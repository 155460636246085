import DashboardIcon from '@mui/icons-material/Dashboard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BuildIcon from '@mui/icons-material/Build';
import ConstructionIcon from '@mui/icons-material/Construction';
import TableRowsIcon from "@mui/icons-material/TableRows";
import BoltIcon from '@mui/icons-material/Bolt';
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SummarizeIcon from '@mui/icons-material/Summarize';

const Icons = {
  dashboard: <DashboardIcon />,
  technicalKpi: <BuildIcon />,
  user: <PeopleOutlineIcon />,
  financialKpi: <MonetizationOnIcon />,
  dataTable : <TableRowsIcon/>,
  generationKpi: <ConstructionIcon />,
  transmissionKpi: <PrecisionManufacturingIcon />,
  customerBilling: <DescriptionIcon />,
  departmentWage: <MonetizationOnIcon />,
  energy: <BoltIcon />,
  download:<FileDownloadIcon/>,
  report:<SummarizeIcon/>
};
export default Icons;
