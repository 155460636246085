import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const InputWithAdornment = (props) => {
  const {
    name,
    label,
    value,
    onChange,
    adornmentText,
    type = 'text',
    ...others
  } = props;
  return (
    <TextField
      name={name}
      placeholder={label}
      label={label}
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment sx={{ color: 'blue' }} position='end'>
            {adornmentText}
          </InputAdornment>
        ),
      }}
      type={type}
      variant='outlined'
      {...others}
      fullWidth
    />
  );
};

export default InputWithAdornment;
