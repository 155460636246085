import { createContext, useContext, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as apiService from "../services/apiServices";

const usersContext = createContext();

export const useUsersContext = () => {
    return useContext(usersContext);
}

const fetchUsers = async () => {
    const result = await apiService.getUsers();
    return result.data
}

const removeUser  = async(id) =>{
    const result = await apiService.deleteUser(id);
    return result.data;
}
const createUser = async (data) => {
    const result = await apiService.createUser(data);
    return result.data;
}

const updateUser = async (userToEdit) =>{
    const { _id, createdAt, ...data } = userToEdit;
    const result = await apiService.updateUser(_id, data);
    return result.data;
}

const UsersContextProvider = ({ children }) => {

    const [userToDelete, setUserToDelete] = useState(null);
    const [userToEdit, setUserToEdit] = useState(null);
    const [editedUser, setEditedUser] = useState(null);
    const usersQuery = useQuery("users", fetchUsers);
    const queryClient = useQueryClient();
    const { mutateAsync: submitUser } = useMutation(createUser, {
        onSuccess: (data) => {
            queryClient.setQueryData("users", (users) => {
                return [...users, data];
            });
        }
    });

    const { mutateAsync: editUser } = useMutation(updateUser, {
        onSuccess: (result) => {
            queryClient.setQueryData("users", users => {
                
                if (!editedUser) return;
                const updatedRecords = users.map(d => {
                    if (d._id === editedUser._id) {
                        d = editedUser;
                    }
                    return d;
                })
                return updatedRecords;
            });
            setUserToEdit(null);
            setEditedUser(null);
        }
    });

    const {mutateAsync: deleteUser} = useMutation("users", removeUser, {
        onSuccess:(data)=>{
            queryClient.removeQueries('users', data._id);
            setUserToDelete(null);
        }
    })
    const value = {
        usersQuery,
        submitUser,
        deleteUser,
        userToDelete, 
        setUserToDelete,
        editUser,
        userToEdit, 
        setUserToEdit,
        editedUser, 
        setEditedUser
    }
    return (
        <usersContext.Provider value={value}>
            {children}
        </usersContext.Provider>
    )
}

export default UsersContextProvider

