import { Box } from "@mui/system";
import React from "react";
import { cardBaseStyles } from "../styles";
import { Stack } from "@mui/material";
import SmoothLineChartWithAnnotation from "../controls/SmoothLineChartWithAnnotation";

const SubPlantDashboardCard = ({
  chartTitle,
  unit,
  valueTitle = chartTitle,
  value,
  valuePercentage,
  seriesData,
  selectedPeriod,
  color = "#2FA4FF",
  height = 120,
}) => {
  
  return (
    <Box
      sx={{
        ...cardBaseStyles,
        display: "grid",
        gridTemplateRows: "repeat(2, 30% auto)",
      }}
    >
      <Stack direction="row" spacing={3} justifyContent="space-between">
        <Stack>
          <Box sx={{ fontWeight: "bold" }}>{`${value} ${unit}`} </Box>
          <Box sx={{ fontSize: "12px", color: "text.secondary" }}>
            {valueTitle}
          </Box>
        </Stack>
        {!valuePercentage ? null : (
          <Stack>
            <Box
              sx={{ color: ()=> valuePercentage <20? "orangered": "green" }}
            >{`${valuePercentage.toFixed(1)} %`}</Box>
            <Box sx={{ fontSize: "12px", color: "text.secondary" }}>
              of plant total
            </Box>
          </Stack>
        )}
      </Stack>
      <SmoothLineChartWithAnnotation
        title={chartTitle}
        annotationText = {selectedPeriod}
        seriesData={seriesData}
        unit={unit}
        color={color}
        height={height}
      />
    </Box>
  );
};

export default SubPlantDashboardCard;
