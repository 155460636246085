import React, { useState } from "react";
import Box from "@mui/system/Box";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Alert, Button, Collapse, IconButton, TextField } from "@mui/material";
import { usePasswordContext } from "../../contexts/passwordContext";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router";
import { useAuthContext } from "../../contexts/authContext";

const initValues = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};
const validationSchema = yup.object({
  oldPassword: yup
    .string("Enter your old password")
    .required("Old password is required"),
  newPassword: yup
    .string("Enter the new password")
    .required("new password is required"),
  confirmNewPassword: yup
    .string("Enter the new confirm password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords mismatch!"),
});
const ChangePasswordForm = () => {
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);
  const [initialValues, setInitialValues] = useState(initValues);
  const { changePassword, setPasswordChanged } = usePasswordContext();
  const { currentUser } = useAuthContext();
  const history = useHistory();

  const goBack = () => {
    setInitialValues(initValues);
    history.goBack();
  };

  return (
    <Box sx={{ width: 1, display: "grid", placeItems: "center", mt: 8 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          const { newPassword, oldPassword } = values;
          const userId = currentUser.id;
          await changePassword(
            { userId, newPassword, oldPassword },
            {
              onError: (error) => {
                setError("Incorrect password. Please type your old password");
                setHasError(true);
              },
              onSuccess: () => {
                setPasswordChanged(true);
                goBack();
              },
            }
          );
        }}       
      >
        {({
          values,
          touched,
          errors,
          isValid,
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                bgcolor: "background.paper",
                p: 2,
                width: { xs: "320px", sm: "500px" },
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: "grid", placeItems: "center" }}>
                <Box sx={{ fontSize: 18, fontWeight: "medium" }}>
                  {`Change Password`}
                </Box>
                <Box
                  component="h5"
                  sx={{ mt: 0, fontSize: 11, color: "text.secondary" }}
                >
                  {`Please enter the old password and the new password for ${currentUser.fullName}`}
                </Box>
              </Box>
              <TextField
                sx={{ ml: 1, my: 2, minWidth: { xs: "280px", sm: "420px" } }}
                onChange={handleChange}
                label="Old Password"
                placeholder="Old Password"
                value={values.oldPassword}
                type="password"
                name="oldPassword"
                variant="outlined"
                onBlur={handleBlur}
                error={touched.oldPassword && Boolean(errors.oldPassword)}
                helperText={
                  touched.oldPassword && errors.oldPassword
                    ? errors.oldPassword
                    : ""
                }
              />

              <TextField
                sx={{ ml: 1, my: 1, minWidth: { xs: "280px", sm: "420px" } }}
                name="newPassword"
                label="New Password"
                placeholder="New Password"
                value={values.newPassword}
                type="password"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.newPassword && Boolean(errors.newPassword)}
                helperText={
                  touched.newPassword && errors.newPassword
                    ? errors.newPassword
                    : ""
                }
              />
              <TextField
                sx={{ ml: 1, my: 1, minWidth: { xs: "280px", sm: "420px" } }}
                name="confirmNewPassword"
                label="Confirm New Password"
                placeholder="Confirm New Password"
                value={values.confirmNewPassword}
                type="password"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.confirmNewPassword &&
                  Boolean(errors.confirmNewPassword)
                }
                helperText={
                  touched.confirmNewPassword && errors.confirmNewPassword
                    ? errors.confirmNewPassword
                    : ""
                }
              />

              <Box display="flex">
                <LoadingButton
                  sx={{ ml: 2, my: 2, textTransform: "none" }}
                  disabled={isSubmitting || !isValid}
                  fullWidth
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Save
                </LoadingButton>
                <Button
                  sx={{ ml: 2, my: 2, textTransform: "none" }}
                  disabled={isSubmitting}
                  onClick={goBack}
                  color="secondary"
                  variant="contained"
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <Box>
        <Collapse in={hasError}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setHasError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {error}
          </Alert>
        </Collapse>
      </Box>
    </Box>
  );
};

export default ChangePasswordForm;
