const definitions = {
    saidi:"System Average Interruption Duration Index - the average number of hours per period without electricity for each customer.",
    capacityFactor: "The actual energy generated to the maximum that the system can generate.",
    availability: "The total time the system operated in a given period to the amount of time in that period.",
    caidi: "Customer Average Interruption Duration Index - the average outage duration experienced by each customer.",
    loadFactor:"The ratio of total energy consumed duirng a billing period to the possible total energy if the system operated at the peak load during that period.",
}
const createKpi = (indicator, key,  description, unit="") =>{
    return {indicator, key, description, unit}
}

export const kpisDescription =[
    createKpi(
        "Total Customers Served",
        "totalCustomersServed",
        "The total number of customers connected to the grid",
    ),
    createKpi(
        "Total Generation",
        "totalGeneration",
        "Total energy in MWh generated during this period",
        "MWh"
    ),
    createKpi(
        "Peak Load",
        "peakLoad",
        "The maximum load in MW recorded during this period",
        "MW"
    ),
    createKpi(
        "Load Factor",
        "loadFactor",
        "The ratio of total energy consumed duirng a billing period to the possible total energy if the system operated at the peak load during that period"
    ),
    createKpi(
        "Capacity Factor",
        "capacityFactor",
        "The actual energy generated to the maximum that the system can generate."
    ),
    createKpi(
        "Availability",
        "availability",
        "The total time the system operated in a given period to the amount of time in that period."
    ),
    createKpi(
        "System Average Interruption Duration Index (SAIDI)",
        "saidi",
        "System Average Interruption Duration Index - the average number of hours per period without electricity for each customer",
        "hrs"
    ),
    createKpi(
        "System Average Interruption Frequency Index (SAIFI)",
        "saifi",
        "System Average Interruption Frequency Index - the average number of times a customer experiences an outage during a given period"
    ),
    createKpi(
        "Customer Average Interruption Duration Index (CAIDI)",
        "caidi",
        "Customer Average Interruption Duration Index - the average outage duration experienced by each customer",
        "hrs"
    ),
    createKpi(
        "Uptime",
        "uptime",
        "Total time the system operated",
        "hrs"
    ),
    createKpi(
        "Total Customers Interrupted",
        "totalCustomersInterrupted",
        "Total customers affected by power outage during this period"
    ),
    createKpi(
        "Interruption Duration",
        "durationOfInterruption",
        "Total hours of non-operation time",
        "hrs"
    ),
    createKpi(
        "Total Time",
        "totalTime",
        "Total number of hours in the reporting period",
        "hrs"
    ),
    // createKpi(
    //     "Substation Loss",
    //     "substationLoss",
    //     "The ratio of the total energy delivered to customers by a system to the total energy generated/received by that system"
    // ),
]

export const substationKpisDescription =[
    createKpi(
        "Total Customers Served",
        "totalCustomersServed",
        "The total number of customers served by the substation",
    ),
    createKpi(
        "Total Energy Delivered",
        "energyDelivered",
        "Total energy in MWh delivered to customers by the substation during this period",
        "MWh"
    ),
    createKpi(
        "Total Energy Received",
        "energyDelivered",
        "Total energy in MWh received at substation during this period",
        "MWh"
    ),
    createKpi(
        "Peak Load",
        "peakLoad",
        "The maximum load in MW recorded by the substation during this period",
        "MW"
    ),
    createKpi(
        "Load Factor",
        "loadFactor",
        "The ratio of total energy consumed duirng a billing period to the possible total energy if the substation operated at the peak load during that period"
    ),
    createKpi(
        "Capacity Factor",
        "capacityFactor",
        "The actual energy generated to the maximum that the substation can generate."
    ),
    createKpi(
        "Availability",
        "availability",
        "The total time the substation operated in a given period to the amount of time in that period."
    ),
    createKpi(
        "System Average Interruption Duration Index (SAIDI)",
        "saidi",
        "System Average Interruption Duration Index - the average number of hours per period without electricity for each customer",
        "hrs"
    ),
    createKpi(
        "System Average Interruption Frequency Index (SAIFI)",
        "saifi",
        "System Average Interruption Frequency Index - the average number of times a customer experiences an outage during a given period"
    ),
    createKpi(
        "Customer Average Interruption Duration Index (CAIDI)",
        "caidi",
        "Customer Average Interruption Duration Index - the average outage duration experienced by each customer connected to this substation",
        "hrs"
    ),
    createKpi(
        "Uptime",
        "uptime",
        "Total time the substation operated",
        "hrs"
    ),
    createKpi(
        "Total Customers Interrupted",
        "totalCustomersInterrupted",
        "Total customers affected by power outage during this period"
    ),
    createKpi(
        "Interruption Duration",
        "durationOfInterruption",
        "Total hours of non-operation time",
        "hrs"
    ),
    createKpi(
        "Total Time",
        "totalTime",
        "Total number of hours in this reporting period",
        "hrs"
    ),
    createKpi(
        "Substation Loss",
        "substationLoss",
        "The ratio of the total energy delivered to customers by this substation to the total energy generated/received by the substation"
    ),
]

export const getGenerationTotalDef = (year) => `The total energy generated by all LEC power plants in the year ${year}.`;
export const getCustomerTotalDef = (year) => `The total number of customers served by LEC in the year ${year}.`;
export const getTotalHoursDef = (year) => `The total hours operated during the year ${year} by all LEC's generation plants.`;
export const getTotalFuelDef = (year) => `The total amount of fuel, in gallons, consumed by all LEC generation plant during the the year ${year}.`;
export const getTotalInternalConsumptionDef = (year) => `The total energy consumed internally by all LEC generation plant during the the year ${year}.`;
export const getTotalUnplannedOutageDef = (year) => `The total hours of unplanned outage by all LEC generation plant during the the year ${year}.`;
export const getTotalMaintenanceHoursDef = (year) => `The total maintenance experienced by all LEC generation plant during the the year ${year}.`;

export default definitions;