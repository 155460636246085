const createHeadCell = (id, label, align = 'right', size = 'small') => {
  return { id, label, align, size };
};
export const systemDataHeadCells = [
  createHeadCell('quarter', 'Quarter', 'left', 'medium'),
  createHeadCell('totalGeneration', 'Generation (MWh)'),
  createHeadCell('peakLoad', 'Peak Load (MW)'),
  createHeadCell('uptime', 'Time Operated (hours)'),
  createHeadCell('totalCustomersServed', 'Total Customers'),
  createHeadCell('totalCustomersInterrupted', 'Customers Interrupted'),
  createHeadCell('durationOfInterruption', 'Interruption Duration (hours)'),
  {
    id: 'edit',
    label: '',
    align: 'right',
    disableSorting: true,
    size: 'small',
  },
  {
    id: 'delete',
    label: '',
    align: 'right',
    disableSorting: true,
    size: 'small',
  },
];
export const substationDataHeadCells = [
  createHeadCell('name', 'Name', 'left', 'medium'),
  createHeadCell('quarter', 'Quarter'),
  createHeadCell('installedCapacity', 'Installed Capacity (MW)'),
  createHeadCell('peakLoad', 'Peak Load (MW)'),
  createHeadCell('energyDelivered', 'Generation (MWh)'),
  createHeadCell('uptime', 'Time Operated (hours)'),
  createHeadCell('totalCustomersServed', 'Total Customers'),
  createHeadCell('totalCustomersInterrupted', 'Customers Interrupted'),
  createHeadCell('durationOfInterruption', 'Interruption Duration (hours)'),
  {
    id: 'edit',
    label: '',
    align: 'right',
    disableSorting: true,
    size: 'small',
  },
  {
    id: 'delete',
    label: '',
    align: 'right',
    disableSorting: true,
    size: 'small',
  },
];

export const userHeadCells = [
  createHeadCell('fullName', 'Full Name', 'left'),
  createHeadCell('email', 'Email', 'left'),
  createHeadCell('phoneNumber', 'Phone Number', 'left'),
  createHeadCell('role', 'User Role', 'left', 'medium'),
  createHeadCell('createdAt', 'Created', 'left'),
  {
    id: 'menu',
    label: '',
    align: 'right',
    disableSorting: true,
    size: 'small',
  },
];
export const generationHeadCell = [
  createHeadCell('collapse', '', 'left'),
  createHeadCell('plantType', 'Plant Type', 'left'),
  createHeadCell('period', 'Period', 'left'),
  createHeadCell('peakLoad', 'Peak Load', 'right'),
  createHeadCell(
    'internalConsumption',
    'Internal Consumption',
    'right',
    'medium'
  ),
  createHeadCell('menu', '', 'left'),
];
export const generationSubHeadCell = [
  createHeadCell('plantName', 'Plant Name', 'left'),
  createHeadCell('energyGenerated', 'Energy Generated', 'right'),
  createHeadCell('serviceHours', 'Service Hours', 'right'),
  createHeadCell(
    'scheduledOutageHours',
    'Scheduledn Outage Hours',
    'right',
    'medium'
  ),
  createHeadCell('maintenanceHours', 'Maintenance Hours', 'right'),
  createHeadCell('forcedOutage', 'Forced Outage', 'right'),
  createHeadCell('unplannedOutage', 'Unplanned Outage', 'right'),
  createHeadCell('periodHours', 'Period Hours', 'right'),
  createHeadCell('installedCapacity', 'Installed Capacity', 'right'),
  createHeadCell('fuelConsumption', 'FuelConsumption', 'right'),
  createHeadCell('menu', '', 'left'),
];

export const transmissionHeadCell = [
  createHeadCell('period', 'Period', 'left'),
  createHeadCell('energyDispatched', 'Energy Dispatched', 'right'),
  createHeadCell('energyDelivered', 'Energy Delivered', 'right', 'medium'),
  createHeadCell('totalCircuit', 'Total Circuit', 'right', 'medium'),
  createHeadCell(
    'totalLengthOfCircuit',
    'Total Circuit Length',
    'right',
    'medium'
  ),
  createHeadCell('averageDemand', 'Avg Demand', 'right', 'medium'),
  createHeadCell('peakDemand', 'Peak Demand', 'right', 'medium'),
  createHeadCell('contractedEnergy', 'Contracted Energy', 'right', 'medium'),
  createHeadCell('energyImported', 'Energy Imported', 'right', 'medium'),
  createHeadCell(
    'totalOperationHours',
    'Total Operation Hrs',
    'right',
    'medium'
  ),
  {
    id: 'menu',
    label: '',
    align: 'right',
    disableSorting: true,
    size: 'small',
  },
];
export const financialHeadCell = [
  createHeadCell('collapse', '', 'left'),
  createHeadCell('name', 'Name', 'left'),
  createHeadCell('period', 'Period', 'left'),
  createHeadCell('totalRevenue', 'Total Revenue (USD)', 'right'),
  createHeadCell('menu', '', 'left'),
];
export const customerBillingHeadCell = [
  createHeadCell('collapse', '', 'left'),
  createHeadCell('paymentType', 'Payment Type', 'left'),
  createHeadCell('period', 'Period', 'left'),
  createHeadCell('totalBill', 'Total Energy Bill ', 'right'),
  createHeadCell('totalCustomerCount', 'Total Customer Count', 'right'),
  createHeadCell('menu', '', 'left'),
];
export const departmentWageHeadCell = [
  createHeadCell('employeeType', 'Employee Type', 'left'),
  createHeadCell('period', 'Period', 'left'),
  createHeadCell('employeeCount', 'Employee Count', 'right'),
  createHeadCell('totalWage', 'Total Wage', 'right'),
  createHeadCell('menu', '', 'left'),
];
