import React, { useState, useEffect, useContext, createContext } from 'react';
import * as apiServices from '../services/apiServices';
import { useQuery } from 'react-query';

const sharedKpiContext = createContext();
const powerPlantDataUrl = '/power-plant-data';
const financialDataUrl = "/financial-data";
const customerBillingsUrl = "/customer-billing-data";
export const useSharedKpiContext = () => useContext(sharedKpiContext);


const fetchYearlyGenerationDataTotal = async ({ queryKey }) => {
    const [, year] = queryKey;
    const result = await apiServices.getAll(`${powerPlantDataUrl}/yearly-data/${year}`);
    return result.data;
};
const fetchYearlyGenerationDataTotals = async ({ queryKey }) => {
    const [, year] = queryKey;
    const result = await apiServices.getAll(`${powerPlantDataUrl}/yearly-data-plant-type/${year}`);
    return result.data;
};
const fetchBillingDataTotals = async ({ queryKey }) => {
    const [, year] = queryKey;
    const result = await apiServices.getAll(`${customerBillingsUrl}/yearly-data/${year}`);
    return result.data;
};
const fetchBillingDataAllPaymentTypes = async ({ queryKey }) => {
    const [, year] = queryKey;
    const result = await apiServices.getAll(`${customerBillingsUrl}/totals-all-payment-types/${year}`);
    return result.data;
};
const fetchPeriodicGenerationDataTotals = async ({ queryKey }) => {
    const [, year] = queryKey;
    const result = await apiServices.getAll(`${powerPlantDataUrl}/yearly-data-by-period/${year}`);
    return result.data;
};
const fetchYearlySubplantsData = async ({ queryKey }) => {
    const [, year] = queryKey;
    const result = await apiServices.getAll(`${powerPlantDataUrl}/subplant-yearly-data/${year}`);
    return result.data;
};
const fetchPeriodicGenerationKpis = async ({ queryKey }) => {
    const [, year] = queryKey;
    const result = await apiServices.getAll(`${powerPlantDataUrl}/kpis/${year}`);
    return result.data;
};
const fetchGenerationOptions = async ({ queryKey }) => {
    const result = await apiServices.getAll(`${powerPlantDataUrl}/unique/options`);
    return result.data;
}
const fetchYearlyFinancialDataTotals = async ({ queryKey }) => {
    const [, year] = queryKey;
    const result = await apiServices.getAll(`${financialDataUrl}/yearly-data/${year}`);
    return result.data;
}

const SharedKpiContextProvider = ({ children }) => {

    const [selectedYear, setSelectedYear] = useState("");
    const [years, setYears] = useState();
    const [plantNames, setPlantNames] = useState();
    const [plantTypes, setPlantTypes] = useState();
    const [periods, setPeriods] = useState();
    const generationOptionsQuery = useQuery("generation-options", fetchGenerationOptions);
    const yearlyGenerationDataTotalQuery = useQuery(["yearly-generation-data-total", selectedYear], fetchYearlyGenerationDataTotal,
        { enabled: !!selectedYear }
    );
    const yearlyGenerationDataTotalsQuery = useQuery(["yearly-generation-data-totals", selectedYear], fetchYearlyGenerationDataTotals,
        { enabled: !!selectedYear }
    );
    const yearlyFinancialDataTotalsQuery = useQuery(["yearly-financial-data-totals", selectedYear], fetchYearlyFinancialDataTotals,
        { enabled: !!selectedYear }
    );
    const customerBillingDataTotalsQuery = useQuery(["billing-data-totals", selectedYear], fetchBillingDataTotals,
        { enabled: !!selectedYear }
    );
    const periodicGenerationDataTotalsQuery = useQuery(["periodic-generation-data-totals", selectedYear], fetchPeriodicGenerationDataTotals,
        { enabled: !!selectedYear }
    );
    const yearlySubplantsDataQuery = useQuery(["yearly-subplants-data", selectedYear], fetchYearlySubplantsData,
        { enabled: !!selectedYear }
    );
    const yearlyGenerationKpiQuery = useQuery(["periodic-generation-kpis", selectedYear], fetchPeriodicGenerationKpis,
        { enabled: !!selectedYear }
    );    
    const allPaymentTypeTotalsQuery = useQuery(["all-payment-types-totals", selectedYear], fetchBillingDataAllPaymentTypes,
        { enabled: !!selectedYear }
    );    

    useEffect(() => {
        const { data } = generationOptionsQuery;
        if (data) {
            const { plantNames, plantTypes, years, periods } = data;
            setYears(years);
            setPlantTypes(plantTypes)
            setPeriods(periods);
            setPlantNames(plantNames);

        }
        //eslint-disable-next-line
    }, [generationOptionsQuery.data]);

    useEffect(() => {
        if (years)
            setSelectedYear([years[0]]);
        //eslint-disable-next-line
    }, [years]);


    const value = {
        generationOptionsQuery,
        yearlyGenerationDataTotalQuery,
        yearlyFinancialDataTotalsQuery,
        customerBillingDataTotalsQuery,
        yearlyGenerationDataTotalsQuery,
        yearlySubplantsDataQuery,
        periodicGenerationDataTotalsQuery,
        yearlyGenerationKpiQuery,
        allPaymentTypeTotalsQuery,
        years,
        plantTypes,
        plantNames,
        periods,
        selectedYear,
        setSelectedYear
    }
    return (
        <sharedKpiContext.Provider value={value}>
            {children}
        </sharedKpiContext.Provider>
    )
}

export default SharedKpiContextProvider