import React, { useState, useContext, createContext, useEffect } from 'react';
import { getPeriodInfo } from '../services/util';
import * as apiServices from '../services/apiServices';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const customerBillingsContext = createContext();
const rootPath = '/customer-billing-data';
export const useCustomerBillingsContext = () =>
  useContext(customerBillingsContext);

const fetchCustomerBillings = async ({ queryKey }) => {
  const result = await apiServices.getAll(rootPath);
  return result.data;
};

const fetchUniquePaymentTypes = async ({ queryKey }) => {
  const result = await apiServices.getAll(`${rootPath}/unique/payment-types`);
  return result.data;
};

const fetchCustomerBillingsByPaymentType = async ({ queryKey }) => {
  const [, paymentType] = queryKey;
  const result = await apiServices.getAll(
    `${rootPath}/unique/payment-types/${paymentType}`
  );
  return result.data;
};

const deleteData = async (id) => {
  const result = await apiServices.deleteData(`${rootPath}/delete/${id}`);
  return result.data;
};

const updateCustomerBilling = async (recordToEdit) => {
  const { _id, period, year, month, periodHours, ...data } = recordToEdit;
  const periodInfo = getPeriodInfo(period);
  if (!periodInfo) throw new Error('Period not in correct format');
  const { year: y, month: m } = periodInfo;
  const dataToEdit = { period, year: y, month: m, ...data };
  await apiServices.updateData(`${rootPath}/edit/${_id}`, dataToEdit);
  return { _id, ...dataToEdit };
};

const createCustomerBilling = async (data) => {
  try {
    const { period } = data;
    const periodInfo = getPeriodInfo(period);
    if (!periodInfo) throw new Error('Period not in correct format');
    const { year, month } = periodInfo;
    const customerBilling = { year, month, ...data };
    const result = apiServices.createData(rootPath, customerBilling);
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

const getYearlyData = (data, selectedYear) => {
  if (!data || !selectedYear) return;
  const billingRecords = [];

  const yearlyData = data.filter(d => d.year === selectedYear);

  //This is considering that the records come in already sorted
  const firstRecord = yearlyData[0];
  const lastestRecords = yearlyData.filter(d => d.period === firstRecord.period);
  //This is considering that there is no duplicate records per period
  const lastCustomerCounts = lastestRecords.map(lr => {
    const { totalCustomerCount, billings, paymentType } = lr;
    const subCustomerCounts = billings.map(b => {
      const { customerCount, customerType } = b;
      return { customerCount, customerType };
    });
    return { totalCustomerCount, subCustomerCounts, paymentType }
  });
  
  yearlyData.forEach((record) => {
    const { totalBill, totalCustomerCount, billings, paymentType } = record;

    const billIndex = billingRecords.findIndex(br => br.paymentType === paymentType);
    if (billIndex === -1) {
      billingRecords.push({ totalBill, totalCustomerCount, paymentType, billings });
    }
    else {
      const customerCount = lastCustomerCounts.find(lcc => lcc.paymentType === paymentType);

      customerCount ? billingRecords[billIndex].totalCustomerCount = customerCount.totalCustomerCount :
        billingRecords[billIndex].totalCustomerCount = totalCustomerCount;

      billingRecords[billIndex].totalBill += totalBill;
      const subBillings =JSON.parse(JSON.stringify(billingRecords[billIndex].billings));
      if (subBillings.length === 0) return;
      billings.forEach(billing => {
        const subIndex = subBillings.findIndex(sb => sb.customerType === billing.customerType);
        if (subIndex === -1) {
          subBillings.push(billing);
        }
        else {
          const { subCustomerCounts } = customerCount;
          const subCustomerCount = subCustomerCounts.find(sc => sc.customerType === billing.customerType);
          subCustomerCount ?
            subBillings[subIndex].customerCount = subCustomerCount.customerCount : subBillings[subIndex].customerCount = billing.customerCount;
          subBillings[subIndex].energyBilled += billing.energyBilled;
        }
      });
      billingRecords[billIndex].billings = subBillings;
    }
  });

  return billingRecords;

}

const CustomerBillingsContextProvider = ({ children }) => {
  const [paymentType, setPaymentType] = useState('');
  const [selectedYear, setSelectedYear] = useState();
  const [years, setYears] = useState([]);
  const [yearlyBillingRecords, setYearlyBillingRecords] = useState([]);

  const customerBillingsQuery = useQuery(
    'customer-billings',
    fetchCustomerBillings
  );
  const uniquePaymentTypesQuery = useQuery(
    'unique-payment-types',
    fetchUniquePaymentTypes
  );
  const recordsByPaymentTypeQuery = useQuery(
    ['records-by-payment-type', paymentType],
    fetchCustomerBillingsByPaymentType
  );

  const queryClient = useQueryClient();
  const { mutateAsync: submitCustomerBilling } = useMutation(
    createCustomerBilling,
    {
      onSuccess: (data) => {
        queryClient.setQueryData('customer-billings', (customerBillings) => {
          return [...customerBillings, data.data];
        });
      },
    }
  );

  const { mutateAsync: editCustomerBilling } = useMutation(
    updateCustomerBilling,
    {
      onSuccess: (data) => {
        queryClient.setQueryData('customer-billings', (customerBillings) => {
          if (!data) return;
          const updatedRecords = customerBillings.map((d) => {
            if (d._id === data._id) {
              return data;
            }
            return d;
          });
          return updatedRecords;
        });
      },
    }
  );

  const { mutateAsync: deleteCustomerBilling } = useMutation(
    'customer-billings',
    deleteData,
    {
      onSuccess: (data) => {
        queryClient.removeQueries('customer-billings', data._id);
      },
    }
  );


  useEffect(() => {
    const { data } = customerBillingsQuery
    if (data) {
      const yearsFiltered = data.map((d) => d.year).filter((value, index, self) => self.indexOf(value) === index);
      setYears(yearsFiltered);
      setSelectedYear(yearsFiltered[0]);
    }
    //eslint-disable-next-line
  }, [customerBillingsQuery.data]);

  useEffect(() => {
    const {data} = customerBillingsQuery
    if (data && selectedYear) {
      const dataCopy = JSON.parse(JSON.stringify(data));
      const billings = getYearlyData(dataCopy, selectedYear);
      setYearlyBillingRecords(billings);
    }
    //eslint-disable-next-line
  }, [selectedYear, customerBillingsQuery.data])

  const value = {
    customerBillingsQuery,
    submitCustomerBilling,
    editCustomerBilling,
    deleteCustomerBilling,
    uniquePaymentTypesQuery,
    recordsByPaymentTypeQuery,
    setPaymentType,
    selectedYear,
    setSelectedYear,
    years,
    yearlyBillingRecords
  };

  return (
    <customerBillingsContext.Provider value={value}>
      {children}
    </customerBillingsContext.Provider>
  );
};

export default CustomerBillingsContextProvider;
