import { Chip, Divider, Stack } from "@mui/material";
import { Box, alpha } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSharedKpiContext } from "../../contexts/sharedKpiContext";
import Icons from "../../icons";
import MuiButton from "../controls/buttons/MuiButton";
import Selector from "../controls/Selector";
import PageHeader from "../pageHeader";
import Loading from "../controls/Loading";
import PlantsLineChart from "../controls/PlantsLineChart";
import { dashboard } from "../pageHeader/pageTitle";
import YearlyDataCard from "./YearlyDataCard";
import * as def from "../../services/kpiDefinitions";
import * as plantTypes from "../../services/plantTypes";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { accountNames } from "../financialKPIs/accountNames";
import { formatFigure3 } from "../../services/util";
import EvStationIcon from "@mui/icons-material/EvStation";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import MixedCharts from "./MixedCharts";
import FinancialPieChart from "./FinancialPieChart";
import GenerationPieChart from "./GenerationPieChart";
import BillingRadialChart from "./BillingRadialChart";
import { useHistory, useRouteMatch} from "react-router-dom";

const getCustomerBillingData = (data) => {
  if (!data) return;
  const firstData = data[0];
  if (!firstData) return;

  const totalEnergyBilled = data.reduce((prevValue, currValue) => {
    return prevValue + currValue.totalEnergyBilled;
  }, 0);
  const { totalCustomerCount } = firstData;
  return { totalCustomerCount, totalEnergyBilled };
};

const getCustomerBillingSeries = (data) => {
  if (!data) return;
  let billTotal = 0;
  let totalCustomer = 0;
  const series = data.map((d) => {
    const { paymentType, totalBill, totalCustomerCount } = d;
    billTotal += totalBill;
    totalCustomer += totalCustomerCount;
    return { x: paymentType, y1: totalBill, y2: totalCustomerCount };
  });
  if (!billTotal || !totalCustomer) return;
  return {
    billSeries: {
      name: `Energy Billed: ${formatFigure3(billTotal)} MWh`,
      data: series.map((s) => ({
        x: s.x.toLowerCase(),
        y: parseFloat(((s.y1 * 100) / billTotal).toFixed(1)),
      })),
    },
    customerCountSeries: {
      name: `Customer Count: ${formatFigure3(totalCustomer)}`,
      data: series.map((s) => ({
        x: s.x.toLowerCase(),
        y: parseFloat(((s.y2 * 100) / totalCustomer).toFixed(1)),
      })),
    },
  };
};

const getGenerationTypeSeries = (data) => {
  if (!data) return;
  let total = 0;
  const energyDataSeries = data.map((d) => {
    const { _id, totalEnergy } = d;
    total += totalEnergy;
    if (_id === plantTypes.hydroplant) {
      return {
        x: "Hydropower",
        y: totalEnergy,
      };
    } else if (_id === plantTypes.heavyFuel) {
      return {
        x: "Heavy Fuel",
        y: totalEnergy,
      };
    } else {
      return {
        x: "Others",
        y: totalEnergy,
      };
    }
  });
  return {
    name: "Energy Genearation Sources",
    total,
    data: energyDataSeries,
  };
};
const getAccountSeries = (data, accountName) => {
  if (!data) return;
  const accountData = data.find((d) => d.name === accountName);
  if (!data) return;
  const { name, accounts, total } = accountData;
  return {
    name,
    total,
    data: accounts.map((acc) => {
      const { name, value } = acc;
      return { x: name, y: value };
    }),
  };
};

const getSubplantDataSeries = (data) => {
  if (!data) return;
  const energyData = data.map((d) => {
    const { _id, totalEnergyGenerated } = d;
    const energy = { x: _id, y: totalEnergyGenerated };
    return energy;
  });
  const hoursData = data.map((d) => {
    const { _id, totalHoursServed } = d;
    const hours = { x: _id, y: totalHoursServed };
    return hours;
  });
  const series = [
    { name: "Energy Generated", type: "column", data: energyData },
    { name: "Hours Operated", type: "line", data: hoursData },
  ];
  return series;
};
const getEnergyGeneratedSeries = (data, fieldName) => {
  if (!data) return;
  const dataSeries = data.map((d) => {
    const { _id: name, subPlants } = d;
    const series = subPlants.map((sp) => {
      const { period: x } = sp;
      const y = sp[fieldName];
      return { x, y };
    });
    return { name, data: series.reverse() };
  });
  return dataSeries;
};

const DashboardDetails = () => {
  const [totalCustomer, setTotalCustomer] = useState();
  const [totalSales, setTotalSales] = useState();
  const [totalIntventory, setTotalIntventory] = useState();
  const [accountReceivable, setAccountReceivable] = useState();
  const [totalOwnersEquity, setTotalOwnersEquity] = useState();
  const [grossProfitMargin, setGrossProfitMargin] = useState();
  const [totalFuelCost, setTotalFuelCost] = useState();
  const [totalCurrentAssets, setTotalCurrentAssets] = useState();
  const [totalFixedAssets, setTotalFixedAssets] = useState();
  const [totalDebt, setTotalDebt] = useState();
  const [energyDataSeries, setEnergyDataSeries] = useState();
  const [serviceHoursSeries, setServiceHoursSeries] = useState();
  const [subPlantSeries, setSubPlantSeries] = useState();
  const [expensesSeries, setExpensesSeries] = useState();
  const [typesSeries, setTypesSeries] = useState();
  const [salaryAndWagesSeries, setSalaryAndWagesSeries] = useState();
  const [billingSeries, setBillingSeries] = useState();
  const [totalCustomersSeries, setTotalCustomersSeries] = useState();
  const {
    generationOptionsQuery,
    yearlyGenerationDataTotalQuery,
    yearlyGenerationDataTotalsQuery,
    customerBillingDataTotalsQuery,
    yearlyFinancialDataTotalsQuery,
    periodicGenerationDataTotalsQuery,
    yearlySubplantsDataQuery,
    allPaymentTypeTotalsQuery,
    years,
    selectedYear,
    setSelectedYear,
  } = useSharedKpiContext();

  const history = useHistory();
  const { path } = useRouteMatch();
  const navigateToReportPage = () =>{
    history.push(`${path}/reports`);
  }
  const selectYear = (event) => {
    const value = event.target.value;
    setSelectedYear(value);
  };

  useEffect(() => {
    const { data } = customerBillingDataTotalsQuery;
    if (data) {
      const billingData = getCustomerBillingData(data);
      const { totalCustomerCount } = billingData;
      setTotalCustomer(totalCustomerCount);
    }
    //eslint-disable-next-line
  }, [customerBillingDataTotalsQuery.data]);
  useEffect(() => {
    const { data } = yearlyFinancialDataTotalsQuery;
    if (data) {
      data.forEach((d) => {
        const { name, total } = d;
        switch (name) {
          case accountNames.sales:
            setTotalSales(total);
            break;
          case accountNames.inventory:
            setTotalIntventory(total);
            break;
          case accountNames.accountReceivable:
            setAccountReceivable(total);
            break;
          case accountNames.ownersEquity:
            setTotalOwnersEquity(total);
            break;
          case accountNames.grossProfitMargin:
            setGrossProfitMargin(total);
            break;
          case accountNames.fuelCost:
            setTotalFuelCost(total);
            break;
          case accountNames.currentAssets:
            setTotalCurrentAssets(total);
            break;
          case accountNames.fixedAssets:
            setTotalFixedAssets(total);
            break;
          case accountNames.debt:
            setTotalDebt(total);
            break;
          default:
            break;
        }
      });
      const series1 = getAccountSeries(data, accountNames.expenses);
      setExpensesSeries(series1);
      const series2 = getAccountSeries(data, accountNames.wagesAndSalaries);
      setSalaryAndWagesSeries(series2);
    }
    //eslint-disable-next-line
  }, [yearlyFinancialDataTotalsQuery.data]);
  useEffect(() => {
    const { data } = periodicGenerationDataTotalsQuery;
    if (data) {
      const series1 = getEnergyGeneratedSeries(data, "totalEnergy");
      setEnergyDataSeries(series1);
      const series2 = getEnergyGeneratedSeries(data, "serviceHours");
      setServiceHoursSeries(series2);
    }
    if (yearlyGenerationDataTotalsQuery.data) {
      const energySeries = getGenerationTypeSeries(
        yearlyGenerationDataTotalsQuery.data
      );
      setTypesSeries(energySeries);
    }
    //eslint-disable-next-line
  }, [
    periodicGenerationDataTotalsQuery.data,
    yearlyGenerationDataTotalsQuery.data,
  ]);

  useEffect(() => {
    const data = yearlySubplantsDataQuery.data;
    if (data) {
      const series = getSubplantDataSeries(data);
      setSubPlantSeries(series);
    }
    //eslint-disable-next-line
  }, [yearlySubplantsDataQuery.data]);

  useEffect(() => {
    const data = allPaymentTypeTotalsQuery.data;
    if (data) {
      const result = getCustomerBillingSeries(data);
      setBillingSeries(result.billSeries);
      setTotalCustomersSeries(result.customerCountSeries);
    }
    //eslint-disable-next-line
  }, [allPaymentTypeTotalsQuery.data]);
  return (
    <Box sx={{ width: 1 }}>
      <Box>
        {generationOptionsQuery.data  ? (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, sm: 2 }}
            justifyContent="space-between"
          >
            <PageHeader
              icon={Icons.dashboard}
              title={dashboard.title}
              subTitle={dashboard.subtitle}
            />
            <Box>
              <Selector
                name="selectedYear"
                label="Select Year"
                value={selectedYear}
                onChange={selectYear}
                options={years}
              />
            </Box>
            <Box>
              <MuiButton
                text="Generate Report"
                variant="outlined"
                size="large"
                onClick={navigateToReportPage}
                color="primary"
                type="button"
                minWidth="160px"
                startIcon={Icons.download}
              />
            </Box>
          </Stack>
        ) : null}
      </Box>
      {(generationOptionsQuery.status === "loading" || 
      yearlyFinancialDataTotalsQuery.status === "loading" ||
      yearlyGenerationDataTotalsQuery.status === "loading" ||
      customerBillingDataTotalsQuery.status === "loading" ||
      yearlyGenerationDataTotalQuery.status === "loading" ||
      yearlyGenerationDataTotalsQuery.status === "loading")
      ? (
        <Loading />
      ) : (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={2}
          sx={{ mt: { xs: 2 } }}
        >
          {customerBillingDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                value={totalCustomer}
                color={"#00C897"}
                title={"Total Customers"}
                definition={def.getCustomerTotalDef(selectedYear)}
                icon={<PeopleAltIcon fontSize="medium" />}
              />
            </Box>
          ) : null}
          {yearlyGenerationDataTotalQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                unit="MWh"
                value={yearlyGenerationDataTotalQuery.data.totalEnergy}
                title={"Total Energy Generated"}
                definition={def.getGenerationTotalDef(selectedYear)}
              />
            </Box>
          ) : null}

          {yearlyFinancialDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                unit="$"
                fronUnit={true}
                value={totalSales}
                color={"#00C897"}
                title={"Total Sales"}
                icon={<MonetizationOnIcon fontSize="medium" />}
              />
            </Box>
          ) : null}
          {yearlyGenerationDataTotalQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                unit="hrs"
                value={yearlyGenerationDataTotalQuery.data.totalHours}
                title={"Total Hours operated"}
                definition={def.getTotalHoursDef(selectedYear)}
                icon={<WatchLaterIcon fontSize="medium" />}
              />
            </Box>
          ) : null}
          <Box gridColumn={{ xs: "span 12" }}>
            <Divider variant="middle" sx={{ my: 1 }}>
              <Chip
                label="Generation"
                sx={{ bgcolor: alpha("#00C897", 0.2) }}
              />
            </Divider>
          </Box>
          {periodicGenerationDataTotalsQuery.data &&
          yearlyGenerationDataTotalQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6" }}
              sx={{
                borderRadius: "10px",
                bgcolor: "background.paper",
                p: 2,
                boxShadow: 0,
              }}
            >
              <PlantsLineChart
                title="Energy Generated"
                unit="MWh"
                height={"360px"}
                totalValue={formatFigure3(
                  yearlyGenerationDataTotalQuery.data.totalEnergy
                )}
                seriesData={energyDataSeries}
              />
            </Box>
          ) : null}
          {periodicGenerationDataTotalsQuery.data &&
          yearlyGenerationDataTotalQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6" }}
              sx={{
                borderRadius: "10px",
                bgcolor: "background.paper",
                p: 2,
                boxShadow: 0,
              }}
            >
              <PlantsLineChart
                title="Service Hours"
                unit="hrs"
                height={"360px"}
                totalValue={formatFigure3(
                  yearlyGenerationDataTotalQuery.data.totalHours
                )}
                seriesData={serviceHoursSeries}
              />
            </Box>
          ) : null}
          {yearlySubplantsDataQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6" }}
              sx={{
                borderRadius: "10px",
                bgcolor: "background.paper",
                p: 2,
                boxShadow: 0,
              }}
            >
              <MixedCharts
                title={"Sub Plant Operation Graph"}
                titleY1={"Energy Generated(MWh)"}
                titleY2={"Hours Operated(hrs)"}
                unit1="MWh"
                unit2="hrs"
                dataSeries={subPlantSeries}
              />
            </Box>
          ) : null}
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6" }}
            sx={{
              borderRadius: "10px",
              bgcolor: "background.paper",
              p: 2,
              boxShadow: 0,
            }}
          >
            {yearlyFinancialDataTotalsQuery.data ? (
              <GenerationPieChart dataSeries={typesSeries} />
            ) : null}
          </Box>
          {yearlyGenerationDataTotalQuery.data ? (
            <>
              <Box
                gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
                sx={{ borderRadius: "10px" }}
              >
                <YearlyDataCard
                  unit="gallons"
                  value={
                    yearlyGenerationDataTotalQuery.data.totalFuelConsumption
                  }
                  title={"Fuel Consumption"}
                  definition={def.getTotalFuelDef(selectedYear)}
                  icon={<EvStationIcon fontSize="medium" />}
                />
              </Box>
              <Box
                gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
                sx={{ borderRadius: "10px" }}
              >
                <YearlyDataCard
                  unit="MWh"
                  value={
                    yearlyGenerationDataTotalQuery.data.totalInternalConsumption
                  }
                  title={"Internal Consumption"}
                  definition={def.getTotalInternalConsumptionDef(selectedYear)}
                  icon={<ElectricalServicesIcon fontSize="medium" />}
                />
              </Box>
              <Box
                gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
                sx={{ borderRadius: "10px" }}
              >
                <YearlyDataCard
                  unit="hrs"
                  value={
                    yearlyGenerationDataTotalQuery.data.totalUnplannedOutage
                  }
                  title={"Unplanned Outage"}
                  color={"#EB5353"}
                  definition={def.getTotalUnplannedOutageDef(selectedYear)}
                  icon={<AlarmOffIcon fontSize="medium" />}
                />
              </Box>
              <Box
                gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
                sx={{ borderRadius: "10px" }}
              >
                <YearlyDataCard
                  unit="hrs"
                  value={
                    yearlyGenerationDataTotalQuery.data.totalMaintenanceHours
                  }
                  title={"Maintenance Hours"}
                  color={"#EB5353"}
                  definition={def.getTotalMaintenanceHoursDef(selectedYear)}
                  icon={<AlarmOffIcon fontSize="medium" />}
                />
              </Box>
            </>
          ) : null}
          {yearlyFinancialDataTotalsQuery.data ? (
            <Box gridColumn={{ xs: "span 12" }}>
              <Divider variant="middle" sx={{ my: 1 }}>
                <Chip
                  label="Financial"
                  sx={{ bgcolor: alpha("#00C897", 0.2) }}
                />
              </Divider>
            </Box>
          ) : null}
          {yearlyFinancialDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                unit="$"
                fronUnit={true}
                value={totalIntventory}
                color={"#00C897"}
                title={"Total Inventory"}
                icon={<MonetizationOnIcon fontSize="medium" />}
              />
            </Box>
          ) : null}
          {yearlyFinancialDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                unit="$"
                fronUnit={true}
                value={accountReceivable}
                color={"#00C897"}
                title={"Account Receivable"}
                icon={<MonetizationOnIcon fontSize="medium" />}
              />
            </Box>
          ) : null}
          {yearlyFinancialDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                unit="$"
                fronUnit={true}
                value={grossProfitMargin}
                color={"#00C897"}
                title={"Gross Profit Margin"}
                icon={<MonetizationOnIcon fontSize="medium" />}
              />
            </Box>
          ) : null}
          {yearlyFinancialDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                unit="$"
                fronUnit={true}
                value={totalOwnersEquity}
                color={"#00C897"}
                title={"Total Owners Equity"}
                icon={<MonetizationOnIcon fontSize="medium" />}
              />
            </Box>
          ) : null}
          {yearlyFinancialDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                unit="$"
                fronUnit={true}
                value={totalFuelCost}
                color={"#00C897"}
                title={"Total Fuel Cost"}
                icon={<MonetizationOnIcon fontSize="medium" />}
              />
            </Box>
          ) : null}
          {yearlyFinancialDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                unit="$"
                fronUnit={true}
                value={totalCurrentAssets}
                color={"#00C897"}
                title={"Total Current Assets"}
                icon={<MonetizationOnIcon fontSize="medium" />}
              />
            </Box>
          ) : null}
          {yearlyFinancialDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                unit="$"
                fronUnit={true}
                value={totalFixedAssets}
                color={"#00C897"}
                title={"Total Fixed Assets"}
                icon={<MonetizationOnIcon fontSize="medium" />}
              />
            </Box>
          ) : null}
          {yearlyFinancialDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <YearlyDataCard
                unit="$"
                fronUnit={true}
                value={totalDebt}
                color={"#00C897"}
                title={"Total Debt"}
                icon={<MonetizationOnIcon fontSize="medium" />}
              />
            </Box>
          ) : null}
          {yearlyFinancialDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6" }}
              sx={{
                borderRadius: "10px",
                bgcolor: "background.paper",
                p: 2,
                boxShadow: 0,
              }}
            >
              <FinancialPieChart dataSeries={expensesSeries} />
            </Box>
          ) : null}
          {yearlyFinancialDataTotalsQuery.data ? (
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6" }}
              sx={{
                borderRadius: "10px",
                bgcolor: "background.paper",
                p: 2,
                boxShadow: 0,
              }}
            >
              <FinancialPieChart dataSeries={salaryAndWagesSeries} />
            </Box>
          ) : null}
          <Box gridColumn={{ xs: "span 12" }}>
            <Divider variant="middle" sx={{ my: 1 }}>
              <Chip
                label="Customer Billings"
                sx={{ bgcolor: alpha("#00C897", 0.2) }}
              />
            </Divider>
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
            sx={{
              borderRadius: "10px",
              bgcolor: "background.paper",
              p: 2,
              boxShadow: 0,
            }}
          >
            <BillingRadialChart dataSeries={billingSeries} />
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
            sx={{
              borderRadius: "10px",
              bgcolor: "background.paper",
              p: 2,
              boxShadow: 0,
            }}
          >
            <BillingRadialChart dataSeries={totalCustomersSeries} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DashboardDetails;
