export const dashboard = {
  title: 'Dashboard',
  subtitle: 'Displays Key Performance Indicators for LEC\'s operations',
};

export const technicalKpi = {
  title: 'Technical KPI Page',
  subtitle: 'Displays information on the system technical performance',
};

export const technicalKpiForm = {
  title: 'Technical KPI Form',
  subtitle: 'Form for entering system operation data.',
};
export const financialKpi = {
  title: 'Financial KPI Page',
  subtitle: 'Displays financial kpi for LEC Operations',
};
export const financialDataTable = {
  title: 'Financial Data Table',
  subtitle: 'Displays list of financial data for LEC Operations',
};
export const generationKpi = {
  title: 'Generation KPI Page',
  subtitle: 'Displays generation kpi for the system',
};
export const generationDataTable = {
  title: 'Generation Data List',
  subtitle: 'Displays list of generation data for LEC system',
};
export const report = {
  title: 'Report  Generation Page',
  subtitle: 'Page for generating LEC\'s KPI reports into Excel',
};
export const customerBillingKpi = {
  title: 'Customer Billing KPI Page',
  subtitle: 'Displays Customer Billing kpi for the system',
};
export const transmissionKpi = {
  title: 'Transmission KPI Page',
  subtitle: 'Displays transmission kpi for LEC system',
};
export const departmentWageKpi = {
  title: 'Department Wage KPI Page',
  subtitle: 'Displays Department Wage kpi for the system',
};
export const user = {
  title: 'User Page',
  subtitle: 'Displays the registered users of the dashboard app',
};
