import { TrendingDown, TrendingUp } from "@mui/icons-material";
import { alpha } from "@mui/lab/node_modules/@mui/system";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { formatFigure3 } from "../../services/util";
import { periodTransCardBaseStyles } from "../styles";

const getPercent = (currentValue, previousValue) => {
  if (!currentValue) return;
  const diff = currentValue - previousValue;
  const percent = diff / currentValue;
  return percent * 100;
};

const PeriodicKPICard = ({
  title,
  currentValue,
  previousValue,
  unit,
  color = "#28527A",
}) => {
  const [trendColor, setTrendColor] = useState(color);
  const [trendIcon, setTrendIcon] = useState();

useEffect(() => {
  if(currentValue && previousValue){
      const diff = currentValue - previousValue;
      if(diff<0) {
          setTrendColor("#E40017");
          setTrendIcon(<TrendingDown />)
      }
      else{
        setTrendColor(color);
        setTrendIcon(<TrendingUp />)
      }
  }
  // eslint-disable-next-line
}, [currentValue, previousValue], color)


  return (
    <Box
      display="grid"
      gridTemplateRows="repeat(3, 20% auto auto)"
      sx={{ ...periodTransCardBaseStyles }}
    >
      <Box
        sx={{
          ml: 1,
          color: "text.secondary",
        }}
      >
        {title}
      </Box>
      <Box sx={{ fontSize: "20px", fontWeight: "bold", mt: 1, mb: 1, ml: 1, }}>
        {`${formatFigure3(currentValue, 0)} ${unit}`}
      </Box>
      {!currentValue || !previousValue || !(currentValue - previousValue)? null : (
        <Box
          sx={{
            width: 0.4,
            ml: 1,
            color: trendColor,
            bgcolor: alpha(trendColor, 0.2),
            fontWeight: "bold",
            display: "grid",
            placeItems: "center",
            p: 0.7,
            borderRadius: "10%"
          }}
        >
          <Stack direction="row" spacing={1}>
            {`${getPercent(currentValue, previousValue).toFixed(1)}%`}{" "}
            <span>
              {trendIcon}
            </span>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default PeriodicKPICard;
