import { useState, createContext, useContext } from 'react';
import { useMutation } from 'react-query';
import * as apiServices from '../services/apiServices';

const PasswordContext = createContext();

export const usePasswordContext = () => {
  return useContext(PasswordContext);
};

const resetPassword = async ({ userId, newPassword, oldPassword }) => {
  const result = await apiServices.changePassword(
    userId,
    newPassword,
    oldPassword
  );
  return result.data;
};
const PasswordContextProvider = ({ children }) => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { mutateAsync: changePassword } = useMutation(resetPassword, {
    onSuccess: (data) => {
      setPasswordChanged(true);
    },
  });
  const value = {
    usePasswordContext,
    changePassword,
    passwordChanged,
    setPasswordChanged,
  };
  return (
    <PasswordContext.Provider value={value}>
      {children}
    </PasswordContext.Provider>
  );
};

export default PasswordContextProvider;
