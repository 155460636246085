import React from "react";
import Report from "./Report";
import ReportsContextProvider from "../../contexts/reportsContext";

const Reports = () => {
  return (
    <ReportsContextProvider>
      <Report />
    </ReportsContextProvider>
  );
};

export default Reports;
