import React from 'react';
import { useFormikContext } from 'formik';
import InputWithAdornment from './InputWithAdornment';

const TotalDependentInput = ({ action, ...props }) => {
  const {
    values: { billings },
    touched,
    setFieldValue,
  } = useFormikContext();
  const [disable, setDisable] = React.useState(false);

  React.useEffect(() => {
    setDisable(billings.length !== 0);
    setFieldValue(props.name, action(billings), true);
  }, [billings, setFieldValue, touched, props.name, action]);

  return (
    <>
      <InputWithAdornment
        {...props}
        inputProps={{
          disabled: disable,
        }}
      />
    </>
  );
};

export default TotalDependentInput;
