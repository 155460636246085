import Box from '@mui/system/Box';
import BuildIcon from '@mui/icons-material/Build';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGenerationKpiContext } from '../../contexts/generationKpiContext';
import InputWithAdornment from '../controls/formControls/InputWithAdornment';
import PageHeader from '../pageHeader';
import MuiButton from '../controls/buttons/MuiButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Selector from '../controls/Selector';
import { getPeriods } from '../../services/util';
import * as yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import SuccessToastNotification from '../controls/SuccessToastNotification';
import ErrorToastNotification from '../controls/ErrorToastNotification';
import { Paper } from '@mui/material';

const validationSchema = yup.object({
  period: yup.string('Enter a valid period').required('period is required'),
  plantType: yup
    .string('Enter a valid plant type')
    .required('plant type is required'),
  peakLoad: yup
    .number('Enter a valid peak load')
    .required('peak load is required'),
  internalConsumption: yup
    .number('Enter a valid internal consumption')
    .required('internal consumption is required'),
  plants: yup.array().of(
    yup.object({
      plantName: yup
        .string('Enter Plant name')
        .required('Plant Name is required'),
      energyGenerated: yup
        .string('Enter energy generated')
        .required('Plant Location is required'),
      serviceHours: yup
        .string('Enter service hours')
        .required('Service Hours is required'),
      scheduledOutageHours: yup
        .string('Enter scheduled outage hours')
        .required('Scheduled Outage Hours is required'),
      maintenanceHours: yup
        .string('Enter maintenance hours')
        .required('Maintenance Hours is required'),
      forcedOutage: yup
        .string('Enter forced outage')
        .required('Forced Outage is required'),
      unplannedOutage: yup
        .string('Enter unplanned outage')
        .required('Unplanned Outage is required'),
      fuelConsumption: yup
        .string('Enter fuel consumption')
        .required('Fuel Consumption is required'),
      installedCapacity: yup
        .string('Enter installed capacity')
        .required('Installed Capacity is required'),
    })
  ),
});

function createSubPlant(plantName, installedCapacity) {
  return {
    plantName,
    energyGenerated: '',
    serviceHours: '',
    scheduledOutageHours: '',
    maintenanceHours: '',
    forcedOutage: '',
    unplannedOutage: '',
    installedCapacity,
    fuelConsumption: '',
  };
}

const periods = getPeriods();
const initialValues = {
  period: '',
  plantType: '',
  peakLoad: '',
  internalConsumption: '',
  plants: [],
};

const GenerationEditForm = () => {
  //TODO - make form responsive
  const history = useHistory();
  const { id } = useParams();
  const [hasError, setHasError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [mode, setMode] = useState('');
  const [error, setError] = useState('');

  const {
    editGenerationData,
    submitGenerationData,
    powerPlantTypesQuery,
    generationDataQuery,
  } = useGenerationKpiContext();

  useEffect(() => {
    if (id) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [id]);

  const goBack = () => {
    history.goBack();
  };
  const getSubPowerPlants = (powerPlantType) => {
    const { status, data } = generationDataQuery;
    if (status === 'success') {
      const { plants } = data.find((item) => item.plantType === powerPlantType);
      if (!plants) return [];

      return plants.map((plant) => {
        return createSubPlant(plant.plantName, plant.installedCapacity);
      });
    }
    return [];
  };

  const getPowerPlantTypes = (powerPlantTypesQuery) => {
    const { status, data } = powerPlantTypesQuery;
    if (status === 'success') {
      return data;
    } else {
      return [];
    }
  };

  const getPowerPlantToEdit = (id) => {
    const { status, data } = generationDataQuery;
    if (status === 'success') {
      const powerPlant = data.find((ss) => ss._id === id);
      if (!powerPlant) return {};
      return powerPlant;
    }
  };

  const handleError = (error) => {
    if (error.response) {
      setError(error.response.data.msg);
    } else if (error.request) {
      setError('Please check your internet connection');
    } else {
      setError(error.message);
    }
  };

  return (
    <Box sx={{ width: '100%', '&>div': { width: '100%' } }}>
      <MuiButton
        text='Back'
        color='success'
        size='small'
        type='button'
        variant='outlined'
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
      />
      <PageHeader
        icon={<BuildIcon />}
        title={
          mode === 'add'
            ? 'Add Power Plant Data Form'
            : 'Edit Power Plant Data Form'
        }
        subTitle={`Form for ${mode}ing power plant data.`}
      />
      <Formik
        initialValues={id ? getPowerPlantToEdit(id) : initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          if (mode === 'add') {
            await submitGenerationData(values, {
              onError: (error) => {
                setHasError(true);
                handleError(error);
              },
              onSuccess: () => {
                resetForm();
                setIsSuccessful(true);
              },
            });
          } else if (mode === 'edit') {
            await editGenerationData(values, {
              onError: (error) => {
                setHasError(true);
                handleError(error);
              },
              onSuccess: () => {
                resetForm();
                setIsSuccessful(true);
              },
            });
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          isValid,
          dirty,
          handleReset,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                borderRadius: 2,
                width: '100%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Paper
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                    p: 2,
                    columnGap: 2,
                    rowGap: 2,
                  }}
                >
                  <Selector
                    options={periods}
                    name='period'
                    label='Period'
                    onChange={handleChange}
                    value={values.period}
                    onBlur={handleBlur}
                    error={touched.period && Boolean(errors.period)}
                    helperText={
                      touched.period && errors.period ? errors.period : ''
                    }
                  />
                  <Autocomplete
                    options={getPowerPlantTypes(powerPlantTypesQuery)}
                    value={values.plantType}
                    onOpen={handleBlur}
                    freeSolo={true}
                    onInputChange={(e, value) => {
                      setFieldValue('name', value || '', true);
                    }}
                    onChange={(e, value, reason) => {
                      setFieldValue('name', value || '', true);
                      if (reason === 'clear') {
                        setFieldValue('accounts', [], true);
                        return;
                      }
                      const subPowerPlant = getSubPowerPlants(value || '');

                      if (!subPowerPlant) return;
                      setFieldValue('plants', subPowerPlant, true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='contact'
                        variant='outlined'
                        type='text'
                        label='Name'
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={
                          touched.name && errors.name ? errors.name : ''
                        }
                      />
                    )}
                  />

                  <InputWithAdornment
                    name='peakLoad'
                    variant='outlined'
                    value={values.peakLoad}
                    type='text'
                    label='Peak Load'
                    onBlur={handleBlur}
                    error={touched.peakLoad && Boolean(errors.peakLoad)}
                    helperText={
                      touched.peakLoad && errors.peakLoad ? errors.peakLoad : ''
                    }
                    onChange={handleChange}
                    adornmentText='MW'
                  />
                  <InputWithAdornment
                    name='internalConsumption'
                    variant='outlined'
                    value={values.internalConsumption}
                    type='text'
                    label='Internal Consumption'
                    onBlur={handleBlur}
                    error={
                      touched.internalConsumption &&
                      Boolean(errors.internalConsumption)
                    }
                    helperText={
                      touched.internalConsumption && errors.internalConsumption
                        ? errors.peakLoad
                        : ''
                    }
                    onChange={handleChange}
                    adornmentText='MW'
                  />
                </Paper>

                <FieldArray
                  name='plants'
                  render={(arrayHelpers) => (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <h3>Sub Power Plants</h3>
                        <Button
                          variant='contained'
                          sx={{ height: '40px' }}
                          onClick={() => {
                            arrayHelpers.push(createSubPlant('', ''));
                          }}
                        >
                          Add Sub Plants
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: '1fr',
                          columnGap: '16px',
                          alignItems: 'center',
                        }}
                      >
                        {values.plants.map((plant, index) => (
                          <Paper
                            key={index}
                            sx={{
                              mb: 2,
                              padding: 2,
                            }}
                          >
                            <h3>{plant.plantName}</h3>
                            <Box
                              sx={{
                                display: 'grid',
                                gridTemplateColumns: {
                                  xs: '1fr',
                                  md: '1fr 1fr',
                                },
                                gap: 2,
                                alignItems: 'center',
                              }}
                            >
                              <InputWithAdornment
                                name={`plants.${index}.plantName`}
                                variant='outlined'
                                value={plant.plantName}
                                type='text'
                                label='Plant Name'
                                onBlur={() => {
                                  handleBlur();
                                }}
                                error={
                                  touched.plants &&
                                  touched.plants[index]?.plantName &&
                                  Boolean(
                                    errors.plants &&
                                      errors.plants[index]?.plantName
                                  )
                                }
                                helperText={
                                  touched.plants &&
                                  touched.plants[index]?.plantName &&
                                  errors.plants &&
                                  errors.plants[index]?.plantName
                                    ? errors.plants[index]?.plantName
                                    : ''
                                }
                                onChange={handleChange}
                              />

                              <InputWithAdornment
                                name={`plants.${index}.energyGenerated`}
                                variant='outlined'
                                type='number'
                                value={plant.energyGenerated}
                                label='Energy Generated'
                                onBlur={handleBlur}
                                error={
                                  touched.plants &&
                                  touched.plants[index]?.energyGenerated &&
                                  Boolean(
                                    errors.plants &&
                                      errors.plants[index]?.energyGenerated
                                  )
                                }
                                helperText={
                                  touched.plants &&
                                  touched.plants[index]?.energyGenerated &&
                                  errors.plants &&
                                  errors.plants[index]?.energyGenerated
                                    ? errors.plants[index]?.energyGenerated
                                    : ''
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                adornmentText='kWh'
                              />
                              <InputWithAdornment
                                name={`plants.${index}.serviceHours`}
                                variant='outlined'
                                type='number'
                                value={plant.serviceHours}
                                label='Service Hours'
                                onBlur={handleBlur}
                                error={
                                  touched.plants &&
                                  touched.plants[index]?.serviceHours &&
                                  Boolean(
                                    errors.plants &&
                                      errors.plants[index]?.serviceHours
                                  )
                                }
                                helperText={
                                  touched.plants &&
                                  touched.plants[index]?.serviceHours &&
                                  errors.plants &&
                                  errors.plants[index]?.serviceHours
                                    ? errors.plants[index]?.serviceHours
                                    : ''
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                adornmentText='hours'
                              />
                              <InputWithAdornment
                                name={`plants.${index}.scheduledOutageHours`}
                                variant='outlined'
                                type='number'
                                value={plant.scheduledOutageHours}
                                label='Scheduled Outage Hours'
                                onBlur={handleBlur}
                                error={
                                  touched.plants &&
                                  touched.plants[index]?.scheduledOutageHours &&
                                  Boolean(
                                    errors.plants &&
                                      errors.plants[index]?.scheduledOutageHours
                                  )
                                }
                                helperText={
                                  touched.plants &&
                                  touched.plants[index]?.scheduledOutageHours &&
                                  errors.plants &&
                                  errors.plants[index]?.scheduledOutageHours
                                    ? errors.plants[index]?.scheduledOutageHours
                                    : ''
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                adornmentText='hours'
                              />
                              <InputWithAdornment
                                name={`plants.${index}.maintenanceHours`}
                                variant='outlined'
                                type='number'
                                value={plant.maintenanceHours}
                                label='Maintenance Hours'
                                onBlur={handleBlur}
                                error={
                                  touched.plants &&
                                  touched.plants[index]?.maintenanceHours &&
                                  Boolean(
                                    errors.plants &&
                                      errors.plants[index]?.maintenanceHours
                                  )
                                }
                                helperText={
                                  touched.plants &&
                                  touched.plants[index]?.maintenanceHours &&
                                  errors.plants &&
                                  errors.plants[index]?.maintenanceHours
                                    ? errors.plants[index]?.maintenanceHours
                                    : ''
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                adornmentText='hours'
                              />
                              <InputWithAdornment
                                name={`plants.${index}.forcedOutage`}
                                variant='outlined'
                                type='number'
                                value={plant.forcedOutage}
                                label='Forced Outage'
                                onBlur={handleBlur}
                                error={
                                  touched.plants &&
                                  touched.plants[index]?.forcedOutage &&
                                  Boolean(
                                    errors.plants &&
                                      errors.plants[index]?.forcedOutage
                                  )
                                }
                                helperText={
                                  touched.plants &&
                                  touched.plants[index]?.forcedOutage &&
                                  errors.plants &&
                                  errors.plants[index]?.forcedOutage
                                    ? errors.plants[index]?.forcedOutage
                                    : ''
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                adornmentText='hours'
                              />
                              <InputWithAdornment
                                name={`plants.${index}.unplannedOutage`}
                                variant='outlined'
                                type='number'
                                value={plant.unplannedOutage}
                                label='Unplanned Outage'
                                onBlur={handleBlur}
                                error={
                                  touched.plants &&
                                  touched.plants[index]?.unplannedOutage &&
                                  Boolean(
                                    errors.plants &&
                                      errors.plants[index]?.unplannedOutage
                                  )
                                }
                                helperText={
                                  touched.plants &&
                                  touched.plants[index]?.unplannedOutage &&
                                  errors.plants &&
                                  errors.plants[index]?.unplannedOutage
                                    ? errors.plants[index]?.unplannedOutage
                                    : ''
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                adornmentText='hours'
                              />
                              <InputWithAdornment
                                name={`plants.${index}.installedCapacity`}
                                variant='outlined'
                                type='number'
                                value={plant.installedCapacity}
                                label='Installed Capacity'
                                onBlur={handleBlur}
                                error={
                                  touched.plants &&
                                  touched.plants[index]?.installedCapacity &&
                                  Boolean(
                                    errors.plants &&
                                      errors.plants[index]?.installedCapacity
                                  )
                                }
                                helperText={
                                  touched.plants &&
                                  touched.plants[index]?.installedCapacity &&
                                  errors.plants &&
                                  errors.plants[index]?.installedCapacity
                                    ? errors.plants[index]?.installedCapacity
                                    : ''
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                adornmentText='kW'
                              />
                              <InputWithAdornment
                                name={`plants.${index}.fuelConsumption`}
                                variant='outlined'
                                type='number'
                                value={plant.fuelConsumption}
                                label='Fuel Consumption'
                                onBlur={handleBlur}
                                error={
                                  touched.plants &&
                                  touched.plants[index]?.fuelConsumption &&
                                  Boolean(
                                    errors.plants &&
                                      errors.plants[index]?.fuelConsumption
                                  )
                                }
                                helperText={
                                  touched.plants &&
                                  touched.plants[index]?.fuelConsumption &&
                                  errors.plants &&
                                  errors.plants[index]?.fuelConsumption
                                    ? errors.plants[index]?.fuelConsumption
                                    : ''
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                adornmentText='kW'
                              />
                              <Button
                                variant='outlined'
                                color='error'
                                onClick={() => arrayHelpers.remove(index)}
                                sx={{ height: '50px', width: '200px' }}
                              >
                                Remove Form
                              </Button>
                            </Box>
                          </Paper>
                        ))}
                      </Box>
                    </Box>
                  )}
                />

                <Box display='inline'>
                  <LoadingButton
                    sx={{ ml: 1, my: 2, textTransform: 'none' }}
                    disabled={isSubmitting || !isValid || !dirty}
                    loading={isSubmitting}
                    variant='contained'
                    size='large'
                    type='submit'
                  >
                    Submit
                  </LoadingButton>
                  <Button
                    sx={{ ml: 1, my: 2, textTransform: 'none' }}
                    disabled={isSubmitting}
                    onClick={handleReset}
                    size='large'
                    color='secondary'
                    variant='contained'
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <SuccessToastNotification
        isOpen={isSuccessful}
        setIsSuccessful={setIsSuccessful}
        message='Form successfully saved!'
      />
      <ErrorToastNotification
        isOpen={hasError}
        setHasError={setHasError}
        message={error}
      />
    </Box>
  );
};

export default GenerationEditForm;
