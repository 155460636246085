import React, { useEffect, useState } from 'react';
import { useGenerationKpiContext } from '../../contexts/generationKpiContext';
import Chart from 'react-apexcharts';

const SubPlantDetails = () => {
  const { generationDataQuery } = useGenerationKpiContext();
  const [unitAnalyticsData, setUnitAnalyticsData] = useState([]);
  const [options] = useState({
    chart: {
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
      },
    },
    // colors: ['#00E396'],

    // dataLabels: { enabled: true },
    stroke: {
      curve: 'smooth',
      width:2
    },
    title: {
      text: 'Product Trends by Month',
      align: 'left',
      floating: true,
      offsetX: 30,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      // customLegendItems: ['Actual', 'Expected'],
      // markers: {
      //   fillColors: ['#00E396', '#775DD0'],
      // },
    },
    markers: {
      size: 2,
      colors:"#775DD0",
      strokeColors:"#00E396",
      strokeWidth:2
    },
    fill: {
      opacity: 1,
    },
    // dataLabels: {
    //   enabled: true,
    //   formatter: function (val) {
    //     return val + '%';
    //   },
    // },
  });

  useEffect(() => {
    const { status, data } = generationDataQuery;
    if (status === 'success') {
      const filter = filterByYearPlantTypePlantName(
        data,
        2021,
        'Mt. Coffee Hydro Plant',
        'MTC#2'
      );
      let unitData = getUnitAnalyticsData(filter.data);
      setUnitAnalyticsData([{ name: 'Force Outage Rate', data: unitData.for}, { name: 'Force Outage Factor', data: unitData.fof}, {name:"Availability Factor", data: unitData.af}, {name:"Capacity Foctor", data: unitData.cf}, {name:"Service Factor", data: unitData.sf}, {name:"Scheduled Outage Factor", data: unitData.sof}]);
    }
  }, [generationDataQuery]);
  return (
    <div>
      <Chart
        options={options}
        series={unitAnalyticsData}
        type='line'
        // width='1400'
        height='500'
      />
    </div>
  );
};

const sortByMonth = (data) => {
  return data.sort((a, b) => {
    const monthValueA = Object.keys(a)[0];
    const monthValueB = Object.keys(b)[0];
    if (monthValueA < monthValueB) {
      return -1;
    }
    if (monthValueA > monthValueB) {
      return 1;
    }
    return 0;
  });
};

const filterByYearPlantTypePlantName = (data, year, plantType, plantName) => {
  const initialData = { plantType, plantName, year, data: [] };

  const plants = [];
  data.forEach((curr) => {
    if (curr.plantType === plantType && curr.year === year) {
      let plant = {
        [curr.month - 1]: curr.plants.find((p) => p.plantName === plantName),
      };
      plants.push(plant);
    }
  });
  return {
    ...initialData,
    data: sortByMonth(plants),
  };
};

const createData = (kpi, value, period) => {
  switch (kpi) {
    case 'for':
      return kpiFunctions.for(value.forcedOutage, value.serviceHours, period);

    case 'sof':
      return kpiFunctions.sof(
        value.maintenanceHours,
        value.scheduledOutageHours,
        value.periodHours,
        period
      );
    case 'fof':
      return kpiFunctions.fof(value.forcedOutage, value.periodHours, period);
    case 'sf':
      return kpiFunctions.sf(value.serviceHours, value.periodHours, period);
    case 'fcr':
      return kpiFunctions.fcr(
        value.fuelConsumption,
        value.energyGenerated,
        period
      );
    case 'af':
      return kpiFunctions.af(
        value.periodHours,
        value.forcedOutage,
        value.maintenanceHours,
        value.serviceHours,
        period
      );
    case 'cf':
      return kpiFunctions.cf(
        value.energyGenerated,
        value.periodHours,
        value.installedCapacity,
        period
      );
    default:
      return {};
  }
};

const getUnitAnalyticsData = (data) => {
  const initialData = {
    for: [],
    fof: [],
    sof: [],
    fcr: [],
    sf: [],
    af: [],
    cf: [],
  };
  let quarterlyTotal = {
    for: 0,
    fof: 0,
    sof: 0,
    fcr: 0,
    sf: 0,
    af: 0,
    cf: 0,
  };

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const unitAnalyticsData = data.reduce((acc, curr, index) => {
    const kpiFactors = ['for', 'fof', 'sof', 'fcr', 'sf', 'af', 'cf'];
    kpiFactors.forEach((kpi) => {
      acc[kpi].push(createData(kpi, curr[index], monthNames[index]));
      quarterlyTotal[kpi] += acc[kpi][index].y;
      if (kpi === 'for') console.log(quarterlyTotal[kpi]);
    });

    // if ((index + 1) % 3 === 0) {
    //   kpiFactors.forEach((kpi) => {
    //     acc[kpi].push(
    //       new KpiDataPoint(
    //         (quarterlyTotal[kpi] / 3).toFixed(2),
    //         `QTR${Math.ceil(index / 3)}`,
    //         0,
    //         '#775DD0'
    //       )
    //     );
    //   });

    //   quarterlyTotal = {
    //     for: 0,
    //     fof: 0,
    //     sof: 0,
    //     fcr: 0,
    //     sf: 0,
    //     af: 0,
    //     cf: 0,
    //   };
    // }

    return acc;
  }, initialData);

  return unitAnalyticsData;
};

const kpiFunctions = {
  for: (foh, sh, period) => {
    if (foh === 0 && sh === 0) return new KpiDataPoint(0, period, 0);
    const y = (foh / (sh + foh)) * 100;
    return new KpiDataPoint(y, period, 0);
  },
  sof: (mh, so, ph, period) => {
    if (ph === 0) return new KpiDataPoint(0, period, 0);
    const y = ((mh + so) / ph) * 100;
    return new KpiDataPoint(y, period, 0);
  },
  fof: (foh, ph, period) => {
    if (ph === 0) return new KpiDataPoint(0, period, 0);
    const y = (foh / ph) * 100;
    return new KpiDataPoint(y, period, 0);
  },
  sf: (sh, ph, period) => {
    if (ph === 0) return new KpiDataPoint(0, period, 0);
    const y = (sh / ph) * 100;
    return new KpiDataPoint(y, period, 0);
  },
  fcr: (fc, eg, period) => {
    if (eg === 0) return new KpiDataPoint(0, period, 0);
    const y = (fc / eg) * 100;
    return new KpiDataPoint(y, period, 0);
  },
  af: (ph, foh, moh, soh, period) => {
    if (ph === 0) return new KpiDataPoint(0, period, 100);
    const y = ((ph - (foh + moh + soh)) / ph) * 100;
    return new KpiDataPoint(y, period, 0);
  },
  cf: (eg, ph, ic, period) => {
    if (ph === 0 || ic === 0) return new KpiDataPoint(0, period);
    const y = (eg / (ph * ic)) * 100;
    return new KpiDataPoint(y, period);
  },
};

const KpiDataPoint = function (value, period) {
  this.x = period;
  this.y = value;
  // this.goals = [
  //   {
  //     name: 'expected',
  //     value: expected || 0,
  //     strokeHeight: 5,
  //     strokeColor: color || '#775DD0',
  //   },
  // ];
};

export default SubPlantDetails;
