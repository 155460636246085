import React, { useContext, createContext, useState, useEffect } from 'react';

import { getPeriodInfo } from '../services/util';
import * as apiServices from '../services/apiServices';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const financialKpiContext = createContext();
const rootPath = '/financial-data';
export const useFinancialKpiContext = () => useContext(financialKpiContext);

const fetchFinancialData = async ({ queryKey }) => {
  const result = await apiServices.getAll(rootPath);
  return result.data;
};

const fetchFinancialAccountNames = async ({ queryKey }) => {
  const result = await apiServices.getAll(`${rootPath}/unique/names`);
  return result.data;
};
const fetchFinancialYears = async ({ queryKey }) => {
  const result = await apiServices.getAll(`${rootPath}/unique/years`);
  return result.data;
};
const fetchYearlyFinancialDataTotals = async ({ queryKey }) => {
  const [, year] = queryKey;
  const result = await apiServices.getAll(`${rootPath}/yearly-data/${year}`);
  return result.data;
}

const deleteData = async (id) => {
  const result = await apiServices.deleteData(`${rootPath}/delete/${id}`);
  return result.data;
};

const updateFinancialData = async (recordToEdit) => {
  const { _id, period, year, month, periodHours, ...data } = recordToEdit;
  const periodInfo = getPeriodInfo(period);
  if (!periodInfo) throw new Error('Period not in correct format');
  const { year: y, month: m } = periodInfo;
  const dataToEdit = { period, year: y, month: m, ...data };
  await apiServices.updateData(`${rootPath}/edit/${_id}`, dataToEdit);
  return { _id, ...dataToEdit };
};

const createFinancialData = async (data) => {
  try {
    const { period } = data;
    const periodInfo = getPeriodInfo(period);
    if (!periodInfo) throw new Error('Period not in correct format');
    const { year, month } = periodInfo;
    const financialData = { year, month, ...data };
    const result = apiServices.createData(rootPath, financialData);
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

const FinancialKpiContextProvider = ({ children }) => {


  const [selectedYear, setSelectedYear] = useState();
  const [years, setYears] = useState();
  const [yearlyFinancialData, setYearlyFinancialData] = useState([]);
  const [financialData, setFinancialData] = useState();

  const financialDataQuery = useQuery('financial-data', fetchFinancialData);
  const uniqueAccountNamesQuery = useQuery(
    'unique-account-names',
    fetchFinancialAccountNames
  );
  const financialYearsQuery = useQuery("financial-years", fetchFinancialYears)

  const yearlyFinancialDataTotalsQuery = useQuery(["yearly-financial-data-totals", selectedYear], fetchYearlyFinancialDataTotals,
        { enabled: !!selectedYear }
    );

  useEffect(() => {
    if(financialYearsQuery.data){
      const {data} = financialYearsQuery;
      setYears(data);
      setSelectedYear(data[0]);
    }
    // eslint-disable-next-line
  }, [financialYearsQuery.data])
  

  useEffect(() => {
    if(yearlyFinancialDataTotalsQuery.status === "success"){
      const {data} = yearlyFinancialDataTotalsQuery;
      setYearlyFinancialData(data);
    }
// eslint-disable-next-line
  }, [yearlyFinancialDataTotalsQuery.data]);

  useEffect(() => {
    if(financialDataQuery.data && selectedYear){
      const data = financialDataQuery.data
      const selectedData = data.filter(d=>d.year === selectedYear);
      setFinancialData(selectedData);
    }
  }, [financialDataQuery.data, selectedYear])
  


  const queryClient = useQueryClient();
  const { mutateAsync: submitFinancialData } = useMutation(
    createFinancialData,
    {
      onSuccess: (data) => {
        queryClient.setQueryData('financial-data', (financialData) => {
          return [...financialData, data.data];
        });
      },
    }
  );

  const { mutateAsync: editFinancialData } = useMutation(updateFinancialData, {
    onSuccess: (data) => {
      queryClient.setQueryData('financial-data', (financialData) => {
        if (!data) return;
        const updatedRecords = financialData.map((d) => {
          if (d._id === data._id) {
            return data;
          }
          return d;
        });
        return updatedRecords;
      });
    },
  });

  const { mutateAsync: deleteFinancialData } = useMutation(
    'financial-data',
    deleteData,
    {
      onSuccess: (data) => {
        queryClient.removeQueries('financial-data', data._id);
      },
    }
  );

  const value = {
    financialDataQuery,
    financialData,
    submitFinancialData,
    editFinancialData,
    deleteFinancialData,
    uniqueAccountNamesQuery,
    selectedYear,
    setSelectedYear,
    years,
    yearlyFinancialData
  };

  return (
    <financialKpiContext.Provider value={value}>
      {children}
    </financialKpiContext.Provider>
  );
};

export default FinancialKpiContextProvider;
