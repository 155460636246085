import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { appUserRoles } from '../../services/util';
import AddEditDepartmentWage from './AddEditDepartmentWage';
import DepartmentWageContextProvider from '../../contexts/departmentWagesContext';
import DepartmentWageList from './DepartmentWageList';
import PrivateRoute from '../routes/PrivateRoute';

const DepatmentWageKPI = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <DepartmentWageContextProvider>
        <PrivateRoute
          roles={appUserRoles}
          path={path}
          exact
          component={DepartmentWageList}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/form/:id`}
          exact
          component={AddEditDepartmentWage}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/form`}
          exact
          component={AddEditDepartmentWage}
        />
      </DepartmentWageContextProvider>
    </Switch>
  );
};

export default DepatmentWageKPI;
