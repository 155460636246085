import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import { alpha } from '@mui/system';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';

const TblContainer = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& thead th': {
            fontWeight: '700',
          },
          '& tbody td': {
            fontWeight: '400',
          },
          '& tbody tr:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
            cursor: 'pointer',
          },
        }}
      >
        {props.children}
      </Table>
    </TableContainer>
  );
};

export default TblContainer;
