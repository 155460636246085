import { Box } from '@mui/system';
import React from 'react'
import RadialChart from './RadialChart';
import CardTitle from './CardTitle';
import definitions from '../../services/kpiDefinitions';
import { cardBaseStyles } from '../styles';

const CapacityFactorCard = ({capacityFactor, chartLabel = "System  Capacity Factor"}) => {
    const percentCapacityFactor = parseFloat(100*capacityFactor).toFixed(1) || 0;
    return (
        <Box  sx={{
            ...cardBaseStyles
          }}>
            <CardTitle title="Capacity Factor" definition={definitions.capacityFactor}/>
            <RadialChart percentValue = {percentCapacityFactor} chartLabels={chartLabel}/>
        </Box>
    )
}

export default CapacityFactorCard
