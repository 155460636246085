import React from "react";
import { Box } from "@mui/system";
import MuiButton from "../controls/buttons/MuiButton";
import Icons from "../../icons";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PageHeader from "../pageHeader";
import { report } from "../pageHeader/pageTitle";
import { useHistory } from "react-router-dom";
import { generateExcelFile } from "./excelGenerator";
import { useReportsContext } from "../../contexts/reportsContext";
import Loading from "../controls/Loading";

const Report = () => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  const { powerPlantReportQuery, powerPlantPeakLoadQuery, plantAvailabilityQuery, transmissionReportQuery,
    customerBillingReportQuery, departmentWagesQuery, financialDataQuery  } = useReportsContext();
  const { data: flattenedGenerationData } = powerPlantReportQuery;
  const { data: peakLoadData } = powerPlantPeakLoadQuery;
  const { data: plantsAvailability } = plantAvailabilityQuery;
  const { data: transmissionData } = transmissionReportQuery;
  const { data: customerBillingData } = customerBillingReportQuery;
  const {data: departmentWagesData} = departmentWagesQuery;
  const {data: financialData} = financialDataQuery;
  const exportReport = async () => {
    await generateExcelFile(flattenedGenerationData, peakLoadData, plantsAvailability, transmissionData, customerBillingData, departmentWagesData, financialData);
  };
  return (
    <Box sx={{ height: "100%" }}>
      <MuiButton
        text="Back"
        color="primary"
        size="small"
        type="button"
        variant="outlined"
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
      />
      <PageHeader
        icon={Icons.report}
        title={report.title}
        subTitle={report.subtitle}
      />
      {powerPlantReportQuery.status === "loading" &&
       powerPlantPeakLoadQuery.status === "loading" &&
       plantAvailabilityQuery.status === "loading" &&
        transmissionReportQuery.status === "loading" &&
        customerBillingReportQuery.status === "loading"
       ? (
        <Loading />
      ) : (
        <Box sx={{ display: "grid", placeItems: "center", height: "100%" }}>
          <MuiButton
            text="Export to Excel"
            onClick={exportReport}
            variant="outlined"
            size="large"
            color="success"
            type="button"
            minWidth="160px"
            startIcon={Icons.download}
          />
        </Box>
      )}
    </Box>
  );
};

export default Report;
