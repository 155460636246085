import React from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/system/Box";

const SuccessToastNotification = ({setIsSuccessful, isOpen, message }) => {
  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSuccessful(false);
  };
  return (
    <Box>
      <Snackbar sx={{
          "& svg":{
              color:"background.paper"
          }
      }}
        open={isOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="success" onClose={closeSnackbar} sx={{bgcolor:"success.light", color:"background.paper"}}>
          {message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default SuccessToastNotification;
