import React from "react";
import Button from "@mui/material/Button";

const MuiActionButton = (props) => {
  const { color, children, onClick, ...other } = props;
  return (
    <Button color={color} onClick={onClick} {...other}>
      {children}
    </Button>
  );
};

export default MuiActionButton;
