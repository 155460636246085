import React from "react";
import { IconButton, Popover, Stack, Typography } from "@mui/material";
import { alpha, Box } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { yearlyDataCardBaseStyles } from "../styles";
import { formatFigure2 } from "../../services/util";
import DataUsageIcon from '@mui/icons-material/DataUsage';

const YearlyDataCard = ({
  value,
  title,
  definition,
  unit = "",
  color = "#00A19D",
  fronUnit = false,
  icon = <DataUsageIcon fontSize="medium" />
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const showInfo = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "info-popover" : undefined;
  return (
    <Box
      sx={{
        ...yearlyDataCardBaseStyles,
        p: 2,
        gridTemplateRows: "repeat(2, auto auto)",
        placeItems: "start",
      }}
      display="grid"
    >
      <Box>
        <Stack direction="row">
          <Box
            sx={{
              p: 0.7,
              borderRadius: "50%",
              bgcolor: value < 0 ? "#F5C0C0" : alpha(color, 0.2),
              display: "grid",
              placeItems: "center",
              color: value < 0 ? "#CE1212" : color,
            }}
          >
            {icon}
          </Box>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              ml: 1,
              color: "text.secondary",
            }}
          >
            {title}
          </Box>
        </Stack>
      </Box>
      <Box sx={{ fontSize: "24px", fontWeight: "bold", mt: 1, width:1 }}>
        {`${fronUnit? unit : ""}${formatFigure2(value)}  ${fronUnit? "" : unit}`}
        {definition ? (
          <Box display={"inline"} justifyContent = "right" sx = {{mr:1}}>
            <IconButton
              aria-describedby={id}
              onClick={showInfo}
              sx={{ color }}
            >
              <InfoOutlinedIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box
                component={Typography}
                sx={{
                  p: 2,
                  fontSize: 11,
                  fontWeight: "medium",
                  maxWidth: "230px",
                  color: "success.dark",
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                }}
              >
                {definition}
              </Box>
            </Popover>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default YearlyDataCard;
