import { alpha } from "@mui/system";
import { FOUR_CARDS_MIN_HEIGHT as cardHeight } from "../../constants";
export const cardBaseStyles = {
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 2,
    boxShadow: 0,
    minHeight: cardHeight,
};
export const financialCardBaseStyles2 = {
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 2,
    boxShadow: 0,
    minHeight: "300px",
};
export const billingCardBaseStyles = {
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 2,
    boxShadow: 0,
    minHeight: "380px",
};
export const energyBillingCardBaseStyles = {
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 2,
    boxShadow: 0,
    minHeight: "250px",
};
export const subplantCardBaseStyles = {
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 2,
    boxShadow: 0,
    minHeight: "180px",
};
export const financialCardBaseStyles = {
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
    boxShadow: 0,
    minHeight: "120px",
};
export const yearlyDataCardBaseStyles = {
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
    boxShadow: 0,
    minHeight: "160px",
};
export const transmissionCardBaseStyles = {
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
    boxShadow: 0,
    minHeight: "180px",
};
export const transmissionCardBaseStyles2 = {
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
    boxShadow: 0,
    minHeight: "250px",
};
export const periodTransCardBaseStyles = {
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
    boxShadow: 0,
    minHeight: "140px",
};
export const subCardBaseStyles = {
    bgcolor:(theme) => alpha(theme.palette.primary.light, 0.1),
    //bgcolor:"#fafafa", //(theme) => alpha(theme.palette.primary.main, 0.1),
    borderRadius: "8px",
    p: 1,
    boxShadow: 1,
    display: "grid", 
    placeItems: "center"
};