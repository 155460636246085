import { Divider, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { financialCardBaseStyles2 } from "../styles";

const AccountCard = ({ account }) => {
  const formatFigure = (figure) => {
    if (!figure) return;
    return parseFloat(figure.toFixed(2)).toLocaleString();
  };
  const calculatePercentage = (value, totalValue) =>{
      if(!totalValue) return "0%";
      const percentage = (value/totalValue) *100;
      if(isNaN(percentage)) return "0%";
      return percentage.toFixed(1) + "%";
  }
  return (
    <Box
      sx={{
        ...financialCardBaseStyles2,
        display: "grid",
        gridTemplateRows: "repeat(3, 8% auto auto)",
      }}
    >
      <Box sx={{fontWeight:"bold", color:"text.secondary"}}>{account.name}</Box>
      <Box sx={{ fontSize: "24px", fontWeight: "bold", mb: 1 }}>
        {`$ ${formatFigure(account.total)}`}
      </Box>
      <Stack
        direction="column"
        divider={<Divider orientation="horizontal" flexItem />}
        spacing={2}
      >
        {account.accounts.map((subAccount) => (
          <Stack
            key={subAccount.name}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{fontSize:"13px"}}>{subAccount.name}</Box>
            <Box sx={{fontWeight:"bold", color:"text.secondary", fontSize:"13px"}}>{`${calculatePercentage(subAccount.value, account.total)}`}</Box>
            <Box sx={{fontWeight:"bold", fontSize:"13px"}}>{`$ ${formatFigure(subAccount.value)}`}</Box>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default AccountCard;
