import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { appUserRoles } from '../../services/util';
import AddEditTransmissionKpi from './AddEditTransmissionKpi';
import TransmissionKpiContextProvider from '../../contexts/transmissionKpiContext';

import TransmissionKpiList from './TransmissionKpiList';
import PrivateRoute from '../routes/PrivateRoute';
import TransmissionKpiDetails from './TransmissionKpiDetails';

const GenerationKPI = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <TransmissionKpiContextProvider>
        <PrivateRoute
          roles={appUserRoles}
          path={path}
          exact
          component={TransmissionKpiDetails}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/table`}
          exact
          component={TransmissionKpiList}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/table/form/:id`}
          exact
          component={AddEditTransmissionKpi}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/table/form`}
          exact
          component={AddEditTransmissionKpi}
        />
      </TransmissionKpiContextProvider>
    </Switch>
  );
};

export default GenerationKPI;
