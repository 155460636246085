import React from "react";
import { useAuthContext } from "../../contexts/authContext";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { currentUser } = useAuthContext();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!!currentUser && roles && roles.indexOf(currentUser.role) !== -1)
          return <Component {...props} />;
        else
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
      }}
    />
  );
};

export default PrivateRoute;
