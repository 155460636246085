import Box from '@mui/system/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFinancialKpiContext } from '../../contexts/financialKpiContext';
import PageHeader from '../pageHeader';
import MuiButton from '../controls/buttons/MuiButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Selector from '../controls/Selector';
import { getPeriods } from '../../services/util';
import * as yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import SuccessToastNotification from '../controls/SuccessToastNotification';
import ErrorToastNotification from '../controls/ErrorToastNotification';
import { IconButton } from '@mui/material';
import TotalDependentInput from './TotalDependentInput';
import Icons from '../../icons';

const validationSchema = yup.object({
  period: yup.string('Enter a valid period').required('period is required'),
  name: yup.string('Enter a valid name').required('name is required'),
  total: yup.number('Enter a valid total').required('total is required'),
  accounts: yup.array().of(
    yup.object({
      name: yup.string('Enter a valid name').required('name is required'),
      value: yup.number('Enter a valid value').required('value is required'),
    })
  ),
});

const periods = getPeriods();
const initialValues = {
  period: '',
  name: '',
  total: '',
  accounts: [],
};

const AddEditFinancialData = () => {
  //TODO - make form responsive
  const history = useHistory();
  const { id } = useParams();
  const [hasError, setHasError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [mode, setMode] = useState('');
  const [error, setError] = useState('');

  const {
    financialDataQuery,
    submitFinancialData,
    editFinancialData,
    uniqueAccountNamesQuery,
  } = useFinancialKpiContext();

  useEffect(() => {
    if (id) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [id]);

  const goBack = () => {
    history.goBack();
  };
  const getAccountNames = (queryResult) => {
    const { status, data } = uniqueAccountNamesQuery;
    if (status === 'success') {
      return data;
    }
    return [];
  };

  const getAccountToEdit = () => {
    const { status, data } = financialDataQuery;
    if (status === 'success') {
      return data.find((d) => d._id === id);
    } else {
      return initialValues;
    }
  };

  const getSubAccountsForAdding = (queryResult, name) => {
    const { status, data } = queryResult;
    if (!data) return [];
    if (status === 'success') {
      const account = data.find((d) => d.name === name);
      if (!account) return [];
      return account.accounts.map((a) => ({ name: a.name, value: '' }));
    }
    return [];
  };

  const handleError = (error) => {
    if (error.response) {
      setError(error.response.data.msg);
    } else if (error.request) {
      setError('Please check your internet connection');
    } else {
      setError(error.message);
    }
  };

  return (
    <Box>
      <MuiButton
        text='Back'
        color='primary'
        size='small'
        type='button'
        variant='outlined'
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
      />
      <PageHeader
        icon={Icons.financialKpi }
        title={
          mode === 'add'
            ? 'Add Financial Data Form'
            : 'Edit Financial Data Form'
        }
        subTitle={`Form for ${mode}ing financial data.`}
      />
      <Formik
        initialValues={id ? getAccountToEdit() : initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          if (mode === 'add') {
            await submitFinancialData(values, {
              onError: (error) => {
                setHasError(true);
                handleError(error);
              },
              onSuccess: () => {
                resetForm();
                setIsSuccessful(true);
              },
            });
          } else if (mode === 'edit') {
            await editFinancialData(values, {
              onError: (error) => {
                setHasError(true);
                handleError(error);
              },
              onSuccess: () => {
                resetForm();
                setIsSuccessful(true);
              },
            });
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          isValid,
          dirty,
          handleReset,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                bgcolor: 'background.paper',
                p: 2,
                borderRadius: 2,
                '&>div': {
                  width: '100%',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                    columnGap: 2,
                    rowGap: 2,
                    flexWrap: 'wrap',
                  }}
                >
                  <Selector
                  options={periods}
                  name='period'
                  label='Period'
                  onChange={handleChange}
                  value={values.period}
                  onBlur={handleBlur}
                  error={touched.period && Boolean(errors.period)}
                  helperText={
                    touched.period && errors.period ? errors.period : ''
                  }
                />
                  <Autocomplete
                    fullWidth
                    options={getAccountNames(financialDataQuery)}
                    value={values.name}
                    onOpen={handleBlur}
                    freeSolo={true}
                    onInputChange={(e, value) => {
                      setFieldValue('name', value || '', true);
                    }}
                    onChange={(e, value, reason) => {
                      setFieldValue('name', value || '', true);
                      if (reason === 'clear') {
                        setFieldValue('accounts', [], true);
                        return;
                      }
                      const subAccounts = getSubAccountsForAdding(
                        financialDataQuery,
                        value || ''
                      );
                      if (!subAccounts) return;
                      setFieldValue('accounts', subAccounts, true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='contact'
                        variant='outlined'
                        type='text'
                        label='Name'
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={
                          touched.name && errors.name ? errors.name : ''
                        }
                      />
                    )}
                  />
                  <TotalDependentInput
                    label='Total'
                    value={values.total}
                    type='number'
                    onChange={handleChange}
                    name='total'
                    onBlur={handleBlur}
                    error={touched.total && Boolean(errors.total)}
                    helperText={
                      touched.total && errors.total ? errors.total : ''
                    }
                    adornmentText='$'
                  />
                </Box>
                <Box>
                  <FieldArray
                    name='accounts'
                    render={(arrayHelpers) => (
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <h4>Sub Accounts</h4>
                          <Button
                            sx={{ height: '40px' }}
                            onClick={() => {
                              arrayHelpers.push({ name: '', value: '' });
                            }}
                          >
                            Add Sub Account
                          </Button>
                        </Box>
                        {values.accounts.map((account, index) => (
                          <Box
                            key={index}
                            sx={{
                              mb: 2,
                              display: 'flex',
                              gap: 2,
                            }}
                          >
                            <TextField
                              name={`accounts.${index}.name`}
                              variant='outlined'
                              value={account.name}
                              type='text'
                              label='Name'
                              onBlur={handleBlur}
                              error={
                                touched.accounts &&
                                touched.accounts[index]?.name &&
                                Boolean(
                                  errors.accounts &&
                                    errors.accounts[index]?.name
                                )
                              }
                              helperText={
                                touched.accounts &&
                                touched.accounts[index]?.name &&
                                errors.accounts &&
                                errors.accounts[index]?.name
                                  ? errors.accounts[index]?.name
                                  : ''
                              }
                              onChange={handleChange}
                              fullWidth
                            />
                            <TextField
                              name={`accounts.${index}.value`}
                              variant='outlined'
                              type='number'
                              value={account.value}
                              label='Value'
                              onBlur={handleBlur}
                              error={
                                touched.accounts &&
                                touched.accounts[index]?.value &&
                                Boolean(
                                  errors.accounts &&
                                    errors.accounts[index]?.value
                                )
                              }
                              helperText={
                                touched.accounts &&
                                touched.accounts[index]?.value &&
                                errors.accounts &&
                                errors.accounts[index]?.value
                                  ? errors.accounts[index]?.value
                                  : ''
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <IconButton
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    )}
                  />

                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <LoadingButton
                      sx={{ ml: 1, my: 2, textTransform: 'none' }}
                      disabled={isSubmitting || !isValid || !dirty}
                      loading={isSubmitting}
                      variant='contained'
                      size='large'
                      type='submit'
                    >
                      Submit
                    </LoadingButton>
                    <Button
                      sx={{ ml: 1, my: 2, textTransform: 'none' }}
                      disabled={isSubmitting}
                      onClick={handleReset}
                      size='large'
                      color='secondary'
                      variant='contained'
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <SuccessToastNotification
        isOpen={isSuccessful}
        setIsSuccessful={setIsSuccessful}
        message='Form successfully saved!'
      />
      <ErrorToastNotification
        isOpen={hasError}
        setHasError={setHasError}
        message={error}
      />
    </Box>
  );
};

export default AddEditFinancialData;
