import React from "react";
import TextField from "@mui/material/TextField";
const Input = (props) => {
  const { name, label, value, onChange, type = "text", ...others } = props;
  return (
    <TextField
      sx={{ ml: 1, my:2, minWidth: {xs:"280px", sm:"420px"} }}
      name={name}
      placeholder={label}
      label={label}
      value={value}
      onChange={onChange}
      type={type}
      variant="outlined"
      {...others}
    />
  );
};

export default Input;
