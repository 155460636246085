import React from 'react';
import TablePagination from '@mui/material/TablePagination';

const TblPagination = ({
  rowsPerPageOptions,
  rowsPerPage,
  page,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
}) => (
  <TablePagination
    component='div'
    rowsPerPageOptions={rowsPerPageOptions}
    rowsPerPage={rowsPerPage}
    page={page}
    count={count}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
);

export default TblPagination;
