import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { appUserRoles } from '../../services/util';
import AddEditCustomerBilling from './AddEditCustomerBilling';
import CustomerBillingsContextProvider from '../../contexts/customerBillingsContext';

import CustomerBillingCollapsibleList from './CustomerBillingCollapsibleList';
import PrivateRoute from '../routes/PrivateRoute';
import CustomerBillingDetails from './CustomerBillingDetails';

const GenerationKPI = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <CustomerBillingsContextProvider>
        <PrivateRoute
          roles={appUserRoles}
          path={path}
          exact
          component={CustomerBillingDetails}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/table`}
          exact
          component={CustomerBillingCollapsibleList}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/table/form/:id`}
          exact
          component={AddEditCustomerBilling}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/table/form`}
          exact
          component={AddEditCustomerBilling}
        />
      </CustomerBillingsContextProvider>
    </Switch>
  );
};

export default GenerationKPI;
