import {useState, createContext, useContext} from "react";
import {login, logout, getCurrentUser } from "../services/authService";

const authContext = createContext();

export const useAuthContext = ()=>{
    return useContext(authContext);
};

const AuthContextProvider = ({children}) => {

    const [currentUser, setCurrentUser] = useState(getCurrentUser());

    const loginUser = async({email, password})=>{
        const user = await login(email, password);
        if(!user) {
            setCurrentUser(null);
            return;
        }
        setCurrentUser(user);
    }

    const logoutUser = ()=>{
        logout();
        setCurrentUser(null);
    }

    const value = {
        currentUser,
        setCurrentUser,
        loginUser,
        logoutUser
    }
    return (
        <authContext.Provider value={value}>
            {children}
        </authContext.Provider>
    )
}
export default AuthContextProvider;