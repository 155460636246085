import { Divider, Stack } from "@mui/material";
import { Box, alpha } from "@mui/system";
import React from "react";
import { formatFigure3 } from "../../services/util";
import { energyBillingCardBaseStyles } from "../styles";

const EnergyBillingCard = ({ title, billingData, unit }) => {
  if (!billingData) return null;
  return (
    <Box sx={{ ...energyBillingCardBaseStyles }}>
      <Stack direction={"column"}>
        <Box
          sx={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            width: 1,
          }}
        >
          {title}
          <Box sx={{ fontWeight: "bold", ml: 2 }}>{`${formatFigure3(
            billingData.totalBill,
            0
          )} ${unit}`}</Box>
        </Box>
        <Box
          sx={{ fontSize: "18px", color: "text.secondary" }}
        >{`${formatFigure3(
          billingData.totalCustomerCount,
          0
        )}  total customers`}</Box>
      </Stack>
      <Divider sx = {{mb:1}}/>
      <Stack>
        {billingData.billings.map((billing) => (
          <Stack sx={{ fontSize: "15px",  p:1}} spacing={2} key = {billing.customerType}>
            <Box sx = {{bgcolor: alpha("#E8F6EF", 0.9), p:1, borderRadius:"10px" }}>
              <Stack  direction="row" spacing={2}
                sx={{
                  fontWeight: "bold",
                  width: 1, margin: 0.5
                }}
                justifyContent="space-between"
              >
                <Box>{billing.customerType}</Box>
                <Box>{`${formatFigure3(billing.energyBilled, 1)} ${unit}`}</Box>
              </Stack>
              <Box>{`${formatFigure3(billing.customerCount)} ${billing.customerCount <=1? "customer" : "customers"}`}</Box>
            </Box>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default EnergyBillingCard;
