import React, { useState, useEffect, useContext, createContext } from 'react';

import { getPeriodInfo } from '../services/util';
import * as apiServices from '../services/apiServices';
import { useMutation, useQuery, useQueryClient } from 'react-query';
const generationKpiContext = createContext();

const rootPath = '/power-plant-data';

export const useGenerationKpiContext = () => useContext(generationKpiContext);

const fetchGenerationData = async ({ queryKey }) => {
  const result = await apiServices.getAll(rootPath);
  return result.data;
};
const fetchPowerPlantKPIs = async ({ queryKey }) => {
  const [, year] = queryKey;
  const result = await apiServices.getAll(`${rootPath}/kpis/${year}`);
  return result.data;
};

const fetchPowerPlantTypes = async ({ queryKey }) => {
  const result = await apiServices.getAll(`${rootPath}/unique/types`);
  return result.data;
};
const fetchUniqueYears = async ({ queryKey }) => {
  const result = await apiServices.getAll(`${rootPath}/unique/years`);
  return result.data;
};
const fetchGenerationDataByPlantName = async ({ queryKey }) => {
  const [, plantName] = queryKey;
  const result = await apiServices.getGenerationDataByPlantName(plantName);
  return result.data;
};


const getPeriodsAndPlants = (data) => {
  if (!data) return { periods: [], years: [], plantNames: [] };

  const periods = data
    .map((d) => d.period)
    .filter((value, index, self) => self.indexOf(value) === index);

  const years = data
    .map((d) => d.year)
    .filter((value, index, self) => self.indexOf(value) === index);
  const names = data
    .map((d) => d.plantName)
    .filter((value, index, self) => self.indexOf(value) === index);
  return { periods, years, plantNames: names };
};
const deleteData = async (id) => {
  const result = await apiServices.deleteData(`${rootPath}/delete/${id}`);
  return result.data;
};

const updateGenerationData = async (recordToEdit) => {
  const { _id, period, year, month, periodHours, ...data } = recordToEdit;
  const periodInfo = getPeriodInfo(period);
  if (!periodInfo) throw new Error('Period not in correct format');
  const { year: y, month: m, totalHours: ph } = periodInfo;
  const generationData = {
    period,
    year: y,
    month: m,
    periodHours: ph,
    ...data,
  };
  await apiServices.updateData(`${rootPath}/edit/${_id}`, generationData);
  return { _id, ...generationData };
};

const createGenerationData = async (data) => {
  try {
    const { period } = data;
    const periodInfo = getPeriodInfo(period);
    if (!periodInfo) throw new Error('Period not in correct format');
    const { year, month, totalHours: periodHours } = periodInfo;
    const generationData = { year, month, periodHours, ...data };
    const result = apiServices.createData(rootPath, generationData);
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

const GenerationKpiContextProvider = ({ children }) => {
  const [selectedYear, setSelectedYear] = useState();
  const [selectedDataName, setSelectedDataName] = useState();
  const [selectedPlantType, setSelectedPlantType] = useState();
  const [generationKpis, setGenerationKpis] = useState([]);
  const [generationRecords, setGenerationRecords] = useState();
  const generationDataQuery = useQuery('generation-data', fetchGenerationData);
  const [options, setOptions] = useState()
  const powerPlantTypesQuery = useQuery(
    'power-plant-types',
    fetchPowerPlantTypes
  );
  const uniqueYearsQuery = useQuery(
    'unique-years',
    fetchUniqueYears
  );

  const yearlyPowerPlantKpisQuery = useQuery(["yearly-power-plants-kpis", selectedYear], fetchPowerPlantKPIs,
    { enabled: !!selectedYear }
  );

  useEffect(() => {

    if (powerPlantTypesQuery.data && uniqueYearsQuery.data) {
      const plantTypes = JSON.parse(JSON.stringify(powerPlantTypesQuery.data))
      setSelectedPlantType(plantTypes.reverse()[0]);
      const years = uniqueYearsQuery.data;
      setSelectedYear(years[0]);
    }
    //eslint-disable-next-line
  }, [powerPlantTypesQuery.data, uniqueYearsQuery.data])

  useEffect(() => {
    if (selectedPlantType && selectedYear && yearlyPowerPlantKpisQuery.data) {
      const data = yearlyPowerPlantKpisQuery.data;
      const kpis = data.filter(d=>d.plantType === selectedPlantType);
      const options = getPeriodsAndPlants(kpis);
      setOptions(options);
      setGenerationKpis(kpis);
    }
    //eslint-disable-next-line
  }, [selectedPlantType, selectedYear, yearlyPowerPlantKpisQuery.data]);

  useEffect(() => {
    if(selectedYear && generationDataQuery.data) {
      const data = generationDataQuery.data.filter(d=>d.year === selectedYear);
      setGenerationRecords(data);
    }
    //eslint-disable-next-line
  }, [selectedYear, generationDataQuery.data])
  

  const selectedPowerPlantDataQuery = useQuery(
    ['selected-generation-data', selectedDataName],
    fetchGenerationDataByPlantName
  );
  const queryClient = useQueryClient();
  const { mutateAsync: submitGenerationData } = useMutation(
    createGenerationData,
    {
      onSuccess: (data) => {
        queryClient.setQueryData('generation-data', (generationData) => {
          return [...generationData, data.data];
        });
      },
    }
  );

  const { mutateAsync: editGenerationData } = useMutation(
    updateGenerationData,
    {
      onSuccess: (data) => {
        queryClient.setQueryData('generation-data', (generationData) => {
          if (!data) return;
          const updatedRecords = generationData.map((d) => {
            if (d._id === data._id) {
              return data;
            }
            return d;
          });
          return updatedRecords;
        });
      },
    }
  );

  const { mutateAsync: deleteGenerationData } = useMutation(
    'generation-data',
    deleteData,
    {
      onSuccess: (data) => {
        queryClient.removeQueries('generation-data', data._id);
      },
    }
  );
  const value = {
    generationDataQuery,
    submitGenerationData,
    editGenerationData,
    generationRecords,
    powerPlantTypesQuery,
    setSelectedDataName,
    selectedDataName,
    selectedPowerPlantDataQuery,
    deleteGenerationData,
    generationKpis,
    setSelectedPlantType,
    selectedPlantType,
    options
  };

  return (
    <generationKpiContext.Provider value={value}>
      {children}
    </generationKpiContext.Provider>
  );
};

export default GenerationKpiContextProvider;
