import { Box } from "@mui/system";
import React from "react";
import { financialCardBaseStyles2 } from "../styles";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Stack } from "@mui/material";
import SmoothLineChart from "../controls/SmoothLineChart";

const FinancialCardWithChart = ({
  title,
  seriesData,
  value,
  unit = "$",
  color = "#52734D",
}) => {
  const formatFigure = (figure) => {
    if (!figure) return;
    return parseFloat(figure.toFixed(2)).toLocaleString();
  };

  return (
    <Box
      sx={{
        ...financialCardBaseStyles2,
        display: "grid",
        gridTemplateRows: "repeat(2, auto auto)",
      }}
    >
      <Box>
        <Stack direction="column">
          <Stack direction="row">
            <Box
              sx={{
                p: 1,
                borderRadius: "50%",
                bgcolor: "#CDDEFF",
                display: "grid",
                placeItems: "center",
                color: "#00A19D",
              }}
            >
              <MonetizationOnIcon />
            </Box>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                ml: 1,
                color: "text.secondary",
                fontSize: 18,
              }}
            >
              {title}
            </Box>
          </Stack>
          <Box sx={{ fontSize: "24px", fontWeight: "bold", mt: 1 }}>
            {`$ ${formatFigure(value)}`}
          </Box>
        </Stack>
      </Box>
      <Box sx = {{p:1}}>
        <SmoothLineChart
          color={color}
          height="160px"
          title={title}
          unit={unit}
          frontUnit={true}
          opacityFrom = {0.6}
          seriesData={seriesData}
        />
      </Box>
    </Box>
  );
};

export default FinancialCardWithChart;
