import { Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { useCustomerBillingsContext } from "../../contexts/customerBillingsContext";
import Icons from "../../icons";
import MuiButton from "../controls/buttons/MuiButton";
import Loading from "../controls/Loading";
import Selector from "../controls/Selector";
import PageHeader from "../pageHeader";
import { customerBillingKpi } from "../pageHeader/pageTitle";
import CustomerBillingsChart from "./CustomerBillingsChart";
import EnergyBillingCard from "./EnergyBillingCard";

const getCustomerBillings = (data, year) => {
  if (!data || !year) return;

  const billingSeries = [];
  const customerCountsSeries = [];
  data.reverse().forEach((record) => {
    const { paymentType, totalCustomerCount, period } = record;
    const totalBill = parseFloat(record.totalBill.toFixed(1));
    const billSeries = {
      name: paymentType,
      data: [{ x: period, y: totalBill }],
    };
    const countSeries = {
      name: paymentType,
      data: [{ x: period, y: totalCustomerCount }],
    };
    const index1 = billingSeries.findIndex((bs) => bs.name === paymentType);
    const index2 = customerCountsSeries.findIndex(
      (bs) => bs.name === paymentType
    );
    if (index1 === -1) {
      billingSeries.push(billSeries);
    } else {
      billingSeries[index1].data.push({ x: period, y: totalBill });
    }
    if (index2 === -1) {
      customerCountsSeries.push(countSeries);
    } else {
      customerCountsSeries[index1].data.push({
        x: period,
        y: totalCustomerCount,
      });
    }
  });
  return { billingSeries, customerCountsSeries };
};

const getTotalEnergyBill = (data) => {
  if (!data) return;
  const total = data.reduce((acc, curr) => acc + curr.totalBill, 0);
  return parseFloat(total.toFixed(0));
};
const getTotalCustomer = (data) => {
  if (!data) return;
  const { period } = data[0];
  const total = data
    .filter((d) => d.period === period)
    .reduce((acc, curr) => acc + curr.totalCustomerCount, 0);
  return parseFloat(total.toFixed(0));
};
const CustomerBillingDetails = () => {
  const [energyBillingSeries, setEnergyBillingSeries] = useState();
  const [customerCountSeries, setCustomerCountSeries] = useState();

  const {
    customerBillingsQuery,
    years,
    selectedYear,
    setSelectedYear,
    yearlyBillingRecords,
  } = useCustomerBillingsContext();
  const { path } = useRouteMatch();
  const history = useHistory();
  const navigateToTable = () => {
    history.push(`${path}/table`);
  };
  const selectYear = (event) => {
    const value = event.target.value;
    setSelectedYear(value);
  };

  useEffect(() => {
    const { data } = customerBillingsQuery;
    if (data && selectedYear) {
      const dataCopy = JSON.parse(JSON.stringify(data));
      const { billingSeries, customerCountsSeries } = getCustomerBillings(
        dataCopy,
        selectedYear
      );
      setEnergyBillingSeries(billingSeries);
      setCustomerCountSeries(customerCountsSeries);
    }

    //eslint-disable-next-line
  }, [customerBillingsQuery.data, selectedYear]);

  return (
    <Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 2, sm: 2 }}
        justifyContent="space-between"
      >
        <PageHeader
          icon={Icons.customerBilling}
          title={customerBillingKpi.title}
          subTitle={customerBillingKpi.subtitle}
        />
        <Box sx={{ display: { sm: "grid" }, placeItems: { sm: "center" } }}>
          {customerBillingsQuery.status === "loading" ? (
            <Skeleton />
          ) : (
            <Selector
              name="selectedYear"
              label="Select Year"
              value={selectedYear}
              onChange={selectYear}
              width="160px"
              options={years}
            />
          )}
        </Box>
        <Box sx={{ display: { sm: "grid" }, placeItems: { sm: "center" } }}>
          <MuiButton
            text="Data Table"
            variant="outlined"
            size="large"
            color="primary"
            type="button"
            minWidth="160px"
            onClick={navigateToTable}
            startIcon={Icons.dataTable}
          />
        </Box>
      </Stack>
      {customerBillingsQuery.status !== "success" ? (
        Loading
      ) : (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={2}
          sx={{ mt: { xs: 2 } }}
        >
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6" }}
            sx={{ borderRadius: "10px" }}
          >
            {energyBillingSeries && yearlyBillingRecords ? (
              <CustomerBillingsChart
                dataSeries={energyBillingSeries}
                totalValue={getTotalEnergyBill(yearlyBillingRecords)}
                unit={"MWh"}
                title={"Total Energy Billed"}
                selectedYear={selectedYear}
              />
            ) : null}
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6" }}
            sx={{ borderRadius: "10px" }}
          >
            {customerCountSeries && yearlyBillingRecords ? (
              <CustomerBillingsChart
                dataSeries={customerCountSeries}
                totalValue={getTotalCustomer(yearlyBillingRecords)}
                title={"Total Customers"}
                stacked={false}
                selectedYear={selectedYear}
              />
            ) : null}
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6" }}
            sx={{ borderRadius: "10px" }}
          >
            {yearlyBillingRecords ? (
              <EnergyBillingCard
                billingData={yearlyBillingRecords.find(
                  (ybr) => ybr.paymentType === "Pre pay"
                )}
                title={"Pre pay  energy consumption"}
                unit={"MWh"}
              />
            ) : null}
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6" }}
            sx={{ borderRadius: "10px" }}
          >
            {yearlyBillingRecords ? (
              <EnergyBillingCard
                billingData={yearlyBillingRecords.find(
                  (ybr) => ybr.paymentType === "Post pay"
                )}
                title={"Post pay energy consumption"}
                unit={"MWh"}
              />
            ) : null}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CustomerBillingDetails;
