import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { formatFigure3 } from "../../services/util";

const ColumnChart = ({ dataSeries = [], title, unit="", height = "350px", stacked=false }) => {
  const series = dataSeries;
  const options = {
    chart: {
      type: "bar",
      stacked,
      height: height,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    
    yaxis: {
      title: {
        text: `${title} ${unit? unit: ""}`,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${formatFigure3(val)} ${unit}`;
        },
      },
    },
  };
  return (
    <Box sx={{ p: 0.5 }}>
      <ReactApexChart
        height={height}
        options={options}
        series={series}
        type="bar"
      />
    </Box>
  );
};

export default ColumnChart;
