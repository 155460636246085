import axios from "axios";

export default function api(options = {}){
    const {headers = {}} = options;
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-Type": "application/json",
            ...headers
        }
    })
}
