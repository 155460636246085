import React from "react";
import Button from "@mui/material/Button";

const MuiButton = ({ text, size, color, variant, minWidth, onClick, extraStyle, ...other }) => {
  return (
    <Button
      variant={variant || "contained"}
      size={size}
      color={color}
      onClick={onClick}
      {...other}
      sx={{
          ...extraStyle,
        margin:{sm: 0.5},
        textTransform: "none",
        minWidth:{minWidth},
        maxHeight:"40px"
      }}
    >
      {text}
    </Button>
  );
};

export default MuiButton;
