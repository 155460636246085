export const COLORS= 
{
    NAV_BAR_COLOR : "#FFFFFF",
    MAIN_COLOR : "#E3EEFA",
    CHART_COLOR: "#297F87",
    CHART_GRADIENT_COLOR: "#93B5C6"
    
}

export const DRAWER_WIDTH = 240;
export const FOUR_CARDS_MIN_HEIGHT = "220px";