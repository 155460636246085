import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";

const SmoothLineChart = ({title, seriesData = [], unit, color, height, frontUnit = false, opacityFrom = 0.3, opacityTo = 0.1}) => {
  const series = [
    {
      name: title,
      data: seriesData,
    },
  ];
  const options = {
    chart: {
      type: "area",
      height,
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: "smooth",
      //curve: 'straight',
      width: "1.5",
    },
    colors: [color],
    fill: {
        gradient: {
            enabled: true,
            opacityFrom,
            opacityTo
        }
    },
    tooltip: {
      y: {
        formatter: function (y) {
          if (y !== undefined) { 
            if(frontUnit) return `${unit} ` + y.toLocaleString()
            return y.toLocaleString() + ` ${unit}`
          };
          return y;
        },
      },
    },
  };
  return (
    <Box sx={{p:0.5}}>
      <ReactApexChart
        height={height}
        options={options}
        series={series}
        type="area"
      />
    </Box>
  );
};

export default SmoothLineChart;
