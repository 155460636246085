import Box from '@mui/system/Box';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCustomerBillingsContext } from '../../contexts/customerBillingsContext';
import PageHeader from '../pageHeader';
import MuiButton from '../controls/buttons/MuiButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Selector from '../controls/Selector';
import { getPeriods } from '../../services/util';
import * as yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import SuccessToastNotification from '../controls/SuccessToastNotification';
import ErrorToastNotification from '../controls/ErrorToastNotification';
import { IconButton, Paper, Typography } from '@mui/material';
import DependentInput from '../controls/formControls/DependentInput';
import { useMediaQuery } from '@mui/material';

const validationSchema = yup.object({
  period: yup.string('Enter a valid period').required('period is required'),
  paymentType: yup
    .string('Enter a valid Payment Type')
    .required('Payment Type is required'),
  totalBill: yup
    .number('Enter a valid total Bill')
    .required('total Bill is required'),
  totalCustomerCount: yup
    .number('Enter a valid Total Customer Count')
    .required('Total Customer Count is required'),
  billings: yup.array().of(
    yup.object({
      customerType: yup
        .string('Enter a valid customer type')
        .required('customer type is required'),
      energyBilled: yup
        .number('Enter a valid energy billed')
        .required('energy billed is required'),
      customerCount: yup
        .number('Enter a valid customer count')
        .required('customer count is required'),
    })
  ),
});

const periods = getPeriods();
const initialValues = {
  paymentType: '',
  period: '',
  totalBill: '',
  totalCustomerCount: '',
  billings: [],
};

const AddEditCustomerBilling = () => {
  //TODO - make form responsive
  const history = useHistory();
  const { id } = useParams();
  const [hasError, setHasError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [mode, setMode] = useState('');
  const [error, setError] = useState('');
  const isMedium = useMediaQuery('(min-width:900px)');

  const {
    customerBillingsQuery,
    submitCustomerBilling,
    editCustomerBilling,
    uniquePaymentTypesQuery,
  } = useCustomerBillingsContext();

  useEffect(() => {
    if (id) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [id]);

  const goBack = () => {
    history.goBack();
  };
  const getPaymentTypes = () => {
    const { status, data } = uniquePaymentTypesQuery;
    if (status === 'success') {
      return data;
    }
    return [];
  };

  const getBillingInfoToEdit = () => {
    const { status, data } = customerBillingsQuery;
    if (status === 'success') {
      return data.find((d) => d._id === id);
    } else {
      return initialValues;
    }
  };

  const getBillingsByPaymentTypeForAdding = (queryResult, paymentType) => {
    const { status, data } = queryResult;
    if (!data) return [];
    if (status === 'success') {
      const account = data.find((d) => d.paymentType === paymentType);
      if (!account) return [];
      return account.billings.map((a) => ({
        customerType: a.customerType,
        energyBilled: '',
        customerCount: '',
      }));
    }
    return [];
  };

  const sumTotalBill = (billings) => {
    if (billings.length === 0) return 0;
    return billings.reduce((acc, curr) => {
      if (typeof curr.energyBilled !== 'number') return acc;
      return acc + curr.energyBilled;
    }, 0);
  };

  const sumTotalCustomer = (billings) => {
    if (billings.length === 0) return 0;
    return billings.reduce((acc, curr) => {
      if (typeof curr.customerCount !== 'number') return acc;
      return acc + curr.customerCount;
    }, 0);
  };

  const handleError = (error) => {
    if (error.response) {
      setError(error.response.data.msg);
    } else if (error.request) {
      setError('Please check your internet connection');
    } else {
      setError(error.message);
    }
  };

  const resetErrors = (err) => {
    setHasError(false);
    setError('');
  };

  return (
    <Box sx={{ width: '100%', '&>div': { width: '100%' } }}>
      <MuiButton
        text='Back'
        color='success'
        size='small'
        type='button'
        variant='outlined'
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
      />
      <PageHeader
        icon={<BuildIcon />}
        title={
          mode === 'add'
            ? 'Add Customer Billing Data Form'
            : 'Edit Customer Billing Data Form'
        }
        subTitle={`Form for ${mode}ing customer billing data.`}
      />
      <Formik
        initialValues={id ? getBillingInfoToEdit() : initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          if (mode === 'add') {
            await submitCustomerBilling(values, {
              onError: (error) => {
                setHasError(true);
                handleError(error);
              },
              onSuccess: () => {
                resetForm();
                setIsSuccessful(true);
              },
            });
          } else if (mode === 'edit') {
            await editCustomerBilling(values, {
              onError: (error) => {
                setHasError(true);
                handleError(error);
              },
              onSuccess: () => {
                resetForm();
                setIsSuccessful(true);
              },
            });
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          isValid,
          dirty,
          handleReset,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Paper
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 2,
                  gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                }}
              >
                <Selector
                  options={periods}
                  name='period'
                  label='Period'
                  onChange={handleChange}
                  value={values.period}
                  onBlur={handleBlur}
                  error={touched.period && Boolean(errors.period)}
                  helperText={
                    touched.period && errors.period ? errors.period : ''
                  }
                />
                <Autocomplete
                  options={getPaymentTypes(customerBillingsQuery)}
                  value={values.paymentType}
                  onOpen={handleBlur}
                  freeSolo={true}
                  onInputChange={(e, value) => {
                    setFieldValue('paymentType', value || '', true);
                  }}
                  onChange={(e, value, reason) => {
                    handleChange(e);

                    const customerTypes = getBillingsByPaymentTypeForAdding(
                      customerBillingsQuery,
                      value || ''
                    );
                    if (!customerTypes) return;
                    setFieldValue('billings', customerTypes, true);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='contact'
                      variant='outlined'
                      type='text'
                      label='Name'
                      onBlur={handleBlur}
                      error={touched.paymentType && Boolean(errors.paymentType)}
                      helperText={
                        touched.paymentType && errors.paymentType
                          ? errors.paymentType
                          : ''
                      }
                    />
                  )}
                />
                <DependentInput
                  label='Total Bill'
                  value={values.totalBill}
                  type='number'
                  onChange={handleChange}
                  name='totalBill'
                  onBlur={handleBlur}
                  error={touched.totalBill && Boolean(errors.totalBill)}
                  helperText={
                    touched.totalBill && errors.totalBill
                      ? errors.totalBill
                      : ''
                  }
                  adornmentText=''
                  action={sumTotalBill}
                />
                <DependentInput
                  label='Total Customer'
                  value={values.totalCustomerCount}
                  type='number'
                  onChange={handleChange}
                  name='totalCustomerCount'
                  onBlur={handleBlur}
                  error={
                    touched.totalCustomerCount &&
                    Boolean(errors.totalCustomerCount)
                  }
                  helperText={
                    touched.totalCustomerCount && errors.totalCustomerCount
                      ? errors.totalCustomerCount
                      : ''
                  }
                  adornmentText=''
                  action={sumTotalCustomer}
                />
              </Box>
            </Paper>
            <Box>
              <Box>
                <FieldArray
                  name='billings'
                  render={(arrayHelpers) => (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <h4>Billings</h4>
                        <Button
                          sx={{ height: '40px' }}
                          onClick={() => {
                            arrayHelpers.push({
                              customerType: '',
                              energyBilled: '',
                              customerCount: '',
                            });
                          }}
                        >
                          Add Billing Info
                        </Button>
                      </Box>
                      {values.billings.map((billing, index) => (
                        <Paper
                          key={index}
                          sx={{
                            mb: 2,
                            display: 'grid',
                            gridTemplateColumns: {
                              xs: '1fr',
                              md: '1fr 1fr 1fr 1fr',
                            },
                            columnGap: 2,
                            rowGap: 2,
                            p: 2,
                          }}
                        >
                          {!isMedium ? (
                            <Typography variant='h5'>
                              {billing.customerType}
                            </Typography>
                          ) : null}
                          <TextField
                            name={`billings.${index}.customerType`}
                            variant='outlined'
                            value={billing.customerType}
                            type='text'
                            label='Customer Type'
                            onBlur={handleBlur}
                            error={
                              touched.billings &&
                              touched.billings[index]?.customerType &&
                              Boolean(
                                errors.billings &&
                                  errors.billings[index]?.customerType
                              )
                            }
                            helperText={
                              touched.billings &&
                              touched.billings[index]?.customerType &&
                              errors.billings &&
                              errors.billings[index]?.customerType
                                ? errors.billings[index]?.customerType
                                : ''
                            }
                            onChange={handleChange}
                            fullWidth
                          />
                          <TextField
                            name={`billings.${index}.energyBilled`}
                            variant='outlined'
                            type='number'
                            value={billing.energyBilled}
                            label='energyBilled'
                            onBlur={handleBlur}
                            error={
                              touched.billings &&
                              touched.billings[index]?.energyBilled &&
                              Boolean(
                                errors.billings &&
                                  errors.billings[index]?.energyBilled
                              )
                            }
                            helperText={
                              touched.billings &&
                              touched.billings[index]?.energyBilled &&
                              errors.billings &&
                              errors.billings[index]?.energyBilled
                                ? errors.billings[index]?.energyBilled
                                : ''
                            }
                            onChange={handleChange}
                          />
                          <TextField
                            name={`billings.${index}.customerCount`}
                            variant='outlined'
                            type='number'
                            value={billing.customerCount}
                            label='Customer Count'
                            onBlur={handleBlur}
                            error={
                              touched.billings &&
                              touched.billings[index]?.customerCount &&
                              Boolean(
                                errors.billings &&
                                  errors.billings[index]?.customerCount
                              )
                            }
                            helperText={
                              touched.billings &&
                              touched.billings[index]?.customerCount &&
                              errors.billings &&
                              errors.billings[index]?.customerCount
                                ? errors.billings[index]?.customerCount
                                : ''
                            }
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          <IconButton
                            sx={{ width: '60px', height: '60px', ml: 3 }}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Paper>
                      ))}
                    </Box>
                  )}
                />

                <Box>
                  <LoadingButton
                    sx={{ ml: 1, my: 2, textTransform: 'none' }}
                    disabled={isSubmitting || !isValid || !dirty}
                    loading={isSubmitting}
                    variant='contained'
                    size='large'
                    type='submit'
                  >
                    Submit
                  </LoadingButton>
                  <Button
                    sx={{ ml: 1, my: 2, textTransform: 'none' }}
                    disabled={isSubmitting}
                    onClick={handleReset}
                    size='large'
                    color='secondary'
                    variant='contained'
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <SuccessToastNotification
        isOpen={isSuccessful}
        setIsSuccessful={setIsSuccessful}
        message='Form successfully saved!'
      />
      <ErrorToastNotification
        isOpen={hasError}
        setHasError={resetErrors}
        message={error}
      />
    </Box>
  );
};

export default AddEditCustomerBilling;
