import React, { useState } from "react";
import { useFinancialKpiContext } from "../../contexts/financialKpiContext";
import useTable from "../../hooks/useTable";
import TblPagination from "../controls/tables/TblPagination";
import TblHead from "../controls/tables/TblHead";
import TblContainer from "../controls/tables/TblContainer";
import { TableBody } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../controls/popup";
import { useAuthContext } from "../../contexts/authContext";
import SimplePopupContent from "../controls/popup/SimplePopupContent";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Box } from "@mui/system";
import MuiButton from "../controls/buttons/MuiButton";
import FinancialCollapsibleRow from "./FinancialCollapsedRow";
import SuccessToastNotification from "../controls/SuccessToastNotification";
import ErrorToastNotification from "../controls/ErrorToastNotification";
import Icons from "../../icons";
import PageHeader from "../../components/pageHeader";
import { financialDataTable } from "../pageHeader/pageTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { financialHeadCell as headCells } from "../controls/tables/headCell";
import Loading from "../controls/Loading";

export default function FinancialCollapsibleTable() {
  const history = useHistory();
  const [hasError, setHasError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState("");  
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [openDeleteDataPopup, setOpenDeleteDataPopup] = useState(false);
  const { financialDataQuery, deleteFinancialData } =
    useFinancialKpiContext();
  const { currentUser } = useAuthContext();
  const {
    order,
    orderBy,
    rowsPerPage,
    page,
    rowsPerPageOptions,
    count,
    setOrder,
    setOrderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    recordsAfterPagingAndSorting,
  } = useTable({ record: financialDataQuery });

  const { path } = useRouteMatch();

  const navigateToForm = () => {
    history.push(`${path}/form`);
  };

  const yesToDelete = async () => {
    setOpenDeleteDataPopup(false);
    await deleteFinancialData(itemToDeleteId, {
      onError: (err) => {
        setHasError(true);
        handleError(error);
        setItemToDeleteId(null);
      },
      onSuccess: () => {
        setItemToDeleteId(null);
        setIsSuccessful(true);
      },
    });
  };

  const noToDelete = () => {
    setOpenDeleteDataPopup(false);
    //setDataToDelete(null);
  };
  const prompToDeleteData = (id) => {
    if (!id) return;
    setItemToDeleteId(id);
    setOpenDeleteDataPopup(true);
  };

  const editData = (id) => {
    history.push(`${path}/form/${id}`);
  };
  const goBack = () => {
    history.goBack();
  };
  const handleError = (error) => {
    if (error.response) {
      setError(error.response.data.msg);
    } else if (error.request) {
      setError("Please check your internet connection");
    } else {
      setError(error.message);
    }
  };

  return (
    <Box>
      <MuiButton
        text="Back"
        color="primary"
        size="small"
        type="button"
        variant="outlined"
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
      />
      <PageHeader
        icon={Icons.dataTable}
        title={financialDataTable.title}
        subTitle={financialDataTable.subtitle}
      />
      {financialDataQuery.status === "loading" ? (
        <Loading />
      ) : (
        <Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: -1,
                bgcolor: "#fff",
                borderRadius: 2,
                p: 1,
                "& .MuiBox-root": {
                  margin: 1,
                  fontWeight: "medium",
                  fontSize: 20,
                },
                "& .MuiButton-outlined": {
                  padding: "12px 12px 12px 0",
                  m: 1,
                },
              }}
            >
              <Box>Financial Data</Box>
              {currentUser && currentUser.role === "Admin" && (
                <MuiButton
                  text="Add New"
                  variant="outlined"
                  size="large"
                  color="primary"
                  type="button"
                  minWidth="150px"
                  onClick={navigateToForm}
                  startIcon={<AddIcon />}
                />
              )}
            </Box>
            <TblContainer>
              <TblHead
                headCells={headCells}
                orderBy={orderBy}
                order={order}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
              />
              <TableBody>
                {recordsAfterPagingAndSorting().map((item) => (
                  <FinancialCollapsibleRow
                    row={item}
                    key={item._id}
                    editData={editData}
                    prompToDeleteData={prompToDeleteData}
                    currentUser={currentUser}
                  />
                ))}
              </TableBody>
            </TblContainer>
            <TblPagination
              rowsPerPageOptions={rowsPerPageOptions}
              rowsPerPage={rowsPerPage}
              page={page}
              count={count}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      )}
      <Popup
        title="Delete Generation Data?"
        openPopup={openDeleteDataPopup}
        setOpenPopup={setOpenDeleteDataPopup}
      >
        <SimplePopupContent
          yesOnClick={yesToDelete}
          noOnClick={noToDelete}
          message={`Are you sure you want to delete  data for ?`}
        />
      </Popup>
      <SuccessToastNotification
        isOpen={isSuccessful}
        setIsSuccessful={setIsSuccessful}
        message="Item deleted successfully"
      />
      <ErrorToastNotification
        isOpen={hasError}
        setHasError={setHasError}
        message={error}
      />
    </Box>
  );
}
