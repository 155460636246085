import Box from '@mui/system/Box';
import BuildIcon from '@mui/icons-material/Build';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTransmissionKpiContext } from '../../contexts/transmissionKpiContext';
import PrePopulatedWithAdornmentInput from '../controls/formControls/PrePopulatedWithAdornment';
import InputWithAdornment from '../controls/formControls/InputWithAdornment';
import PageHeader from '../pageHeader';
import MuiButton from '../controls/buttons/MuiButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Selector from '../controls/Selector';
import { getPeriods } from '../../services/util';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import SuccessToastNotification from '../controls/SuccessToastNotification';
import ErrorToastNotification from '../controls/ErrorToastNotification';

const validationSchema = yup.object({
  period: yup.string('Enter a valid period').required('period is required'),
  energyDispatched: yup
    .number('Enter a valid energy dispatched')
    .required('energy dispatched is required'),
  energyDelivered: yup
    .number('Enter a valid energy delivered')
    .required('energy delivered is required'),
  totalCircuit: yup
    .number('Enter a valid total circuit')
    .required('total circuit is required'),
  totalLengthOfCircuit: yup
    .number('Enter a valid total length of circuit')
    .required('total length of circuit is required'),
  averageDemand: yup
    .number('Enter a valid average demand')
    .required('average demand is required'),
  peakDemand: yup
    .number('Enter a valid peak demand')
    .required('peak demand is required'),
  systemCapacity: yup
    .number('Enter a valid system capacity')
    .required('system capacity is required'),
  contractedEnergy: yup
    .number('Enter a valid contracted energy')
    .required('contracted energy is required'),
  energyImported: yup
    .number('Enter a valid energy imported')
    .required('energy imported is required'),
  totalOperationHours: yup
    .number('Enter a valid total operation hours')
    .required('total operation hours is required'),
});

const periods = getPeriods();
const initialValues = {
  period: '',
  energyDispatched: '',
  energyDelivered: '',
  totalCircuit: '',
  totalLengthOfCircuit: '',
  averageDemand: '',
  peakDemand: '',
  systemCapacity: '',
  contractedEnergy: '',
  energyImported: '',
  totalOperationHours: '',
  frequency: '50±0.5Hz',
};

const AddEditTransmissionKpi = () => {
  const history = useHistory();
  const { id } = useParams();
  const [hasError, setHasError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [mode, setMode] = useState('');
  const [error, setError] = useState('');

  const {
    transmissionDataQuery,
    submitTransmissionData,
    editTransmissionData,
  } = useTransmissionKpiContext();

  useEffect(() => {
    if (id) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [id]);

  const goBack = () => {
    history.goBack();
  };
  const getDataToBeEdited = () => {
    const { data } = transmissionDataQuery;
    if (data) {
      return data.find((d) => d._id === id);
    } else {
      return initialValues;
    }
  };

  const getSystemCapacity = () => {
    const { status, data } = transmissionDataQuery;
    if (status === 'success') {
      const transmissionKpi = data[0];
      if (!transmissionKpi) return;
      const { systemCapacity } = transmissionKpi;
      return systemCapacity;
    }
  };

  const handleError = (error) => {
    if (error.response) {
      setError(error.response.data.msg);
    } else if (error.request) {
      setError('Please check your internet connection');
    } else {
      setError(error.message);
    }
  };

  return (
    <Box>
      <MuiButton
        text='Back'
        color='success'
        size='small'
        type='button'
        variant='outlined'
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
      />
      <PageHeader
        icon={<BuildIcon />}
        title={
          mode === 'add'
            ? 'Add Transmission Data Form'
            : 'Edit Transmission Data Form'
        }
        subTitle={`Form for ${mode}ing transmission data.`}
      />
      <Formik
        initialValues={id ? getDataToBeEdited() : initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          if (mode === 'add') {
            await submitTransmissionData(values, {
              onError: (error) => {
                setHasError(true);
                handleError(error);
              },
              onSuccess: () => {
                resetForm();
                setIsSuccessful(true);
              },
            });
          } else if (mode === 'edit') {
            await editTransmissionData(values, {
              onError: (error) => {
                setHasError(true);
                handleError(error);
              },
              onSuccess: () => {
                resetForm();
                setIsSuccessful(true);
              },
            });
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          handleReset,
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                bgcolor: 'background.paper',
                p: 2,
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                  columnGap: 2,
                  rowGap: 2,
                  width: '100%',
                }}
              >
                <Selector
                  options={periods}
                  name='period'
                  label='Period'
                  onChange={handleChange}
                  value={values.period}
                  onBlur={handleBlur}
                  error={touched.period && Boolean(errors.period)}
                  helperText={
                    touched.period && errors.period ? errors.period : ''
                  }
                />
                <InputWithAdornment
                  label='Energy Dispatched'
                  value={values.energyDispatched}
                  type='number'
                  onChange={handleChange}
                  name='energyDispatched'
                  onBlur={handleBlur}
                  error={
                    touched.energyDispatched && Boolean(errors.energyDispatched)
                  }
                  helperText={
                    touched.energyDispatched && errors.energyDispatched
                      ? errors.energyDispatched
                      : ''
                  }
                  adornmentText='MWh'
                />
                <InputWithAdornment
                  label=' Energy Delivered'
                  value={values.energyDelivered}
                  type='number'
                  onChange={handleChange}
                  name='energyDelivered'
                  onBlur={handleBlur}
                  error={
                    touched.energyDelivered && Boolean(errors.energyDelivered)
                  }
                  helperText={
                    touched.energyDelivered && errors.energyDelivered
                      ? errors.energyDelivered
                      : ''
                  }
                  adornmentText='MWh'
                />
                <InputWithAdornment
                  label='Total Circuit'
                  value={values.totalCircuit}
                  type='number'
                  onChange={handleChange}
                  name='totalCircuit'
                  onBlur={handleBlur}
                  error={touched.totalCircuit && Boolean(errors.totalCircuit)}
                  helperText={
                    touched.totalCircuit && errors.totalCircuit
                      ? errors.totalCircuit
                      : ''
                  }
                  adornmentText=''
                />
                <InputWithAdornment
                  label='Total Length Of Circuit'
                  value={values.totalLengthOfCircuit}
                  type='number'
                  onChange={handleChange}
                  name='totalLengthOfCircuit'
                  onBlur={handleBlur}
                  error={
                    touched.totalLengthOfCircuit &&
                    Boolean(errors.totalLengthOfCircuit)
                  }
                  helperText={
                    touched.totalLengthOfCircuit && errors.totalLengthOfCircuit
                      ? errors.totalLengthOfCircuit
                      : ''
                  }
                  adornmentText='Km'
                />
                <InputWithAdornment
                  label='Average Demand'
                  value={values.averageDemand}
                  type='number'
                  onChange={handleChange}
                  name='averageDemand'
                  onBlur={handleBlur}
                  error={touched.averageDemand && Boolean(errors.averageDemand)}
                  helperText={
                    touched.averageDemand && errors.averageDemand
                      ? errors.averageDemand
                      : ''
                  }
                  adornmentText='MW'
                />
                <InputWithAdornment
                  label='Peak Demand'
                  value={values.peakDemand}
                  type='number'
                  onChange={handleChange}
                  name='peakDemand'
                  onBlur={handleBlur}
                  error={touched.peakDemand && Boolean(errors.peakDemand)}
                  helperText={
                    touched.peakDemand && errors.peakDemand
                      ? errors.peakDemand
                      : ''
                  }
                  adornmentText='MW'
                />
                <PrePopulatedWithAdornmentInput
                  label='System Capacity'
                  value={values.systemCapacity}
                  prePopulate={mode === 'add'}
                  type='number'
                  onChange={handleChange}
                  name='systemCapacity'
                  onBlur={handleBlur}
                  getValue={getSystemCapacity}
                  error={
                    touched.systemCapacity && Boolean(errors.systemCapacity)
                  }
                  helperText={
                    touched.systemCapacity && errors.systemCapacity
                      ? errors.systemCapacity
                      : ''
                  }
                  adornmentText='MW'
                />
                <InputWithAdornment
                  label='Contracted Energy'
                  value={values.contractedEnergy}
                  type='number'
                  onChange={handleChange}
                  name='contractedEnergy'
                  onBlur={handleBlur}
                  error={
                    touched.contractedEnergy && Boolean(errors.contractedEnergy)
                  }
                  helperText={
                    touched.contractedEnergy && errors.contractedEnergy
                      ? errors.contractedEnergy
                      : ''
                  }
                  adornmentText='MWh'
                />
                <InputWithAdornment
                  label='Energy Imported'
                  value={values.energyImported}
                  type='number'
                  onChange={handleChange}
                  name='energyImported'
                  onBlur={handleBlur}
                  error={
                    touched.energyImported && Boolean(errors.energyImported)
                  }
                  helperText={
                    touched.energyImported && errors.energyImported
                      ? errors.energyImported
                      : ''
                  }
                  adornmentText='MWh'
                />
                <InputWithAdornment
                  label='Total Operation Hours'
                  value={values.totalOperationHours}
                  type='number'
                  onChange={handleChange}
                  name='totalOperationHours'
                  onBlur={handleBlur}
                  error={
                    touched.totalOperationHours &&
                    Boolean(errors.totalOperationHours)
                  }
                  helperText={
                    touched.totalOperationHours && errors.totalOperationHours
                      ? errors.totalOperationHours
                      : ''
                  }
                  adornmentText='hrs'
                />

                <Box>
                  <LoadingButton
                    sx={{ ml: 1, my: 2, textTransform: 'none' }}
                    disabled={isSubmitting || !(isValid && dirty)}
                    loading={isSubmitting}
                    variant='contained'
                    size='large'
                    type='submit'
                  >
                    Submit
                  </LoadingButton>
                  <Button
                    sx={{ ml: 1, my: 2, textTransform: 'none' }}
                    disabled={isSubmitting}
                    onClick={handleReset}
                    size='large'
                    color='secondary'
                    variant='contained'
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <SuccessToastNotification
        isOpen={isSuccessful}
        setIsSuccessful={setIsSuccessful}
        message='Form successfully saved!'
      />
      <ErrorToastNotification
        isOpen={hasError}
        setHasError={setHasError}
        message={error}
      />
    </Box>
  );
};

export default AddEditTransmissionKpi;
