import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormikContext } from 'formik';

const PrePopulatedWithAdornmentInput = ({
  getValue,
  prePopulate = true,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (getValue && prePopulate) {
      setFieldValue(props.name, getValue());
    }
  }, [getValue, props.name, setFieldValue, prePopulate]);
  const {
    name,
    label,
    value,
    onChange,
    adornmentText,
    type = 'text',
    ...others
  } = props;
  return (
    <TextField
      name={name}
      placeholder={label}
      label={label}
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment sx={{ color: 'blue' }} position='end'>
            {adornmentText}
          </InputAdornment>
        ),
      }}
      type={type}
      variant='outlined'
      {...others}
    />
  );
};

export default PrePopulatedWithAdornmentInput;
