import React from "react";
import Box from "@mui/system/Box";
import { useHistory } from "react-router";
import PageHeader from "../pageHeader";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MuiButton from "../controls/buttons/MuiButton";
import { useAuthContext } from "../../contexts/authContext";
import Divider from "@mui/material/Divider"
import { Chip } from "@mui/material";

const UserProfile = () => {
  const { currentUser } = useAuthContext();
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  const changePassword = () => {
    history.push("/lec/users/password")
  };

  return (
    <Box>
      <MuiButton
        text="Back"
        color="success"
        size="small"
        type="button"
        variant="outlined"
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
      />
      <PageHeader
        title="User Profile"
        subTitle="Displays user information"
        icon={<AccountCircle fontSize="large" />}
      />
      <Box sx={{ display: "grid", placeItems: "center" }}>
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            bgcolor: "background.paper",
            p: 2,
            width: { xs: "320px", sm: "500px" },
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: "grid", placeItems: "center" }}>
            <AccountCircle
              fontSize="large"
              sx={{ width: "120px", height: "120px", color: "primary.main" }}
            />
            <Box sx={{ fontSize: "22px", fontWeight: "medium" }}>
              {currentUser.fullName}
            </Box>
            <Box >{currentUser.email}</Box>
            <Box>{currentUser.phoneNumber}</Box>
            <Box>{`Role:  ${currentUser.role}`}</Box>
            <Divider variant="middle" sx={{width:{xs: "280px", sm: "460px"}, my:4}}>
                <Chip  label="Change Password" onClick={changePassword}/>
            </Divider>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfile;
