import Icons from '../../icons';
import Dashboard from '../dashboard';
import Users from '../users';
import FinancialKPI from '../financialKPIs';
import GenerationKpi from '../generationKPIs';
import TransmissionKPI from '../transmissionKPIs';
import CustomerBilling from '../customer-billing';
import DepartmentWageKPI from '../department-wage';

export const Routes = [
  {
    path: '/lec/dashboard',
    sidebarName: 'Dashboard',
    sidebarIcon: Icons.dashboard,
    component: Dashboard,
  },
  {
    path: '/lec/financial-kpis',
    sidebarName: 'Financial KPIs',
    sidebarIcon: Icons.financialKpi,
    component: FinancialKPI,
  },
  {
    path: '/lec/generation-kpis',
    sidebarName: 'Generation KPIs',
    sidebarIcon: Icons.generationKpi,
    component: GenerationKpi,
  },
  {
    path: '/lec/transmission-kpis',
    sidebarName: 'Transmission KPIs',
    sidebarIcon: Icons.transmissionKpi,
    component: TransmissionKPI,
  },
  {
    path: '/lec/customer-billings',
    sidebarName: 'Customer Billing',
    sidebarIcon: Icons.customerBilling,
    component: CustomerBilling,
  },
  {
    path: '/lec/department-wages',
    sidebarName: 'Department Wage',
    sidebarIcon: Icons.departmentWage,
    component: DepartmentWageKPI,
  },
  {
    path: '/lec/users',
    sidebarName: 'Users',
    sidebarIcon: Icons.user,
    component: Users,
  },
];
