import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditDeleteMenu from '../controls/EditDeleteMenu';

function FinancialCollapsibleRow({
  row,
  editData,
  prompToDeleteData,
  currentUser,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(!open);
  return (
    <React.Fragment>
      <TableRow
        sx={
          row.accounts.length !== 0
            ? {
                '& > *': { borderBottom: 'unset' },
              }
            : {}
        }
        onClick={handleClick}
      >
        <TableCell>
          {row.accounts.length !== 0 && (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={handleClick}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>

        <TableCell align='left'>{row.name}</TableCell>
        <TableCell align='left'>{row.period}</TableCell>
        <TableCell align='right'>{'$' + row.total.toFixed(2)}</TableCell>
        {currentUser && currentUser.role === 'Admin' && (
          <TableCell align='right'>
            <EditDeleteMenu
              onEditClick={() => editData(row._id)}
              onDeleteClick={() => prompToDeleteData(row._id)}
            />
          </TableCell>
        )}
      </TableRow>
      {row.accounts.length !== 0 && (
        <TableRow sx={{ backgroundColor: '#efefef' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box>
                <Typography variant='h6' gutterBottom component='div'>
                  Sub Accounts
                </Typography>
                <Table size='small' aria-label='purchases'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>Name</TableCell>
                      <TableCell align='right'>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.accounts.map((bill, index) => (
                      <TableRow key={`${bill.name}~${index}`}>
                        <TableCell align='left'>{bill.name}</TableCell>
                        <TableCell align='right'>{`$${bill.value.toFixed(
                          2
                        )}`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default FinancialCollapsibleRow;
