import React, { useState } from 'react';
import useTable from '../controls/tables/UseTable';
import { useUsersContext } from '../../contexts/UsersContext';
import { TableBody, TableCell, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { userHeadCells as headCells } from '../controls/tables/headCell';
import { Box } from '@mui/system';
import MuiButton from '../controls/buttons/MuiButton';
import Popup from '../controls/popup';
import SimplePopupContent from '../controls/popup/SimplePopupContent';
import EditDeleteMenu from '../controls/EditDeleteMenu';
import Icons from '../../icons';
import PageHeader from '../../components/pageHeader';
import { user } from '../pageHeader/pageTitle';
import Loading from '../controls/Loading';

const UsersTable = ({ location }) => {
  const history = useHistory();

  const [openDeleteUserPopup, setOpenDeleteUserPopup] = useState(false);
  const {
    usersQuery,
    deleteUser,
    userToDelete,
    setUserToDelete,
    setUserToEdit,
  } = useUsersContext();
  const { pathname } = location;
  const { TblContent, TblHead, recordsAfterPagingAndSorting, TblPagination } =
    useTable({ headCells, record: usersQuery });

  const editUser = (item) => {
    setUserToEdit(item);
    history.push(`${pathname}/form/edit`);
  };
  const navigateToForm = () => {
    history.push(`${pathname}/form`);
  };
  const yesToDelete = async () => {
    await deleteUser(userToDelete._id, {
      onSuccess: () => {
        setOpenDeleteUserPopup(false);
      },
    });
  };

  const noToDelete = () => {
    setOpenDeleteUserPopup(false);
    setUserToDelete(null);
  };
  const prompToDeleteUser = (item) => {
    if (!item) return;
    setUserToDelete(item);
    setOpenDeleteUserPopup(true);
  };
  return (
    <Box sx={{ width: 1 }}>
      <PageHeader
        icon={Icons.user}
        title={user.title}
        subTitle={user.subtitle}
      />
      {usersQuery.status === 'loading' ? (
        <Loading />
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: -1,
              bgcolor: '#fff',
              borderRadius: 2,
              p: 1,
              '& .MuiBox-root': {
                margin: 1,
                fontWeight: 'medium',
                fontSize: 20,
              },
              '& .MuiButton-outlined': {
                padding: '12px 12px 12px 0',
                m: 1,
              },
            }}
          >
            <Box>Users List</Box>
            <MuiButton
              text='Add New'
              variant='outlined'
              size='large'
              color='primary'
              type='button'
              minWidth='150px'
              onClick={navigateToForm}
              startIcon={<AddIcon />}
            />
          </Box>
          <TblContent>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item._id}>
                  <TableCell align='left'>{item.fullName}</TableCell>
                  <TableCell align='left'>{item.email}</TableCell>
                  <TableCell align='left'>{item.phoneNumber}</TableCell>
                  <TableCell align='left'>{item.role}</TableCell>
                  <TableCell align='left'>{item.createdAt}</TableCell>
                  <TableCell align='right'>
                    <EditDeleteMenu
                      onEditClick={() => editUser(item)}
                      onDeleteClick={() => prompToDeleteUser(item)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContent>
          <TblPagination />
        </Box>
      )}
      <Popup
        title='Delete User?'
        openPopup={openDeleteUserPopup}
        setOpenPopup={setOpenDeleteUserPopup}
      >
        <SimplePopupContent
          yesOnClick={yesToDelete}
          noOnClick={noToDelete}
          message={`Are you sure you want to delete ${userToDelete?.fullName}?`}
        />
      </Popup>
    </Box>
  );
};

export default UsersTable;
