import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { appUserRoles } from '../../services/util';
import PrivateRoute from '../routes/PrivateRoute';
import UsersContextProvider from '../../contexts/UsersContext';
import UserEditForm from './UserEditForm';
import UserForm from './UserForm';
import UsersTable from './UsersTable';

const Users = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <UsersContextProvider>
        <PrivateRoute
          roles={appUserRoles}
          path={path}
          exact
          component={UsersTable}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/form/edit`}
          exact
          component={UserEditForm}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/form`}
          exact
          component={UserForm}
        />
      </UsersContextProvider>
    </Switch>
  );
};

export default Users;
