import api from './Api';
import { getToken } from './authService';

export const getSystemData = async () => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  return await api(options).get('/technical-kpis');
};
export const getLastestSystemData = async () => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get('/technical-kpis/system-data/latest');
  return result.data;
};
export const getLastestSystemKpi = async () => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get(
    '/technical-kpis/system-data/kpis/latest'
  );
  return result.data;
};
export const getQuarters = async () => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get('/technical-kpis/all-quarters/names');
  return result.data;
};
export const getSystemKpis = async () => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get('/technical-kpis/all/kpis');
  return result.data;
};

export const getSubstationsData = async () => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get('/substation-kpis');
  return result.data;
};

export const getSubstionsKpis = async () => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get('/substation-kpis/substations/all');
  return result.data;
};

export const getSystemQuarterlyData = async (quarter) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get(`/technical-kpis/quarters/${quarter}`);
  return result.data;
};
export const getSystemQuarterlyKPI = async (quarter) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get(`/technical-kpis/${quarter}/all`);
  return result.data;
};
export const getSubstationNames = async () => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get('/substation-kpis/substations/names');
  return result.data;
};
export const getSubstationsByQuarter = async (quarter) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get(
    `substation-kpis/substations/${quarter}/all`
  );
  return result.data;
};
export const createSystemData = async (data) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).post('/technical-kpis', data);
  return result.data;
};
export const createSubstationData = async (data) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).post('/substation-kpis', data);
  return result.data;
};
export const updateSystemData = async (systemDataId, data) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).put(
    `/technical-kpis/edit/${systemDataId}`,
    data
  );
  return result.data;
};
export const updateSubstationData = async (id, data) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).put(`/substation-kpis/edit/${id}`, data);
  return result.data;
};

export const deleteSystemData = async (systemDataId) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).delete(
    `/technical-kpis/delete/${systemDataId}`
  );
  return result.data;
};
export const deleteSubstationData = async (id) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).delete(`/substation-kpis/delete/${id}`);
  return result.data;
};
export const getQuarterlyKpiBySubstionName = async (name, quarter) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get(
    `/substation-kpis/substations/details/${encodeURIComponent(name)}/${quarter}`
  );
  return result.data;
};
export const getKpisBySubstionName = async (name) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get(
    `/substation-kpis/substations/names/${encodeURIComponent(name)}/all`
  );
  return result.data;
};
export const getQuartersBySubstationName = async (name) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get(
    `/substation-kpis/${encodeURIComponent(name)}/all-quarters`
  );
  return result.data;
};
export const getUsers = async () => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get(`/users`);
  return result.data;
};
export const createUser = async (data) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).post(`/users`, data);
  return result.data;
};
export const updateUser = async (id, data) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).put(`/users/edit/${id}`, data);
  return result.data;
};
export const deleteUser = async (userId) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).delete(`/users/delete/${userId}`);
  return result.data;
};
export const changePassword = async (userId, newPassword, oldPassword) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).patch(`/users/change-password/${userId}`, {
    newPassword,
    oldPassword,
  });
  return result.data;
};

export const getAll = async (path) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get(`${path}`);
  return result.data;
};

export const createData = async (path, data) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };

  const result = await api(options).post(`${path}`, data);
  return result.data;
};

export const getPowerPlantNames = async () => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get(`/power-plant-data/unique/names`);
  return result.data;
};

export const getByParams = async (path) => {
    const options = {
      headers: { 'x-api-token': getToken() },
    };
    const result = await api(options).get(`${path}`);
    return result.data;
  };

export const getGenerationDataByPlantName = async (plantName) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).get(`/power-plant-data/unique/names/${encodeURIComponent(plantName)}`);
  return result.data;
};


export const updateData = async (path, data) => {
  const options = {
    headers: { 'x-api-token': getToken() },
  };
  const result = await api(options).put(
    `${path}`,
    data
  );
  return result.data;
};

export const deleteData = async (path) => {
    const options = {
      headers: { 'x-api-token': getToken() },
    };
    const result = await api(options).delete(`${path}`);
    return result.data;
  };