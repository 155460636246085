import React, { useState } from 'react';
import { useTransmissionKpiContext } from '../../contexts/transmissionKpiContext';
import useTable from '../../hooks/useTable';
import TblPagination from '../controls/tables/TblPagination';
import TblHead from '../controls/tables/TblHead';
import TblContainer from '../controls/tables/TblContainer';
import { TableBody, TableCell, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Popup from '../controls/popup';
import { useAuthContext } from '../../contexts/authContext';
import SimplePopupContent from '../controls/popup/SimplePopupContent';
import { useHistory } from 'react-router-dom';
import { transmissionHeadCell as headCells } from '../controls/tables/headCell';
import { Box } from '@mui/system';
import MuiButton from '../controls/buttons/MuiButton';
import { formatFigure } from '../../services/util';

import SuccessToastNotification from '../controls/SuccessToastNotification';
import ErrorToastNotification from '../controls/ErrorToastNotification';
import EditDeleteMenu from '../controls/EditDeleteMenu';
import PageHeader from '../pageHeader';
import Icons from '../../icons';
import { transmissionKpi } from '../pageHeader/pageTitle';
import Loading from '../controls/Loading';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TransmissionKPI = ({ location }) => {
  const history = useHistory();

  const [hasError, setHasError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState('');
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [openDeleteDataPopup, setOpenDeleteDataPopup] = useState(false);
  const {
    transmissionDataQuery,

    deleteTransmissionData,
  } = useTransmissionKpiContext();
  const { pathname } = location;
  const { currentUser } = useAuthContext();
  const {
    order,
    orderBy,
    rowsPerPage,
    page,
    rowsPerPageOptions,
    count,
    setOrder,
    setOrderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    recordsAfterPagingAndSorting,
  } = useTable({ record: transmissionDataQuery });

  const yesToDelete = async () => {
    setOpenDeleteDataPopup(false);
    await deleteTransmissionData(itemToDeleteId, {
      onSuccess: () => {
        setItemToDeleteId(null);
      },
      onError: (error) => {
        setHasError(true);
        handleError(error);

        setItemToDeleteId(null);
      },
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const noToDelete = () => {
    setOpenDeleteDataPopup(false);
  };
  const prompToDeleteData = (id) => {
    if (!id) return;
    setItemToDeleteId(id);
    setOpenDeleteDataPopup(true);
  };
  const addData = () => {
    history.push(`${pathname}/form/`);
  };

  const editData = (id) => {
    history.push(`${pathname}/form/${id}`);
  };

  const handleError = (error) => {
    if (error.response) {
      setError(error.response.data.msg);
    } else if (error.request) {
      setError('Please check your internet connection');
    } else {
      setError(error.message);
    }
  };

  return (
    <Box>
      <MuiButton
        text='Back'
        color='primary'
        size='small'
        type='button'
        variant='outlined'
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
      />
      <PageHeader
        icon={Icons.transmissionKpi}
        title={transmissionKpi.title}
        subTitle={transmissionKpi.subtitle}
      />
      {transmissionDataQuery.status === 'loading' ? (
        <Loading />
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: -1,
              bgcolor: '#fff',
              borderRadius: 2,
              p: 1,
              '& .MuiBox-root': {
                margin: 1,
                fontWeight: 'medium',
                fontSize: 20,
              },
              '& .MuiButton-outlined': {
                padding: '12px 12px 12px 0',
                m: 1,
              },
            }}
          >
            <Box>Transmission Data</Box>
            {currentUser && currentUser.role === 'Admin' && (
              <MuiButton
                text='Add New'
                variant='outlined'
                size='large'
                color='primary'
                type='button'
                minWidth='150px'
                onClick={addData}
                startIcon={<AddIcon />}
              />
            )}
          </Box>
          <TblContainer>
            <TblHead
              headCells={headCells}
              orderBy={orderBy}
              order={order}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item._id}>
                  <TableCell align='left' size='small'>
                    {item.period}
                  </TableCell>
                  <TableCell align='left' size='small'>
                    {item.energyDispatched}
                  </TableCell>

                  <TableCell align='right' size='small'>
                    {item.energyDelivered}
                  </TableCell>
                  <TableCell align='right' size='small'>
                    {formatFigure(item.totalCircuit)}
                  </TableCell>
                  <TableCell align='right' size='small'>
                    {formatFigure(item.totalLengthOfCircuit)}
                  </TableCell>
                  <TableCell align='right' size='small'>
                    {formatFigure(item.averageDemand)}
                  </TableCell>
                  <TableCell align='right' size='small'>
                    {formatFigure(item.peakDemand)}
                  </TableCell>
                  <TableCell align='right' size='small'>
                    {formatFigure(item.contractedEnergy)}
                  </TableCell>
                  <TableCell align='right' size='small'>
                    {formatFigure(item.energyImported)}
                  </TableCell>
                  <TableCell align='right' size='small'>
                    {formatFigure(item.totalOperationHours)}
                  </TableCell>
                  {currentUser && currentUser.role === 'Admin' && (
                    <TableCell align='right'>
                      <EditDeleteMenu
                        onEditClick={() => editData(item._id)}
                        onDeleteClick={() => prompToDeleteData(item._id)}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            page={page}
            count={count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      )}
      <Popup
        title='Delete Generation Data?'
        openPopup={openDeleteDataPopup}
        setOpenPopup={setOpenDeleteDataPopup}
      >
        <SimplePopupContent
          yesOnClick={yesToDelete}
          noOnClick={noToDelete}
          message={`Are you sure you want to delete  data for ?`}
        />
      </Popup>
      <SuccessToastNotification
        isOpen={isSuccessful}
        setIsSuccessful={setIsSuccessful}
        message='Form successfully saved!'
      />
      <ErrorToastNotification
        isOpen={hasError}
        setHasError={setHasError}
        message={error}
      />
    </Box>
  );
};

export default TransmissionKPI;
