import { useEffect, useState } from "react"
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { alpha } from "@mui/system";

import React from 'react'
import { TablePagination, TableSortLabel } from "@mui/material";

const useTable = ({ headCells, record }) => {

    const [data, setData] = useState([]);
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();

    const pages = [25, 10, 5];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pages[page+1]);

    useEffect(() => {
        if ('status' in record && 'data' in record) {
            if (record.status === 'success')
                if ("data" in record.data) {
                    setData(record.data.data);
                }
                else {
                    setData(record.data);
                }
        }
        else {
            setData(record);
        }
    }, [record]);

    const TblContent = (props) => (
        <TableContainer component={Paper}>
            <Table sx={{
                minWidth: 650,
                '& thead th': {
                    fontWeight: '700',
                },
                '& tbody td': {
                    fontWeight: '400',
                },
                '& tbody tr:hover': {
                    bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
                    cursor: 'pointer',
                }
            }}>
                {props.children}
            </Table>
        </TableContainer>
    );

    const TblHead = (props) => {
        const handleSortRequest = (cellId) => {
            const isAsc = orderBy === cellId && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(cellId)
        }
        return (
            <TableHead>
                <TableRow>
                    {
                        headCells.map(headCell => (
                            <TableCell align={headCell.align} size = {headCell.size} key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                                {headCell.disableSorting ? headCell.label :
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'desc'}
                                        onClick={() => { handleSortRequest(headCell.id) }}>
                                        {headCell.label}
                                    </TableSortLabel>
                                }
                            </TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
        )
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const TblPagination = () => (
        <TablePagination component="div"
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            page={page}
            count={data.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />)

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const getComparator = (order, orderBy) => {
        return order === "desc" ?
            (a, b) => descendingComparator(a, b, orderBy) :
            (a, b) => -descendingComparator(a, b, orderBy);
    }

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0;
    }

    const recordsAfterPagingAndSorting = () => {
        return stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    }

    return {
        TblContent,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    }
}

export default useTable
