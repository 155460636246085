import axios from "axios";
import decodeJwt from "jwt-decode";

export const setUserInLocalStorage = (user) =>{
    localStorage.setItem("user-info", JSON.stringify(user));
}
export const login = async (email, password) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/login`
        const result = await axios.post(url, { email, password }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!result.data) return undefined;
        const { token, userData } = result.data;
        const decoded = decodeJwt(token);
        const { user, iat, exp } = decoded;
        const { id, role } = user;
        const currentUser = { token, id, role, iat, exp, ...userData }
        setUserInLocalStorage(currentUser);
        return currentUser;
    } catch (error) {
        throw error;
    }
}

export const getCurrentUser = () => {
    const userStr = localStorage.getItem("user-info");
    if (!userStr) return null;
    const user = JSON.parse(userStr);
    const { exp } = user;
    if (Date.now() > exp * 1000) {
        localStorage.removeItem("user-info");
        return null;
    }
    return user;
}
export const getToken = () => {
    const userStr = localStorage.getItem("user-info");
    if (!userStr) return null;
    const user = JSON.parse(userStr);
    return user.token;
}
export const logout = () => {
    localStorage.removeItem("user-info");
}
