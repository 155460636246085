export const getQuarters = () => {
    const qs = ['Q1', 'Q2', 'Q3', 'Q4'];
    const date = new Date();
    const year = date.getFullYear();
    const quarters = [];
    for (let i = 0; i < 3; i++) {
        const yearValue = year - i;
        for (const quarter of qs) {
            quarters.push(`${quarter} ${yearValue}`);
        }
    }
    return quarters;
};
export const getPeriods = () => {
    const pes = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    const date = new Date();
    const year = date.getFullYear();
    const monthNumber = date.getMonth();
    const periods = [];
    for (let i = 0; i < 3; i++) {
        const yearValue = (year - i) % 100;
        for (const period of pes) {
            if (year % 100 === yearValue && monthNumber >= pes.indexOf(period)) {
                periods.push(`${period}-${yearValue}`);
            } else if (year % 100 !== yearValue) {
                periods.push(`${period}-${yearValue}`);
            }
        }
    }
    return periods;
};

export const getPeriodInfo = (period) => {
    const ps = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];
    if (!period) return;
    if (period.indexOf("-") === -1) return;

    const [monthStr, yearStr] = period.split("-");
    const monthNum = ps.indexOf(monthStr);
    if (monthNum === -1) return ;
    const yearNum = parseInt(yearStr);
    if (isNaN(yearNum)) return;

    const year = 2000 + yearNum;
    const periodStartDate = new Date(year, monthNum, 1);

    const periodEndDate = new Date(year, monthNum + 1, 0, 23, 59, 59, 999);

    const totalHours = (periodEndDate - periodStartDate) / (60 * 60 * 1000);
    return {year, month: monthNum + 1, totalHours};
}


export const getTotalHoursInQuarter = (quarter) => {
    const qArray = quarter.split(' ');
    const qn = parseInt(qArray[0].slice(qArray.length - 1));
    const year = parseInt(qArray[1]);
    let hours = 0;
    let start, end;
    switch (qn) {
        case 1:
            start = new Date(year, 0, 1);
            end = new Date(year, 2, 31, 23, 59, 59);
            hours = (end - start) / (1000 * 60 * 60);
            break;
        case 2:
            start = new Date(year, 3, 1);
            end = new Date(year, 5, 30, 23, 59, 59);
            hours = (end - start) / (1000 * 60 * 60);
            break;

        case 3:
            start = new Date(year, 6, 1);
            end = new Date(year, 8, 31, 23, 59, 59);
            hours = (end - start) / (1000 * 60 * 60);
            break;
        default:
            start = new Date(year, 9, 1);
            end = new Date(year, 11, 31, 23, 59, 59);
            hours = (end - start) / (1000 * 60 * 60);
            break;
    }
    return Math.round(hours);
};
export const getQuarterNumber = (quarter) => {
    if (!quarter) return 0;
    if (quarter.indexOf(' ') === -1) return 0;
    const quarters = quarter.split(' ');

    if (quarters.length < 2) return 0;
    const [q, yearStr] = quarters;
    if (q.indexOf('Q') === -1) return 0;
    if (yearStr.length < 4) return 0;

    const qStr = q.slice(1);
    const quarterStr = yearStr + qStr;
    const quarterNumber = parseInt(quarterStr);

    return quarterNumber;
};

export const getPreviousQuarter = (quarter) => {
    if (!quarter) return undefined;
    const quarterArr = quarter.split(' ');
    if (!quarterArr || quarterArr.length !== 2) return undefined;
    const [q, yearStr] = quarterArr;
    if (!q || !yearStr) return undefined;

    if (q.length !== 2) return undefined;

    const quarterNum = parseInt(q[1]);
    const year = parseInt(yearStr);

    if (quarterNum === 1) return `Q4 ${year - 1}`;
    else return `Q${quarterNum - 1} ${year}`;
};

export const formatFigure = (figure) => {
    if (!figure) return 0;
    return figure.toLocaleString('en-US');
};

export const appUserRoles = ['Admin', 'Manager', 'User', 'Guest'];
export const superUserRoles = ['Admin'];

export const formatFigure2 = (figure) =>{
    if(!figure) return;
    return parseFloat(figure.toFixed(2)).toLocaleString();
}
export const formatFigure3 = (figure, n=2) =>{
    if(!figure) return 0;
    return parseFloat(figure.toFixed(n)).toLocaleString();
}
