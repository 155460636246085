import React, { useContext, createContext, useState, useEffect } from 'react';

import { getPeriodInfo } from '../services/util';
import * as apiServices from '../services/apiServices';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const transmissionKpiContext = createContext();
const rootPath = '/transmission-data';
export const useTransmissionKpiContext = () =>
  useContext(transmissionKpiContext);

const fetchTransmissionData = async ({ queryKey }) => {
  const result = await apiServices.getAll(rootPath);
  return result.data;
};

const deleteData = async (id) => {
  const result = await apiServices.deleteData(`${rootPath}/delete/${id}`);
  return result.data;
};

const updateTransmissionData = async (recordToEdit) => {
  const { _id, period, year, month, periodHours, ...data } = recordToEdit;
  const periodInfo = getPeriodInfo(period);
  if (!periodInfo) throw new Error('Period not in correct format');
  const { year: y, month: m } = periodInfo;
  const dataToEdit = { period, year: y, month: m, ...data };
  await apiServices.updateData(`${rootPath}/edit/${_id}`, dataToEdit);
  return { _id, ...dataToEdit };
};

const createTransmissionData = async (data) => {
  try {
    const { period } = data;
    const periodInfo = getPeriodInfo(period);
    if (!periodInfo) throw new Error('Period not in correct format');
    const { year, month } = periodInfo;
    const transmissionData = { year, month, ...data };
    const result = apiServices.createData(rootPath, transmissionData);
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
const getYearlyTransmissionData = (data, selectedYear) => {
  if (!data || !selectedYear) return;
  const yearlyTransData = {
    energyDispatched: 0,
    energyImported: 0,
    contractedEnergy: 0,
    energyDelivered: 0,
    totalOperationHours: 0,
    systemCapacity: null,
    totalLengthOfCircuit: null,
    totalCircuit: null
  };
  data.filter(d => d.year === selectedYear).forEach((fd, index) => {
    const { systemCapacity, totalLengthOfCircuit, totalCircuit, energyDispatched, energyImported, contractedEnergy, energyDelivered, totalOperationHours } = fd;
    if (index === 0) {
      yearlyTransData.systemCapacity = systemCapacity
      yearlyTransData.totalLengthOfCircuit = totalLengthOfCircuit
      yearlyTransData.totalCircuit = totalCircuit
      yearlyTransData.energyDispatched += energyDispatched;
      yearlyTransData.energyImported += energyImported;
      yearlyTransData.contractedEnergy += contractedEnergy;
      yearlyTransData.energyDelivered += energyDelivered;
      yearlyTransData.totalOperationHours += totalOperationHours;
    }
    else {
      yearlyTransData.energyDispatched += energyDispatched;
      yearlyTransData.energyImported += energyImported;
      yearlyTransData.contractedEnergy += contractedEnergy;
      yearlyTransData.energyDelivered += energyDelivered;
      yearlyTransData.totalOperationHours += totalOperationHours;
    }
  })
  return yearlyTransData;
}

const TransmissionKpiContextProvider = ({ children }) => {
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState([]);
  const [yearlyTransmissionData, setYearlyTransmissionData] = useState();
  const transmissionDataQuery = useQuery(
    'transmission-data',
    fetchTransmissionData
  );

  const queryClient = useQueryClient();
  const { mutateAsync: submitTransmissionData } = useMutation(
    createTransmissionData,
    {
      onSuccess: (data) => {
        queryClient.setQueryData('transmission-data', (transmissionData) => {
          return [...transmissionData, data.data];
        });
      },
    }
  );

  const { mutateAsync: editTransmissionData } = useMutation(
    updateTransmissionData,
    {
      onSuccess: (data) => {
        queryClient.setQueryData('transmission-data', (transmissionData) => {
          if (!data) return;
          const updatedRecords = transmissionData.map((d) => {
            if (d._id === data._id) {
              return data;
            }
            return d;
          });
          return updatedRecords;
        });
      },
    }
  );

  const { mutateAsync: deleteTransmissionData } = useMutation(
    'transmission-data',
    deleteData,
    {
      onSuccess: (data) => {
        queryClient.removeQueries('transmission-data', data._id);
      },
    }
  );

  useEffect(() => {
    const { data } = transmissionDataQuery
    if (data) {
      const yearsFiltered = data.map((d) => d.year).filter((value, index, self) => self.indexOf(value) === index);
      setYears(yearsFiltered);
      setSelectedYear(yearsFiltered[0]);
      const yearlyData = getYearlyTransmissionData(data, yearsFiltered[0]);
      setYearlyTransmissionData(yearlyData);
    }
    //eslint-disable-next-line
  }, [transmissionDataQuery.data])


  const value = {
    transmissionDataQuery,
    submitTransmissionData,
    editTransmissionData,
    deleteTransmissionData,
    selectedYear,
    setSelectedYear,
    years,
    yearlyTransmissionData
  };

  return (
    <transmissionKpiContext.Provider value={value}>
      {children}
    </transmissionKpiContext.Provider>
  );
};

export default TransmissionKpiContextProvider;
