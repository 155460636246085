import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { formatFigure3 } from "../../services/util";

const GenerationPieChart = ({ dataSeries, height = 320 }) => {
  if(!dataSeries) return null;
  const series = dataSeries.data.map(d=>d.y);
  const options = {
    chart: {
      height,
      type: "donut"
    },
    
    labels: dataSeries.data.map(d=>d.x),
    tooltip: {
      theme: "light",
      y: {
        formatter: function (y) {
          if (y !== undefined) return `${formatFigure3(y)} MWh`;
          return y;
        },
      },
    },
    title: {
        text:`${dataSeries.name}`,
        align: 'center',
        style: {
            fontSize: '14px',
            color:"#323232"
        }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 250
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
   
  };
  return (
    <Box sx={{sm:{m:2}}}>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={height}
      />
    </Box>
  );
};

export default GenerationPieChart;
