import React, { useContext, createContext } from 'react';
import * as apiServices from '../services/apiServices';
import { useQuery } from 'react-query';

const reportsContext = createContext();

export const useReportsContext = () => useContext(reportsContext);

const powerPlantDataUrl = '/power-plant-data';
const transmissionDataUrl = "/transmission-data";
const customerBillingDataUrl = "/customer-billing-data";
const departmentWageUrl = "/department-wages";
const financialDataUrl = "/financial-data";
const fetchPowerPlantDataForReport = async ({ queryKey }) => {
    const result = await apiServices.getAll(`${powerPlantDataUrl}/reports/flatten-data`);
    return result.data;
}
const fetchPowerPlantAvailability = async ({ queryKey }) => {
    const result = await apiServices.getAll(`${powerPlantDataUrl}/reports/availability`);
    return result.data;
}
const fetchPeakLoadDataForReport = async ({ queryKey }) => {
    const result = await apiServices.getAll(`${powerPlantDataUrl}/reports/peak-loads`);
    return result.data;
}

const fetchDepartmentWages = async ({ queryKey }) => {

    const result = await apiServices.getAll(`${departmentWageUrl}/reports/flatten-data`);
    return result.data;
}

const fetchFlattenedCustomerBillingData = async ({ queryKey }) => {
    const result = await apiServices.getAll(`${customerBillingDataUrl}/reports/flatten-data`);
    return result.data;
}

const fetchTransmissionDataForReport = async ({ queryKey }) => {
    const result = await apiServices.getAll(`${transmissionDataUrl}/reports/periodic-data`);
    return result.data;
}
//fetch financial data 
const fetchFinancialData = async ({ queryKey }) => {
    const result = await apiServices.getAll(`${financialDataUrl}/reports/flatten-data`);
    return result.data;
}


//create report context provider
const ReportsContextProvider = ({ children }) => {


    //get power plant data with react-query
    const powerPlantReportQuery = useQuery(
        'flattened-power-plant-data', fetchPowerPlantDataForReport,
    );
    //get peak loads with react-query
    const powerPlantPeakLoadQuery = useQuery(
        'plant-peak-loads', fetchPeakLoadDataForReport,
    );

    //get plant availability with react-query
    const plantAvailabilityQuery = useQuery(
        'power-plant-availability', fetchPowerPlantAvailability,
    );

    //get transmission data with react-query
    const transmissionReportQuery = useQuery(
        'transmission-report-data', fetchTransmissionDataForReport,
    );
    //get customer billing data with react-query
    const customerBillingReportQuery = useQuery(
        'customer-billing-report-data', fetchFlattenedCustomerBillingData,
    );

    //get department wages with react-query
    const departmentWagesQuery = useQuery(
        'department-wages-report-data', fetchDepartmentWages,
    );
    //get financial data with react-query
    const financialDataQuery = useQuery(
        'financial-data-report', fetchFinancialData,
    );

    //set value for context provider
    const value = {
        powerPlantReportQuery,
        powerPlantPeakLoadQuery,
        plantAvailabilityQuery,
        transmissionReportQuery,
        customerBillingReportQuery,
        departmentWagesQuery,
        financialDataQuery       
    };
    return (
        <reportsContext.Provider value={value}>
            {children}
        </reportsContext.Provider>
    );
}
export default ReportsContextProvider;