import Select from '@mui/material/Select';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const Selector = ({ value, onChange, options, name, label, width = "150px", defaultValue = "" }) => {
  if (!options) return null;
  return (
    <FormControl variant='outlined' fullWidth >
      <InputLabel id='selector-input'>{label}</InputLabel>
      <Select
        sx = {{width:width}}
        placeholder={label}
        labelId='selector-input'
        id='item-selected'
        label={label}
        defaultValue = {defaultValue}
        name={name}
        onChange={onChange}
        value={value || ''}
      >
        {options?.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Selector;
