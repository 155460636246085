import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import MuiButton from "../controls/buttons/MuiButton";
import PageHeader from "../pageHeader";
import { useHistory } from "react-router-dom";
import { useGenerationKpiContext } from "../../contexts/generationKpiContext";
import Selector from "../controls/Selector";
import Icons from "../../icons";
import DashboardCard from "./DashboardCard";
import CapacityFactorCard from "../controls/CapacityFactorCard";
import Loading from "../controls/Loading";
import SystemAvailabilityCard from "../controls/SystemAvailabilityCard";
import SubPlantDashboardCard from "./SubPlantDashboardCard";
import PlantsLineChart from "../controls/PlantsLineChart";
import { useRouteMatch } from "react-router-dom";
import { Stack } from "@mui/material";

const initialKpi = {
  plantName: null,
  availabilityFactor: null,
  capacityFactor: null,
  forceOutageRate: null,
  scheduleOutageFactor: null,
  forceOutageFactor: null,
  serviceFactor: null,
  fuelConsumptionRate: null,
};
const initialSubPlantData = {
  energyDataSeries: [],
  serviceHoursDataSeries: [],
  totalEnergy: null,
  totalServiceHours: null,
  currentEnergy: null,
  currentServiceHours: null,
  energyPercentage: null,
  serviceHoursPercentage: null,
};
const getEnergyGenerated = (data, plantType) => {
  if (!data) return;
  const operationData = data.filter((d) => d.plantType === plantType);
  const generationData = operationData.map((value) => {
    const { peakLoad, internalConsumption, plants, period } = value;
    let totalEnergy = 0;
    let totalInstalledCap = 0;
    let totalServiceHours = 0;
    plants.forEach((plant) => {
      totalEnergy += plant.energyGenerated;
      totalInstalledCap += plant.installedCapacity;
      totalServiceHours += plant.serviceHours;
    });
    return {
      peakLoad,
      internalConsumption,
      period,
      totalEnergy,
      totalInstalledCap,
      totalServiceHours,
      plants,
    };
  });
  return generationData.reverse();
};

const sumAmount = (data) => {
  if (!data) return;
  const sum = data.reduce((acc, curr) => {
    return acc + curr.y;
  }, 0);

  return sum.toLocaleString();
};

const avgAmount = (data) => {
  if (!data) return;
  const sum = data.reduce((acc, curr) => {
    return acc + curr.y;
  }, 0);
  const avg = sum / data.length;
  return avg.toFixed(1);
};

const getSeriesData = (data, fieldName) => {
  if (!data) return;
  return data.map((value) => {
    return {
      x: value.period,
      y: value[fieldName],
    };
  });
};

const getSubPlantData = (data, selectedPeriod, plantName) => {
  if (!data) return;
  const subPlantData = [];
  let totalEnergy,
    totalServiceHours = 0;
  data.forEach((d) => {
    const { plants, period } = d;
    const plant = plants.find((p) => p.plantName === plantName);
    if (!plant) return;
    subPlantData.push({ ...plant, period });
    if (period === selectedPeriod) {
      totalEnergy = d.totalEnergy;
      totalServiceHours = d.totalServiceHours;
    }
  });
  const energyDataSeries = getSeriesData(subPlantData, "energyGenerated");
  const serviceHoursDataSeries = getSeriesData(subPlantData, "serviceHours");
  const currentData = subPlantData.find((spd) => spd.period === selectedPeriod);
  if (!currentData) return;
  let serviceHoursPercentage,
    energyPercentage = 0;
  if (totalEnergy) {
    energyPercentage = (100 * currentData.energyGenerated) / totalEnergy;
  }
  if (totalServiceHours) {
    serviceHoursPercentage =
      (100 * currentData.serviceHours) / totalServiceHours;
  }
  return {
    energyDataSeries,
    serviceHoursDataSeries,
    totalEnergy,
    totalServiceHours,
    currentEnergy: currentData.energyGenerated,
    currentServiceHours: currentData.serviceHours,
    energyPercentage,
    serviceHoursPercentage,
  };
};

const GenerationDetails = () => {
  const [selectedPlantKpi, setSelectedPlantKpi] = useState(initialKpi);
  const [generationData, setGenerationData] = useState();
  const [plantSeriesData, setPlantSeriesData] = useState({
    energyGeneratedSeries: [],
    serviceHoursSeries: [],
  });
  const [periods, setPeriods] = useState([]);
  const [years, setYears] = useState([]);
  const [plantNames, setPlantNames] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedPlantName, setSelectedPlantName] = useState("");
  const [energyGenerated, setEnergyGenerated] = useState();
  const [serviceHours, setServiceHours] = useState();
  const [internalConsumption, setInternalConsumption] = useState();
  const [peakLoad, setPeakLoad] = useState();
  const [subPlantData, setSubPlantData] = useState(initialSubPlantData);

  const {
    generationDataQuery,
    generationKpis,
    setSelectedPlantType,
    selectedPlantType,
    options,
    generationRecords,
    powerPlantTypesQuery,
  } = useGenerationKpiContext();
  const history = useHistory();
  const { path } = useRouteMatch();
  const navigateToTable = () => {
    history.push(`${path}/table`);
  };

  const setPowerPlantKpi = (kpis, period, plantName) => {
    const plant = kpis.find(
      (gk) => gk.period === period && gk.plantName === plantName
    );
    if (!plant) return;
    setSelectedPlantKpi(plant);
  };
  const getPlantDataSeries = (data) => {
    if (!data) return;
    const energyGeneratedSeries = [];
    const serviceHoursSeries = [];
    data.forEach((d) => {
      const { period, plants } = d;
      plants.forEach((plant) => {
        const { plantName, energyGenerated, serviceHours } = plant;
        const seriesData1 = {
          name: plantName,
          data: [{ x: period, y: energyGenerated }],
        };
        const seriesData2 = {
          name: plantName,
          data: [{ x: period, y: serviceHours }],
        };
        const index1 = energyGeneratedSeries.findIndex(
          (fd) => fd.name === plantName
        );
        const index2 = serviceHoursSeries.findIndex(
          (fd) => fd.name === plantName
        );
        if (index1 === -1) energyGeneratedSeries.push(seriesData1);
        else {
          energyGeneratedSeries[index1].data.push({
            x: period,
            y: energyGenerated,
          });
        }

        if (index2 === -1) serviceHoursSeries.push(seriesData2);
        else {
          serviceHoursSeries[index2].data.push({ x: period, y: serviceHours });
        }
      });
    });
    return { energyGeneratedSeries, serviceHoursSeries };
  };
  const setSubPlantKpi = (data) => {
    const subPlantKpi = getSubPlantData(
      data,
      selectedPeriod,
      selectedPlantName
    );
    if (!subPlantKpi) return;
    setSubPlantData(subPlantKpi);
  };
  const selectYear = (event) => {
    const value = event.target.value;
    setSelectedYear(value);
  };
  const selectPlantType = (event) => {
    const value = event.target.value;
    setSelectedPlantName("");
    setSelectedPlantType(value);
  };
  const selectPeriod = (event) => {
    const value = event.target.value;
    setSelectedPeriod(value);
    setPowerPlantKpi(generationKpis, selectedPeriod, selectedPlantName);
    setSubPlantKpi(generationData);
  };

  const selectPlantName = (event) => {
    const value = event.target.value;
    setSelectedPlantName(value);
    setPowerPlantKpi(generationKpis, selectedPeriod, value);
    setSubPlantKpi(generationData);
  };

  useEffect(() => {
    if (generationRecords) {
      setSelectedPlantType(selectedPlantType);
      const data = getEnergyGenerated(generationRecords, selectedPlantType);
      setGenerationData(data);
      const energyDataSeries = getSeriesData(data, "totalEnergy");
      setEnergyGenerated(energyDataSeries);
      const serviceHoursSeries = getSeriesData(data, "totalServiceHours");
      setServiceHours(serviceHoursSeries);
      const internalConsumptionSeries = getSeriesData(
        data,
        "internalConsumption"
      );
      setInternalConsumption(internalConsumptionSeries);
      const peakLoadSeries = getSeriesData(data, "peakLoad");
      setPeakLoad(peakLoadSeries);
      const seriesData = getPlantDataSeries(data);
      setPlantSeriesData(seriesData);
      if (!options) return;
      setPeriods(options.periods);
      setYears(options.years);
      setPlantNames(options.plantNames);
    }
    // eslint-disable-next-line
  }, [generationRecords, options]);

  useEffect(() => {
    setSelectedPeriod(periods[0]);
    setSelectedYear(years[0]);
    setSelectedPlantName(plantNames[0]);

    // eslint-disable-next-line
  }, [periods, years, plantNames]);

  useEffect(() => {
    setPowerPlantKpi(generationKpis, selectedPeriod, selectedPlantName);
    setSubPlantKpi(generationData);
    // eslint-disable-next-line
  }, [selectedPeriod, selectedPlantName, selectedYear]);

  return (
    <Box sx={{ width: 1 }}>
      <Box>
        {generationDataQuery.status === "loading" ||
        powerPlantTypesQuery.status === "loading" ? null : (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, sm: 2 }}
            justifyContent="space-between"
          >
            <PageHeader
              icon={Icons.generationKpi}
              title={`${selectedPlantType}`}
              subTitle={`Displays operation details on ${selectedPlantType}`}
            />
            <Box>
                <Selector
                  width="230px"
                  name="selectPlantType"
                  label="Select Plant Type"
                  value={selectedPlantType}
                  onChange={selectPlantType}
                  options={powerPlantTypesQuery.data}
                />
            </Box>
            <Box>
                <Selector
                  name="selectedYear"
                  label="Select Year"
                  value={selectedYear}
                  onChange={selectYear}
                  options={years}
                />
            </Box>
            <Box>
              <MuiButton
                text="Data Table"
                variant="outlined"
                size="large"
                color="primary"
                type="button"
                minWidth="160px"
                onClick={navigateToTable}
                startIcon={Icons.dataTable}
              />
            </Box>
          </Stack>
        )}
      </Box>
      {!generationRecords ? (
        <Loading />
      ) : (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={2}
          sx={{ mt: { xs: 2 } }}
        >
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
            sx={{ borderRadius: "10px" }}
          >
            <DashboardCard
              chartTitle="Energy Generated"
              valueTitle="Total Energy"
              unit="MWh"
              value={sumAmount(energyGenerated)}
              seriesData={energyGenerated}
            />
          </Box>

          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
            sx={{ borderRadius: "10px" }}
          >
            <DashboardCard
              chartTitle="Hours Operated"
              unit="hrs"
              valueTitle="Total Hours Operated"
              color="#A63EC5"
              value={sumAmount(serviceHours)}
              seriesData={serviceHours}
            />
          </Box>

          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
            sx={{ borderRadius: "10px" }}
          >
            <DashboardCard
              chartTitle="Internal Consumption"
              unit="MWh"
              valueTitle="Total Internal Consumption"
              color="#39AEA9"
              seriesData={internalConsumption}
              value={sumAmount(internalConsumption)}
            />
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
            sx={{ borderRadius: "10px" }}
          >
            <DashboardCard
              chartTitle="Peak Load"
              valueTitle="Avg Peak Load"
              unit="MW"
              color="#FFC600"
              seriesData={peakLoad}
              value={avgAmount(peakLoad)}
            />
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6" }}
            sx={{
              borderRadius: "10px",
              bgcolor: "background.paper",
              p: 2,
              boxShadow: 0,
            }}
          >
            <PlantsLineChart
              title="Energy Generated"
              unit="MWh"
              totalValue={sumAmount(energyGenerated)}
              seriesData={plantSeriesData.energyGeneratedSeries}
            />
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6" }}
            sx={{
              borderRadius: "10px",
              bgcolor: "background.paper",
              p: 2,
              boxShadow: 0,
            }}
          >
            <PlantsLineChart
              title="Service Hours"
              unit="hrs"
              totalValue={sumAmount(serviceHours)}
              seriesData={plantSeriesData.serviceHoursSeries}
            />
          </Box>
          <Box
            gridColumn={{ xs: "span 12" }}
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={2}
            sx={{
              border: "1px solid #CCD0CD",
              p: 2,
              borderRadius: "10px",
              width: 1,
            }}
          >
            <Box gridColumn={{ xs: "span 12" }}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Box display="flex" sx={{ width: 1 }}>
                  <Selector
                    name="selectedPeriod"
                    label="Select Period"
                    value={selectedPeriod}
                    onChange={selectPeriod}
                    options={periods}
                  />
                </Box>
                <Box>
                  <Selector
                    name="selectedPlantName"
                    label="Select Plant"
                    defaultValue=""
                    value={selectedPlantName}
                    onChange={selectPlantName}
                    options={plantNames}
                  />
                </Box>
              </Stack>
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <CapacityFactorCard
                capacityFactor={selectedPlantKpi.capacityFactor / 100}
              />
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <SubPlantDashboardCard
                chartTitle="Energy Generated"
                unit="MWh"
                // color="#F05454"
                selectedPeriod={selectedPeriod}
                valuePercentage={subPlantData.energyPercentage}
                value={subPlantData.currentEnergy}
                seriesData={subPlantData.energyDataSeries}
              />
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <SubPlantDashboardCard
                chartTitle="Hours Operated"
                unit="hrs"
                valueTitle="Hours Operated"
                color="#A63EC5"
                selectedPeriod={selectedPeriod}
                valuePercentage={subPlantData.serviceHoursPercentage}
                value={subPlantData.currentServiceHours}
                seriesData={subPlantData.serviceHoursDataSeries}
              />
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              <SystemAvailabilityCard
                title="Availability"
                availability={selectedPlantKpi.availabilityFactor / 100}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default GenerationDetails;
