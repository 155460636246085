import React from 'react'
import Chart from "react-apexcharts";
import {COLORS} from "../../constants"

const RadialChart = ({ percentValue, chartLabels }) => {
    // eslint-disable-next-line
    const value = percentValue=="NaN"? 0:percentValue;
    const chartColors = [COLORS.CHART_COLOR];
    const gradientColors = [COLORS.CHART_GRADIENT_COLOR];
    const series = [value];
    const options = {
        chart: {
            type: 'radialBar',
            offsetY: -2,
            sparkline: {
                enabled: true
            }
        },
        colors: chartColors,
        
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '22px',
                        show: true,
                        color: 'primary.main'
                    }
                },
                track:{
                    background:"#D0CAB2"
                },
            }
        },
        grid: {
            padding: {
                top: -6
            }
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "vertical",
                gradientToColors: gradientColors,
                stops: [0, 100],
            },
        },
        labels: chartLabels,
    };



    return (
        <div>
            <Chart options={options} series={series} type="radialBar" />
        </div>

    )
}
export default RadialChart