import React, { useState } from "react";
import Box from "@mui/system/Box";
import { useHistory } from "react-router";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

const UserIcon = ({ currentUser, logout }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigateToUserProfile = () => {
    history.push("/lec/users/profile")
    setAnchorEl(null);
  };
  
  const handleClose = () =>{
      setAnchorEl(null);
  }

  const handleLogout = () => {
    logout();
    setAnchorEl(null);
    history.push("/login");
  };
  return (
    <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", "& h4":{padding:0, m:0}, "& svg":{ p:0, m:0}}}>
      <IconButton sx={{mr:0.1}}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle fontSize="large" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={navigateToUserProfile}>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>logout</MenuItem>
      </Menu>
      <h4>{currentUser.fullName}</h4>
    </Box>
  );
};

export default UserIcon;
