import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { appUserRoles } from '../../services/util';
import Reports from '../Reports';
import PrivateRoute from '../routes/PrivateRoute';
import DashboardDetails from './DashboardDetails';

const Dashboard = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
          <PrivateRoute
            roles={appUserRoles}
            path={path}
            exact
            component={DashboardDetails}
          />          
          <PrivateRoute
              roles={appUserRoles}
              path={`${path}/reports`}
              component={Reports}
            />

    </Switch>
  );
};

export default Dashboard;
