import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { appUserRoles } from "../../services/util";
import AddEditFinancialData from "./AddEditFinancialData";
import FinancialCollapsibleTable from "./FinancialCollapsibleTable";
import PrivateRoute from "../routes/PrivateRoute";
import FinancialKpiContextProvider from "../../contexts/financialKpiContext";
import FinancialKpiDetails from "./FinancialKpiDetails";

const FinancialKPI = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
        <FinancialKpiContextProvider>
          <PrivateRoute
            roles={appUserRoles}
            path={path}
            exact
            component={FinancialKpiDetails}
          />
          <PrivateRoute
            roles={appUserRoles}
            path={`${path}/table`}
            exact
            component={FinancialCollapsibleTable}
          />
          <PrivateRoute
            roles={appUserRoles}
            path={`${path}/table/form/:id`}
            exact
            component={AddEditFinancialData}
          />
          <PrivateRoute
            roles={appUserRoles}
            path={`${path}/table/form`}
            exact
            component={AddEditFinancialData}
          />
        </FinancialKpiContextProvider>
    </Switch>
  );
};

export default FinancialKPI;
