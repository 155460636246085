import { Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTransmissionKpiContext } from "../../contexts/transmissionKpiContext";
import Icons from "../../icons";
import MuiButton from "../controls/buttons/MuiButton";
import Loading from "../controls/Loading";
import Selector from "../controls/Selector";
import PageHeader from "../pageHeader";
import { transmissionKpi } from "../pageHeader/pageTitle";
import TransmissionKPICard from "./TransmissionKPICard";
import TransmissionKPICardWithChart from "./TransmissionKPICardWithChart";
import PeriodicKPICard from "./PeriodicKPICard";
import InsightsIcon from '@mui/icons-material/Insights';
import StackedLineChartTwoToneIcon from '@mui/icons-material/StackedLineChartTwoTone';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

const getDataSeries = (data, selectedYear, fieldName) =>{
  if(!data || !selectedYear) return;
  const dataSeries =  data.filter(d=>d.year === selectedYear).map(fd=>{
    const x = fd.period;
    const y = fd[fieldName];
    return {x, y}
  });
  return dataSeries.reverse();
}
const getDataSeries2 = (data, selectedYear, fieldName) =>{
  if(!data || !selectedYear) return;
  const dataSeries =  data.filter(d=>d.year === selectedYear).map(fd=>{
    const x = fd.period;
    const y = fd[fieldName]/1000;
    return {x, y}
  });
  return dataSeries.reverse();
}

const getPeriods = (data) =>{
  if(!data) return;
  const periods = data.map((d) => d.period).filter((value, index, self) => self.indexOf(value) === index);
  return periods;
}
const getSelectedRecord = (data, selectedPeriod) =>{
  let currentRecord, previousRecord;
  if(!data || !selectedPeriod) return {currentRecord, previousRecord};
  const currentIndex = data.findIndex(d=>d.period === selectedPeriod);
  if(currentIndex === -1) return {currentRecord, previousRecord};
  const prevIndex = currentIndex + 1;
  currentRecord = data[currentIndex];
  previousRecord = data[prevIndex];
  
  return {currentRecord, previousRecord};
}

const TransmissionKpiDetails = () => {
  const history = useHistory();
  const [energyDispatchedSeries, setEnergyDispatchedSeries] = useState([]);
  const [energyDeliveredSeries, setEnergyDeliveredSeries] = useState([]);
  const [energyImportedSeries, setEnergyImportedSeries] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  const [previousRecord, setPreviousRecord] = useState();
  const {
    transmissionDataQuery,
    years,
    selectedYear,
    setSelectedYear,
    yearlyTransmissionData,
  } = useTransmissionKpiContext();
  const { path } = useRouteMatch();
  const navigateToTable = () => {
    history.push(`${path}/table`);
  };

  const selectYear = (event) => {
    const value = event.target.value;
    setSelectedYear(value);
  };
  const selectPeriod = (event) =>{
    const value = event.target.value;
    setSelectedPeriod(value);
  }
  useEffect(() => {
    const {data} = transmissionDataQuery;
    if(data ){
      const edSeries = getDataSeries(data, selectedYear, "energyDispatched");
      setEnergyDispatchedSeries(edSeries);
      const edvSeries = getDataSeries(data, selectedYear, "energyDelivered");
      setEnergyDeliveredSeries(edvSeries);
      const eimSeries = getDataSeries2(data, selectedYear, "energyImported");
      setEnergyImportedSeries(eimSeries);
      const periodsData = getPeriods(data);
      setPeriods(periodsData);
      setSelectedPeriod(periodsData[0]);
    }
    //eslint-disable-next-line
  }, [transmissionDataQuery.data, selectedYear])
  useEffect(() => {
    const {data} = transmissionDataQuery;
    if(data && selectPeriod){
      const record = getSelectedRecord(data, selectedPeriod);
      setSelectedRecord(record.currentRecord);
      setPreviousRecord(record.previousRecord);   
    }    
    //eslint-disable-next-line
  }, [selectedPeriod])
  return (
    <Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 2, sm: 2 }}
        justifyContent="space-between"
      >
        <PageHeader
          icon={Icons.transmissionKpi}
          title={transmissionKpi.title}
          subTitle={transmissionKpi.subtitle}
        />
        <Box sx={{ display: { sm: "grid" }, placeItems: { sm: "center" } }}>
          {transmissionDataQuery.status === "loading" ? (
            <Skeleton />
          ) : (
            <Selector
              name="selectedYear"
              label="Select Year"
              value={selectedYear}
              onChange={selectYear}
              width="160px"
              options={years}
            />
          )}
        </Box>
        <Box sx={{ display: { sm: "grid" }, placeItems: { sm: "center" } }}>
          <MuiButton
            text="Data Table"
            variant="outlined"
            size="large"
            color="primary"
            type="button"
            minWidth="160px"
            onClick={navigateToTable}
            startIcon={Icons.dataTable}
          />
        </Box>
      </Stack>
      {(transmissionDataQuery.status === "loading" ||
      transmissionDataQuery.status === "error") ? (
        <Loading />
      ) : (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
            sx={{ borderRadius: "10px" }}
          >
            {yearlyTransmissionData && energyDispatchedSeries ? (
              <TransmissionKPICardWithChart
                title={"Energy Dispatched"}
                unit={"MWh"}
                icon = {<InsightsIcon fontSize="medium" />}
                seriesData = {energyDispatchedSeries}
                value={yearlyTransmissionData.energyDispatched}
                color="#00A19D"
              />
            ) : null}
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
            sx={{ borderRadius: "10px" }}
          >
            {yearlyTransmissionData && energyDeliveredSeries ? (
              <TransmissionKPICardWithChart
                title={"Energy Delivered"}
                unit={"MWh"}
                icon = {<StackedLineChartTwoToneIcon fontSize="medium" />}
                seriesData = {energyDeliveredSeries}
                value={yearlyTransmissionData.energyDelivered}
                color="#8A39E1"
              />
            ) : null}
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
            sx={{ borderRadius: "10px" }}
          >
            {yearlyTransmissionData && energyImportedSeries ? (
              <TransmissionKPICardWithChart
                title={"Energy Imported"}
                unit={"MWh"}
                icon = {<FlashOnIcon fontSize="medium" />}
                seriesData = {energyImportedSeries}
                value={yearlyTransmissionData.energyImported/1000}
                color="#FFBE30"
              />
            ) : null}
          </Box>

          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
            sx={{ borderRadius: "10px" }}
          >
            {yearlyTransmissionData ? (
              <TransmissionKPICard
                title={"System Capacity"}
                unit={"MW"}
                value={yearlyTransmissionData.systemCapacity}
                color="#483434"
              />
            ) : null}
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
            sx={{ borderRadius: "10px" }}
          >
            {yearlyTransmissionData ? (
              <TransmissionKPICard
                title={"Contracted Energy"}
                unit={"MWh"}
                value={yearlyTransmissionData.contractedEnergy}
                color="#483434"
              />
            ) : null}
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
            sx={{ borderRadius: "10px" }}
          >
            {yearlyTransmissionData ? (
              <TransmissionKPICard
                title={"Total Circuit Length"}
                unit={"km"}
                icon = {<DonutLargeIcon fontSize = "medium"/>}
                value={yearlyTransmissionData.totalLengthOfCircuit}
                color="#483434"
              />
            ) : null}
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
            sx={{ borderRadius: "10px" }}
          >
            {yearlyTransmissionData ? (
              <TransmissionKPICard
                title={"Total Circuits"}
                unit={""}
                icon = {<DonutLargeIcon fontSize = "medium"/>}
                value={yearlyTransmissionData.totalCircuit}
                color="#483434"
              />
            ) : null}
          </Box>
          <Box
            gridColumn={{ xs: "span 12" }}
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={2}
            sx={{
              border: "1px solid #483434",
              p: 2,
              borderRadius: "10px",
              width: 1,
            }}
          >
            <Box sx={{width:"100%"}}
              gridColumn={{ xs: "span 12" }}
            >
              <Stack sx = {{width:1}}  direction= {{xs: "column", sm:"row"}} spacing = {2} justifyContent = "space-between" >
                
              <Box sx = {{color:"text.secondary", fontSize:"20px"}}>
                Periodic Records
                </Box>
              <Box justifyContent={"end"}>
                <Selector
                  name="selectedPeriod"
                  label="Select Period"
                  value={selectedPeriod}
                  onChange={selectPeriod}
                  options={periods}
                />
              </Box>
              </Stack>
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              {
                selectedRecord?(
                  <PeriodicKPICard color={"#38A3A5"} currentValue = {selectedRecord?.energyDispatched}
                    previousValue = {previousRecord?.energyDispatched} title ={"Energy Dispatched"} 
                    unit = "MWh"
                  />
                ): null
              }
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              {
                selectedRecord?(
                  <PeriodicKPICard color={"#456268"} currentValue = {selectedRecord?.energyDelivered}
                    previousValue = {previousRecord?.energyDelivered} title ={"Energy Delivered"} 
                    unit = "MWh"
                  />
                ): null
              }
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              {
                selectedRecord?(
                  <PeriodicKPICard color={"#456268"} currentValue = {selectedRecord?.energyImported}
                    previousValue = {previousRecord?.energyImported} title ={"Energy Imported"} 
                    unit = "MWh"
                  />
                ): null
              }
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 3" }}
              sx={{ borderRadius: "10px" }}
            >
              {
                selectedRecord?(
                  <PeriodicKPICard color={"#456268"} currentValue = {selectedRecord?.contractedEnergy}
                    previousValue = {previousRecord?.contractedEnergy} title ={"Contracted Energy"} 
                    unit = "MWh"
                  />
                ): null
              }
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TransmissionKpiDetails;
