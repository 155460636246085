import React, { useState, useContext, createContext } from 'react';

import { getPeriodInfo } from '../services/util';
import * as apiServices from '../services/apiServices';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const departmentWageContext = createContext();
const rootPath = '/department-wages';
export const useDepartmentWageContext = () =>
  useContext(departmentWageContext);

const fetchDepartmentWages = async ({ queryKey }) => {
  const result = await apiServices.getAll(rootPath);
  return result.data;
};

const fetchUniqueEmployeeTypes = async ({ queryKey }) => {
  const result = await apiServices.getAll(`${rootPath}/unique/names`);
  return result.data;
};
const fetchDepartmentWagesByPeriod = async ({ queryKey }) => {
  const [, period] = queryKey;
  const result = await apiServices.getAll(`${rootPath}/by-period/${period}`);
  return result.data;
};


const deleteData = async (id) => {
  const result = await apiServices.deleteData(`${rootPath}/delete/${id}`);
  return result.data;
};

const updateDepartmentWage = async (recordToEdit) => {
  const { _id, period, year, month, periodHours, ...data } = recordToEdit;
  const periodInfo = getPeriodInfo(period);
  if (!periodInfo) throw new Error('Period not in correct format');
  const { year: y, month: m } = periodInfo;
  const dataToEdit = { period, year: y, month: m, ...data };
  await apiServices.updateData(`${rootPath}/edit/${_id}`, dataToEdit);
  return { _id, ...dataToEdit };
};

const createDepartmentWage = async (data) => {
  try {
    const { period } = data;
    const periodInfo = getPeriodInfo(period);
    if (!periodInfo) throw new Error('Period not in correct format');
    const { year, month } = periodInfo;
    const departmentWage = { year, month, ...data };
    const result = apiServices.createData(rootPath, departmentWage);
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

const DepartmentWageContextProvider = ({ children }) => {
  const [selectedPeriod, setSelectedPeriod] = useState("NO PERIOD");
  const departmentWagesQuery = useQuery(
    'department-wages',
    fetchDepartmentWages
  );
  const recordsByPeriodQuery = useQuery(
    ['records-by-period', selectedPeriod],
    fetchDepartmentWagesByPeriod
  );
  
  const uniqueEmployeeTypesQuery = useQuery(
    'unique-employee-types',
    fetchUniqueEmployeeTypes
  );

  const queryClient = useQueryClient();
  const { mutateAsync: submitDepartmentWage } = useMutation(
    createDepartmentWage,
    {
      onSuccess: (data) => {
        queryClient.setQueryData('department-wages', (departmentWages) => {
          return [...departmentWages, data.data];
        });
      },
    }
  );

  const { mutateAsync: editDepartmentWage } = useMutation(
    updateDepartmentWage,
    {
      onSuccess: (data) => {
        queryClient.setQueryData('department-wages', (departmentWages) => {
          if (!data) return;
          const updatedRecords = departmentWages.map((d) => {
            if (d._id === data._id) {
              return data;
            }
            return d;
          });
          return updatedRecords;
        });
      },
    }
  );

  const { mutateAsync: deleteDepartmentWage } = useMutation(
    'department-wages',
    deleteData,
    {
      onSuccess: (data) => {
        queryClient.removeQueries('department-wages', data._id);
      },
    }
  );

  const value = {
    departmentWagesQuery,
    uniqueEmployeeTypesQuery,
    submitDepartmentWage,
    editDepartmentWage,
    deleteDepartmentWage,
    recordsByPeriodQuery,
    setSelectedPeriod,
  };

  return (
    <departmentWageContext.Provider value={value}>
      {children}
    </departmentWageContext.Provider>
  );
};

export default DepartmentWageContextProvider;
