import Box from "@mui/system/Box";
import BuildIcon from "@mui/icons-material/Build";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useUsersContext } from "../../contexts/UsersContext";
import Input from "../controls/formControls/Input";
import PageHeader from "../pageHeader";
import Selector from "../controls/Selector"
import MuiButton from "../controls/buttons/MuiButton";
import { appUserRoles } from "../../services/util";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as yup from "yup";
import { Form, Formik } from "formik";
import SuccessToastNotification from "../controls/SuccessToastNotification";
import ErrorToastNotification from "../controls/ErrorToastNotification";

const phoneRegExp =
  /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object({
  fullName: yup
    .string("Enter FullName")
    .required("Full Name is required")
    .min(2, "Full Name must be at least 2 characters"),
  email: yup
    .string("Enter User Email")
    .required("Email Adress is required")
    .min(11, "Email must be at least 11 characters in length."),
  phoneNumber: yup
    .string("Please enter user phone number")
    .matches(phoneRegExp, "Enter a valid phone number"),
  role: yup
    .string("Please enter user role")
    .required("User role is required")
});


const initValues = {
  fullName:"",
  email: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  role:appUserRoles[2]
}

const UserEditForm = () => {
  const history = useHistory();

  const [hasError, setHasError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [initialValues, setInitialValues] = useState(initValues);

  const { editUser, setEditedUser, setUserToEdit, userToEdit } = useUsersContext();
  const goBack = () => {
    setUserToEdit(null);
    history.goBack();
  };

  useEffect(() => {
    if (userToEdit !==null) {
      setInitialValues(userToEdit);
    } else {
      setInitialValues(initValues);
    }
  }, [userToEdit]);

  return (
    <Box>
      <MuiButton
        text="Back"
        color="success"
        size="small"
        type="button"
        variant="outlined"
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
      />
      <PageHeader
        icon={<BuildIcon />}
        title="User Edit Form"
        subTitle="Form for editing user's information."
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          setEditedUser(values)
          await editUser(values, {
            onError: (error) => {
              setHasError(true);
            },
            onSuccess: () => {
              resetForm();
              setIsSuccessful(true);
              goBack();
            },
          });
        }}
      >
        {({
          values,
          touched,
          errors,
          handleReset,
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                bgcolor: "background.paper",
                p: 2,
                borderRadius: 2,
                width:1
              }}
            >
              <Box sx={{width: 1}}>
              <Selector
                  options={appUserRoles}
                  name="role"
                  label="User Role"
                  onChange={handleChange}
                  value={values.role}
                  onBlur={handleBlur}
                  error={touched.role && Boolean(errors.role)}
                  helperText={
                    touched.role && errors.role ? errors.role : ""
                  }
                />
                <Input
                  onChange={handleChange}
                  label="Full Name"
                  value={values.fullName}
                  type="text"
                  name="fullName"
                  onBlur={handleBlur}
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={
                    touched.fullName && errors.fullName ? errors.fullName : ""
                  }
                />
                <Input
                  onChange={handleChange}
                  label="Email"
                  value={values.email}
                  type="text"
                  name="email"
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email ? errors.email : ""}
                />
                <Input
                  onChange={handleChange}
                  label="Phone Number"
                  value={values.phoneNumber}
                  type="text"
                  name="phoneNumber"
                  onBlur={handleBlur}
                  error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                  helperText={
                    touched.phoneNumber && errors.phoneNumber
                      ? errors.phoneNumber
                      : ""
                  }
                />
                <Box display="inline">
                  <LoadingButton
                    sx={{ ml: 1, my: 2, textTransform: "none" }}
                    disabled={
                      isSubmitting ||
                      JSON.stringify(values) === JSON.stringify(initialValues)
                    }
                    loading={isSubmitting}
                    variant="contained"
                    size="large"
                    type="submit"
                  >
                    Submit
                  </LoadingButton>
                  <Button
                    sx={{ ml: 1, my: 2, textTransform: "none" }}
                    disabled={isSubmitting}
                    onClick={handleReset}
                    size="large"
                    color="secondary"
                    variant="contained"
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <SuccessToastNotification
        isOpen={isSuccessful}
        setIsSuccessful={setIsSuccessful}
        message="Form successfully saved!"
      />
      <ErrorToastNotification
        isOpen={hasError}
        setHasError={setHasError}
        message="Error occured while saving the form!"
      />
    </Box>
  );
};

export default UserEditForm;
