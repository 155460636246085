import { Box, Stack } from "@mui/material";
import React from "react";
import BoltIcon from '@mui/icons-material/Bolt';
import { transmissionCardBaseStyles } from "../styles";
import { formatFigure3 } from "../../services/util";
import { alpha } from "@mui/system";
const defaultIcon =  <BoltIcon  fontSize = "medium" />;
const TransmissionKPICard = ({ title, value, unit, color, icon = defaultIcon }) => {
  return (
    <Box
      sx={{
        ...transmissionCardBaseStyles,
        p: 2,
        gridTemplateRows: "repeat(2, auto auto)",
        placeItems: "start",
      }}
      display="grid"
    >
      <Stack sx={{width:"100%"}} direction={"row"} justifyContent="space-between" alignContent={"center"}>
        <Box sx={{display:"grid", placeItems:"center", ml:1, color:"text.secondary"}}>{title}</Box>
        <Box
          sx={{
            p: 2,
            borderRadius: 1.5,
            bgcolor: alpha(color, 0.2),
            display: "grid",
            placeItems: "center",
            color: alpha(color, 1)
          }}
        >
         {icon}
        </Box>
      </Stack>
      <Box sx={{ fontSize: "20px", fontWeight: "bold", mt: 1 }}>
        {`${formatFigure3(value, 0)} ${unit}`}
      </Box>
    </Box>
  );
};

export default TransmissionKPICard;
