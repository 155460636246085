import { useEffect, useState } from 'react';

const useTable = ({ headCells, record }) => {
  const [data, setData] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const rowsPerPageOptions = [25, 10, 5];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[page + 1]);

  useEffect(() => {
    if ('status' in record && 'data' in record) {
      if (record.status === 'success')
        if ('data' in record.data) {
          setData(record.data.data);
        } else {
          setData(record.data);
        }
    } else {
      setData(record);
    }
  }, [record]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const recordsAfterPagingAndSorting = () => {
    return stableSort(data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  return {
    order,
    orderBy,
    rowsPerPage,
    page,
    rowsPerPageOptions,
    count: data.length,
    setOrder,
    setOrderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    recordsAfterPagingAndSorting,
  };
};

export default useTable;
