import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/system/Box';
import { COLORS } from './constants';
import Navbar from './components/navigation/Navbar';
import React from 'react';
import AuthContextProvider from './contexts/authContext';
import { appUserRoles } from './services/util';
import PrivateRoute from './components/routes/PrivateRoute';
import { Route, Switch } from 'react-router';
import Login from './Login';
import UserProfile from './components/users/UserProfile';
import PasswordContextProvider from './contexts/passwordContext';
import ChangePasswordForm from './components/users/ChangePasswordForm';
const theme = createTheme({
  palette: {
    background: {
      default: COLORS.MAIN_COLOR,
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthContextProvider>
          <PasswordContextProvider>
            <Box sx={{ backgroundColor: 'background.default' }}>
              <Switch>
                <Route path='/login' exact component={Login} />

                <PrivateRoute
                  roles={appUserRoles}
                  path='/lec/users/profile'
                  exact
                  component={UserProfile}
                />
                <PrivateRoute
                  roles={appUserRoles}
                  path='/lec/users/password'
                  exact
                  component={ChangePasswordForm}
                />
                <PrivateRoute
                  roles={appUserRoles}
                  path='/'
                  component={Navbar}
                />
              </Switch>
            </Box>
          </PasswordContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
