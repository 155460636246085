import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import FinancialCard from "./FinancialCard";
import { accountNames } from "./accountNames";
import FinancialCardWithChart from "./FinancialCardWithChart";
import AccountCard from "./AccountCard";
import FinancialLineChart from "../controls/FinancialLineChart";
import Loading from "../controls/Loading";
import PageHeader from "../pageHeader";
import { useFinancialKpiContext } from "../../contexts/financialKpiContext";
import { Stack } from "@mui/material";
import Icons from "../../icons";
import { financialKpi } from "../pageHeader/pageTitle";
import MuiButton from "../controls/buttons/MuiButton";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import Selector from "../controls/Selector";

const getAccount = (yearlyFinancialData, accountName) => {
  if (!yearlyFinancialData) return;
  const account = yearlyFinancialData.find((yfd) => yfd.name === accountName);
  if (!account) return;
  return account;
};

const getDataSeries = (data, accountName) => {
  if (!data) return;
  const filteredData = data.filter((d) => d.name === accountName);
  if (!filteredData) return [];

  return filteredData
    .map((fd) => {
      return { x: fd.period, y: fd.total };
    })
    .reverse();
};

const getFinancialDataSeries = (data, accountNames) => {
  if (!data) return;
  const filteredData = data
    .filter((d) => accountNames.includes(d.name))
    .reverse();
  const seriesData = [];
  filteredData.forEach((fd) => {
    const { period, total, name } = fd;
    const series = {
      name,
      data: [{ x: period, y: parseFloat(total.toFixed(2)) }],
    };
    const index = seriesData.findIndex((sd) => sd.name === name);
    if (index === -1) seriesData.push(series);
    else {
      seriesData[index].data.push({
        x: period,
        y: parseFloat(total.toFixed(2)),
      });
    }
  });

  return seriesData;
};

const getGroupedAccountDataSeries = (data, accountName) =>{
  if(!data) return;
  const filteredData = data.filter(d=>d.name === accountName).reverse();
  const dataSeries = [];
  filteredData.forEach(fd=>{
    const {accounts, period} = fd;
    accounts.forEach(account =>{
      const {name, value} = account;
      if(name === accountName) return;
      const series = {
        name,
        data: [{ x: period, y: value }],
      };
      const index = dataSeries.findIndex(ds=>ds.name === name);
      if(index === -1) dataSeries.push(series);
      else{
        dataSeries[index].data.push({ x: period, y: value })
      }
    })
  });
  return dataSeries;
}

const FinancialKpiDetails = () => {
  const [totalSales, setTotalSales] = useState();
  const [totalWages, setTotalWages] = useState();
  const [totalExpenses, setTotalExpenses] = useState();
  const [totalDebt, setTotalDebt] = useState();
  const [accountReceivable, setAccountReceivable] = useState();
  const [totalOwnersEquity, setTotalOwnersEquity] = useState();
  const [totalFixedAssets, setTotalFixedAssets] = useState();
  const [totalCurrentAssets, setTotalCurrentAssets] = useState();
  const [salesSeriesData, setSalesSeriesData] = useState([]);
  const [fuelCostSeriesData, setFuelCostSeriesData] = useState([]);
  const [salesVsExpenseSeries, setSalesVsExpenseSeries] = useState([]);
  const [expensesDataSeries, setExpensesDataSeries] = useState([]);
  const [totalFuelCost, setTotalFuelCost] = useState();
  const [grossProfitMargin, setGrossProfitMargin] = useState();
  const [totalInventory, setTotalInventory] = useState();


  const history = useHistory();
  const { financialData, yearlyFinancialData, selectedYear, years, setSelectedYear } = useFinancialKpiContext();

  useEffect(() => {
    if (yearlyFinancialData && financialData) {
      const salesSeries = getDataSeries(
        financialData,
        accountNames.sales
      );
      setSalesSeriesData(salesSeries);
      const fuelCostSeries = getDataSeries(
        financialData,
        accountNames.fuelCost
      );
      setFuelCostSeriesData(fuelCostSeries);
      const sales = getAccount(yearlyFinancialData, accountNames.sales);
      setTotalSales(sales);
      const fuelCost = getAccount(yearlyFinancialData, accountNames.fuelCost);
      setTotalFuelCost(fuelCost);
      const profitMargin = getAccount(
        yearlyFinancialData,
        accountNames.grossProfitMargin
      );
      const names = [accountNames.sales, accountNames.expenses];
      const salesVsExpense = getFinancialDataSeries(
        financialData,
        names
      );
      setSalesVsExpenseSeries(salesVsExpense);

      const expenseSeries = getGroupedAccountDataSeries(financialData, accountNames.expenses);
      setExpensesDataSeries(expenseSeries);

      setGrossProfitMargin(profitMargin);

      const wages = getAccount(
        yearlyFinancialData,
        accountNames.wagesAndSalaries
      );
      setTotalWages(wages);

      const ownersEquity = getAccount(yearlyFinancialData, accountNames.ownersEquity);
      setTotalOwnersEquity(ownersEquity);

      const receivable = getAccount(yearlyFinancialData, accountNames.accountReceivable);
      setAccountReceivable(receivable);

      const expenses = getAccount(yearlyFinancialData, accountNames.expenses);
      setTotalExpenses(expenses);

      const fixedAssets = getAccount(
        yearlyFinancialData,
        accountNames.fixedAssets
      );
      setTotalFixedAssets(fixedAssets);

      const currentAssets = getAccount(
        yearlyFinancialData,
        accountNames.currentAssets
      );
      setTotalCurrentAssets(currentAssets);

      const debt = getAccount(
        yearlyFinancialData,
        accountNames.debt
      );
      setTotalDebt(debt);

      const inventory = getAccount(yearlyFinancialData, accountNames.inventory);
      setTotalInventory(inventory);
    }
    // eslint-disable-next-line
  }, [yearlyFinancialData, financialData]);
  const { path } = useRouteMatch();
  const navigateToTable = () => {
    history.push(`${path}/table`);
  };
  const selectYear = (event) => {
    const value = event.target.value;
    setSelectedYear(value);
  };

  return (
    <Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 2, sm: 2 }}
        justifyContent="space-between"
      >
        <PageHeader
          icon={Icons.financialKpi}
          title={financialKpi.title}
          subTitle={financialKpi.subtitle}
        />
        <Box sx={{ display: { sm: "grid" }, placeItems: { sm: "center" } }}>
          {!years? (
            null
          ) : (
            <Selector
              name="selectedYear"
              label="Select Year"
              value={selectedYear}
              onChange={selectYear}
              width="160px"
              options={years}
            />
          )}
        </Box>
        <Box sx={{display: {sm:"grid"}, placeItems:{sm: "center"}}}>
          <MuiButton
            text="Data Table"
            variant="outlined"
            size="large"
            color="primary"
            type="button"
            minWidth="160px"
            onClick={navigateToTable}
            startIcon={Icons.dataTable}
          />
        </Box>
      </Stack>

      {!financialData || !yearlyFinancialData ? (
        <Loading />
      ) : (
        <Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={2}
            sx={{ mb: 3, mt:{xs:2} }}
          >
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
              sx={{ borderRadius: "10px" }}
            >
              {totalSales ? (
                <FinancialCardWithChart
                  color="#1EAE98"
                  title="Total Sales"
                  seriesData={salesSeriesData}
                  value={totalSales.total}
                />
              ) : null}
            </Box>

            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
              sx={{ borderRadius: "10px" }}
            >
              {totalFuelCost ? (
                <FinancialCardWithChart
                  title="Total Fuel Cost"
                  color="#CF0000"
                  seriesData={fuelCostSeriesData}
                  value={totalFuelCost.total}
                />
              ) : null}
            </Box>

            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
              sx={{ borderRadius: "10px" }}
            >
              <Stack direction="column" spacing={2}>
                <Box sx={{ borderRadius: "10px" }}>
                  {grossProfitMargin ? (
                    <FinancialCard
                      title="Gross Profit Margin"
                      value={grossProfitMargin.total}
                    />
                  ) : null}
                </Box>
                <Box sx={{ borderRadius: "10px" }}>
                  {totalInventory ? (
                    <FinancialCard
                      title="Total Inventory"
                      value={totalInventory.total}
                    />
                  ) : null}
                </Box>
              </Stack>
            </Box>

            <Box
              gridColumn={{ xs: "span 12", sm:"span 6" }}
              sx={{
                borderRadius: "10px",
                bgcolor: "background.paper",
                p: 2,
                boxShadow: 0,
              }}
            >
              {salesVsExpenseSeries ? (
                <FinancialLineChart
                  title={"Sales vs Expenses Graph"}
                  subtitle={"Amount"}
                  seriesData={salesVsExpenseSeries}
                />
              ) : null}
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm:"span 6" }}
              sx={{
                borderRadius: "10px",
                bgcolor: "background.paper",
                p: 2,
                boxShadow: 0,
              }}
            >
              {expensesDataSeries ? (
                <FinancialLineChart
                  title={"Expenses Comparison Graph"}
                  subtitle={"Amount"}
                  seriesData={expensesDataSeries}
                />
              ) : null}
            </Box>

            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
              sx={{ borderRadius: "10px" }}
            >
              <Stack direction="column" spacing={2}>
                <Box sx={{ borderRadius: "10px" }}>
                  {accountReceivable ? (
                    <FinancialCard
                      title="Account Receivable"
                      value={accountReceivable.total}
                    />
                  ) : null}
                </Box>
                <Box sx={{ borderRadius: "10px" }}>
                  {totalOwnersEquity ? (
                    <FinancialCard
                      title="Total Owners Equity"
                      value={totalOwnersEquity.total}
                    />
                  ) : null}
                </Box>
              </Stack>
            </Box>

            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
              sx={{ borderRadius: "10px" }}
            >
              {totalExpenses ? <AccountCard account={totalExpenses} /> : null}
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
              sx={{ borderRadius: "10px" }}
            >
              {totalWages ? <AccountCard account={totalWages} /> : null}
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
              sx={{ borderRadius: "10px" }}
            >
              {totalCurrentAssets ? (
                <AccountCard account={totalCurrentAssets} />
              ) : null}
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
              sx={{ borderRadius: "10px" }}
            >
              {totalFixedAssets ? (
                <AccountCard account={totalFixedAssets} />
              ) : null}
            </Box>
            <Box
              gridColumn={{ xs: "span 12", sm: "span 6", lg: "span 4" }}
              sx={{ borderRadius: "10px" }}
            >
              {totalDebt ? (
                <AccountCard account={totalDebt} />
              ) : null}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FinancialKpiDetails;
