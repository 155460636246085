import React, { useState } from 'react';
import { useDepartmentWageContext } from '../../contexts/departmentWagesContext';
import useTable from '../../hooks/useTable';
import TblPagination from '../controls/tables/TblPagination';
import TblHead from '../controls/tables/TblHead';
import TblContainer from '../controls/tables/TblContainer';
import { TableBody, TableCell, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Popup from '../controls/popup';
import { useAuthContext } from '../../contexts/authContext';
import SimplePopupContent from '../controls/popup/SimplePopupContent';
import { useHistory } from 'react-router-dom';
import { departmentWageHeadCell as headCells } from '../controls/tables/headCell';
import { Box } from '@mui/system';
import MuiButton from '../controls/buttons/MuiButton';
import { formatFigure } from '../../services/util';
import PageHeader from '../../components/pageHeader';
import { departmentWageKpi } from '../pageHeader/pageTitle';
import Icons from '../../icons';
import SuccessToastNotification from '../controls/SuccessToastNotification';
import ErrorToastNotification from '../controls/ErrorToastNotification';
import EditDeleteMenu from '../controls/EditDeleteMenu';
import Loading from '../controls/Loading';

const DepartmentWageList = ({ location }) => {
  const history = useHistory();
  const [hasError, setHasError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState('');
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [openDeleteDataPopup, setOpenDeleteDataPopup] = useState(false);
  const { departmentWagesQuery, deleteDepartmentWage } =
    useDepartmentWageContext();
  const { currentUser } = useAuthContext();
  const { pathname } = location;
  const {
    order,
    orderBy,
    rowsPerPage,
    page,
    rowsPerPageOptions,
    count,
    setOrder,
    setOrderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    recordsAfterPagingAndSorting,
  } = useTable({ record: departmentWagesQuery });

  const navigateToDetails = (id) => {
    history.push(`/lec/department-wages/${id}`);
  };
  const navigateToForm = () => {
    history.push(`${pathname}/form`);
  };

  const yesToDelete = async () => {
    setOpenDeleteDataPopup(false);
    await deleteDepartmentWage(itemToDeleteId, {
      onError: (err) => {
        setHasError(true);
        handleError(error);
        setItemToDeleteId(null);
      },
      onSuccess: () => {
        setItemToDeleteId(null);
        setIsSuccessful(true);
      },
    });
  };

  const noToDelete = () => {
    setOpenDeleteDataPopup(false);
    //setDataToDelete(null);
  };
  const prompToDeleteData = (id) => {
    if (!id) return;
    setItemToDeleteId(id);
    setOpenDeleteDataPopup(true);
  };

  const editData = (id) => {
    history.push(`${pathname}/form/${id}`);
  };

  const handleError = (error) => {
    if (error.response) {
      setError(error.response.data.msg);
    } else if (error.request) {
      setError('Please check your internet connection');
    } else {
      setError(error.message);
    }
  };

  return (
    <Box>
      <PageHeader
        icon={Icons.departmentWage}
        title={departmentWageKpi.title}
        subTitle={departmentWageKpi.subtitle}
      />
      {departmentWagesQuery.status === 'loading' ? (
        <Loading />
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: -1,
              bgcolor: '#fff',
              borderRadius: 2,
              p: 1,
              '& .MuiBox-root': {
                margin: 1,
                fontWeight: 'medium',
                fontSize: 20,
              },
              '& .MuiButton-outlined': {
                padding: '12px 12px 12px 0',
                m: 1,
              },
            }}
          >
            <Box>Department Wages Data</Box>
            {currentUser && currentUser.role === 'Admin' && (
              <MuiButton
                text='Add New'
                variant='outlined'
                size='large'
                color='primary'
                type='button'
                minWidth='150px'
                onClick={navigateToForm}
                startIcon={<AddIcon />}
              />
            )}
          </Box>
          <TblContainer>
            <TblHead
              headCells={headCells}
              orderBy={orderBy}
              order={order}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={`${item._id}~${item.name}`}>
                  <TableCell
                    onClick={() => navigateToDetails(item.employeeType)}
                    component='th'
                    scope='row'
                    size='medium'
                    sx={{
                      '&:hover': {
                        color: 'primary.main',
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {item.employeeType}
                  </TableCell>
                  <TableCell align='left' size='small'>
                    {item.period}
                  </TableCell>

                  <TableCell align='right' size='small'>
                    {item.employeeCount}
                  </TableCell>
                  <TableCell align='right' size='small'>
                    {formatFigure(item.totalWage)}
                  </TableCell>

                  {currentUser && currentUser.role === 'Admin' && (
                    <TableCell align='right'>
                      <EditDeleteMenu
                        onEditClick={() => editData(item._id)}
                        onDeleteClick={() => prompToDeleteData(item._id)}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            page={page}
            count={count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      )}
      <Popup
        title='Delete Department Wage Data?'
        openPopup={openDeleteDataPopup}
        setOpenPopup={setOpenDeleteDataPopup}
      >
        <SimplePopupContent
          yesOnClick={yesToDelete}
          noOnClick={noToDelete}
          message={`Are you sure you want to delete  data for ?`}
        />
      </Popup>
      <SuccessToastNotification
        isOpen={isSuccessful}
        setIsSuccessful={setIsSuccessful}
        message='Item deleted successfully'
      />
      <ErrorToastNotification
        isOpen={hasError}
        setHasError={setHasError}
        message={error}
      />
    </Box>
  );
};

export default DepartmentWageList;
