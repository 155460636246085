import { Box } from '@mui/system';
import React from 'react'
import RadialChart from './RadialChart';
import CardTitle from './CardTitle';
import definitions from '../../services/kpiDefinitions';
import {cardBaseStyles} from "../styles";

const SystemAvailabilityCard = ({title="System Availability", availability}) => {
    const availabilityPercentage = parseFloat(100*availability).toFixed(1) || 0;
    return (
        <Box  sx={{
            ...cardBaseStyles
          }}>
            <CardTitle title={title} definition={definitions.availability}/>
            <RadialChart percentValue = {availabilityPercentage} chartLabels={title}/>
        </Box>
    )
}

export default SystemAvailabilityCard
