import Box from '@mui/system/Box';
import BuildIcon from '@mui/icons-material/Build';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDepartmentWageContext } from '../../contexts/departmentWagesContext';
import InputWithAdornment from '../controls/formControls/InputWithAdornment';
import PageHeader from '../pageHeader';
import MuiButton from '../controls/buttons/MuiButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Selector from '../controls/Selector';
import { getPeriods } from '../../services/util';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import SuccessToastNotification from '../controls/SuccessToastNotification';
import ErrorToastNotification from '../controls/ErrorToastNotification';
import { Paper } from '@mui/material';

const validationSchema = yup.object({
  period: yup.string('Enter a valid period').required('period is required'),
  employeeType: yup
    .string('Enter a valid employee type')
    .required('employee type is required'),
  employeeCount: yup
    .number('Enter a valid employee count')
    .required('employee count is required'),
  totalWage: yup
    .number('Enter a valid total wage')
    .required('total wage is required'),
});

const periods = getPeriods();
const initialValues = {
  period: '',
  employeeType: '',
  employeeCount: '',
  totalWage: '',
};

const AddEditDepartmentWage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [hasError, setHasError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [mode, setMode] = useState('');
  const [error, setError] = useState('');

  const {
    departmentWagesQuery,
    uniqueEmployeeTypesQuery,
    submitDepartmentWage,
    editDepartmentWage,
  } = useDepartmentWageContext();

  useEffect(() => {
    if (id) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [id]);

  const goBack = () => {
    history.goBack();
  };
  const getEmployeeTypes = (queryResult) => {
    const { status, data } = queryResult;
    if (status === 'success') {
      return data.map((d) => {
        return { label: d };
      });
    }
    return [];
  };

  const getDepartmentWageToEdit = () => {
    const { status, data } = departmentWagesQuery;
    if (status === 'success') {
      return data.find((d) => d._id === id);
    } else {
      return initialValues;
    }
  };

  const handleError = (error) => {
    if (error.response) {
      setError(error.response.data.msg);
    } else if (error.request) {
      setError('Please check your internet connection');
    } else {
      setError(error.message);
    }
  };

  return (
    <Box>
      <MuiButton
        text='Back'
        color='success'
        size='small'
        type='button'
        variant='outlined'
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
      />
      <PageHeader
        icon={<BuildIcon />}
        title={
          mode === 'add'
            ? 'Add Department Wage Data Form'
            : 'Edit Department Wage Data Form'
        }
        subTitle={`Form for ${mode}ing department wage data.`}
      />
      <Formik
        initialValues={id ? getDepartmentWageToEdit() : initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          if (mode === 'add') {
            await submitDepartmentWage(values, {
              onError: (error) => {
                setHasError(true);
                handleError(error);
              },
              onSuccess: () => {
                resetForm();
                setIsSuccessful(true);
              },
            });
          } else if (mode === 'edit') {
            await editDepartmentWage(values, {
              onError: (error) => {
                setHasError(true);
                handleError(error);
              },
              onSuccess: () => {
                resetForm();
                setIsSuccessful(true);
              },
            });
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          isValid,
          dirty,
          handleReset,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Paper
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                columnGap: 2,
                rowGap: 2,
                p: 2,
              }}
            >
              <Selector
                options={periods}
                name='period'
                label='Period'
                onChange={handleChange}
                value={values.period}
                onBlur={handleBlur}
                error={touched.period && Boolean(errors.period)}
                helperText={
                  touched.period && errors.period ? errors.period : ''
                }
              />
              <Autocomplete
                options={getEmployeeTypes(uniqueEmployeeTypesQuery)}
                value={values.employeeType}
                onOpen={handleBlur}
                freeSolo={true}
                onInputChange={(e, value) => {
                  setFieldValue('employeeType', value || '', true);
                }}
                onChange={(e, value, reason) => {
                  setFieldValue('employeeType', value?.label || '', true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='contact'
                    variant='outlined'
                    type='text'
                    label='Plant Name'
                    onBlur={handleBlur}
                    error={touched.employeeType && Boolean(errors.employeeType)}
                    helperText={
                      touched.employeeType && errors.employeeType
                        ? errors.employeeType
                        : ''
                    }
                  />
                )}
              />
              <InputWithAdornment
                label='Employee Count'
                value={values.employeeCount}
                type='number'
                onChange={handleChange}
                name='employeeCount'
                onBlur={handleBlur}
                error={touched.employeeCount && Boolean(errors.employeeCount)}
                helperText={
                  touched.employeeCount && errors.employeeCount
                    ? errors.employeeCount
                    : ''
                }
                adornmentText=''
              />
              <InputWithAdornment
                label='Total Wage'
                value={values.totalWage}
                type='number'
                onChange={handleChange}
                name='totalWage'
                onBlur={handleBlur}
                error={touched.totalWage && Boolean(errors.totalWage)}
                helperText={
                  touched.totalWage && errors.totalWage ? errors.totalWage : ''
                }
                adornmentText='$'
              />

              <Box display='inline'>
                <LoadingButton
                  sx={{ ml: 1, my: 2, textTransform: 'none' }}
                  disabled={isSubmitting || !isValid || !dirty}
                  loading={isSubmitting}
                  variant='contained'
                  size='large'
                  type='submit'
                >
                  Submit
                </LoadingButton>
                <Button
                  sx={{ ml: 1, my: 2, textTransform: 'none' }}
                  disabled={isSubmitting}
                  onClick={handleReset}
                  size='large'
                  color='secondary'
                  variant='contained'
                >
                  Reset
                </Button>
              </Box>
            </Paper>
          </Form>
        )}
      </Formik>
      <SuccessToastNotification
        isOpen={isSuccessful}
        setIsSuccessful={setIsSuccessful}
        message='Form successfully saved!'
      />
      <ErrorToastNotification
        isOpen={hasError}
        setHasError={setHasError}
        message={error}
      />
    </Box>
  );
};

export default AddEditDepartmentWage;
