import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/system/Box";
import styled from "@mui/system/styled";
import React from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import { withRouter, Switch, Redirect, Route, NavLink } from "react-router-dom";

import { Routes } from "../routes/Route";
import { DRAWER_WIDTH } from "../../constants";
import Drawer from "@mui/material/Drawer";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuList,
  MenuItem,
  CssBaseline,
} from "@mui/material";
import PrivateRoute from "../routes/PrivateRoute";
import SharedKpiContextProvider from "../../contexts/sharedKpiContext";
import { useAuthContext } from "../../contexts/authContext";
import Dashboard from "../../components/dashboard";
import Users from "../../components/users";
import FinancialKPI from "../../components/financialKPIs";
import GenerationKpi from "../../components/generationKPIs";
import TransmissionKPI from "../../components/transmissionKPIs";
import CustomerBilling from "../../components/customer-billing";
import DepartmentWageKPI from "../../components/department-wage";
import UserIcon from "../../components/users/UserIcon";
import { appUserRoles, superUserRoles } from "../../services/util";

const MuiLink = styled(NavLink)(() => ({
  textDecoration: "none",
}));

const Navbar = (props) => {
  const { currentUser, logoutUser } = useAuthContext();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { pathname } = props.location;

  const activeRoute = (routeName) => {
    let replace = routeName;
    let re = new RegExp(replace, "g");
    return re.test(pathname);
  };
  const drawer = (
    <Box sx={{ bgcolor: (theme) => theme.palette.background.paper }}>
      <Toolbar variant="dense" />
      <Divider />
      <MenuList>
        {Routes.map((prop, key) => {
          if (
            currentUser &&
            currentUser.role !== "Admin" &&
            prop.sidebarName === "Users"
          )
            return <Divider sx={{ mt: 2 }} />;
          return (
            <MuiLink
              sx={{
                color: (theme) =>
                  activeRoute(prop.path)
                    ? theme.palette.info.main
                    : theme.palette.text.primary,
                "& .Mui-selected": {
                  borderRight: 8,
                  borderRadius: "6px",
                },
                "& .MuiMenuItem-root": {
                  py: "12px",
                },
              }}
              to={prop.path}
              key={key}
            >
              <MenuItem selected={activeRoute(prop.path)}>
                <ListItemIcon
                  sx={{
                    color: (theme) =>
                      activeRoute(prop.path)
                        ? theme.palette.info.main
                        : theme.palette.text.primary,
                  }}
                >
                  {prop.sidebarIcon}
                </ListItemIcon>
                <ListItemText primary={prop.sidebarName} />
              </MenuItem>
            </MuiLink>
          );
        })}
      </MenuList>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        }}
      >
        <Toolbar variant="dense">
          <IconButton
            sx={{ display: { sm: "none" } }}
            aria-label="open drawer"
            color="inherit"
            size="medium"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ fontSize: 20, fontWeight: "medium", flexGrow: 1 }}>
            LEC KPI Dashboard
          </Box>
          <Box sx={{ m: 0, p: 0 }}>
            {currentUser && (
              <UserIcon currentUser={currentUser} logout={logoutUser} />
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        componenet="nav"
        sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
        aria-label="lec-dashboard-nav"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
            },
          }}
          open={true}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          width: "100%",
          padding: 2,
        }}
      >
        <Toolbar variant="dense" />
        <SharedKpiContextProvider>
          <Switch>
            <PrivateRoute
              roles={appUserRoles}
              path="/lec/dashboard"
              component={Dashboard}
            />
            <Route exact path="/">
              <Redirect to="/lec/dashboard" />
            </Route>

            <PrivateRoute
              roles={appUserRoles}
              path="/lec/financial-kpis"
              component={FinancialKPI}
            />
            <PrivateRoute
              roles={appUserRoles}
              path="/lec/generation-kpis"
              component={GenerationKpi}
            />
            <PrivateRoute
              roles={appUserRoles}
              path="/lec/transmission-kpis"
              component={TransmissionKPI}
            />
            <PrivateRoute
              roles={appUserRoles}
              path="/lec/customer-billings"
              component={CustomerBilling}
            />
            <PrivateRoute
              roles={appUserRoles}
              path="/lec/department-wages"
              component={DepartmentWageKPI}
            />
            {currentUser && currentUser.role === "Admin" && (
              <PrivateRoute
                roles={superUserRoles}
                path="/lec/users"
                component={Users}
              />
            )}
          </Switch>
        </SharedKpiContextProvider>
      </Box>
    </Box>
  );
};

export default withRouter(Navbar);
