import ExcelJS from "exceljs";
import saveAs from "file-saver";

const totalDescription = {
    energyGenerated: "Gross Energy Generated",
    serviceHours: "Total Service hours",
    scheduledOutageHours: "TOTAL SCHEDULE OUTAGE HOURS",
    maintenanceHours: "Total Maintenance Outage",
    forcedOutage: "Total  Force Outage hour",
    unplannedOutage: "Total UNPLANNED OUTAGE HOUR(MOH+ FOH)",
    periodHours: "Total Period Hours",
    fuelConsumption: "Total Fuel Consumption",
    installedCapacity: "Installed Capacity",
    peakLoad: "Peak Load",
    internalConsumption: "Total Station service energy usage",
    availability: "Total Plant availability",
    totalGeneration: "Total Energy Generated",
    employeeCount: "Total Number of Employees",
    totalWage: "Total wages & salaries",
    expectedEnergy: "Expected energy  transmitted under full capacity conditions"
};

const processTotalGenerationData = (generationData) => {
    const plants = [
        { name: "World Bank", id: "wb", dataSource: "Gen Monthly Report", unit: "MWh" },
        { name: "High Speed", id: "high speed", dataSource: "Gen Monthly Report", unit: "MWh" },
        { name: "GoL", id: "gol", dataSource: "Gen Monthly Report", unit: "MWh" },
        { name: "JICA", id: "jica", dataSource: "Gen Monthly Report", unit: "MWh" },
        { name: "Mt. Coffee", id: "mtc", dataSource: "Gen Monthly Report", unit: "MWh" },
        { name: "CLSG(Imports)", id: "clsg", dataSource: "Gen Monthly Report", unit: "MWh" }
    ];
    const results = generationData.reduce((result, item) => {
        const { plantName, dataSource, unit, ...periods } = item;
        const plantIndex = plants.findIndex(p => plantName.toLowerCase().includes(p.id.toLowerCase()));
        if (plantIndex === -1) return undefined;

        Object.keys(periods).forEach(key => {
            const value = periods[key];
            if (!plants[plantIndex][key]) {
                plants[plantIndex][key] = value;
            }
            else {
                plants[plantIndex][key] += value;
            }
        })

        return result;

    }, plants)
    return results;
}

function generateTotalGenerationTable(sheet, data, rowNumber, totalDesc, lastColumn, agg = "SUM") {
    const firstRecord = data[0];
    const { name, id, plantType, dataSource, unit, ...rest } = firstRecord;
    const periodHeadings = Object.keys(rest);
    const len = data.length;
    sheet.getRow(rowNumber).values = [totalDesc, unit, dataSource];
    sheet.getRow(rowNumber).font = { bold: true };
    //set formula in excel
    periodHeadings.forEach((heading) => {
        const letter = sheet.getColumnKey(heading).letter;
        const formula = `=${agg}(${letter}${rowNumber + 1}:${letter}${rowNumber + len})`;
        const row = sheet.getRow(rowNumber);
        row.getCell(letter).value = { formula };
        row.getCell(letter).numFmt = "#,##0.00";
    });

    //set border and fill
    setBorderAndFill(sheet, lastColumn, rowNumber);

    data.forEach((rowData) => {
        //set border
        setBorder(sheet, lastColumn, rowNumber);
        sheet.addRow(rowData).numFmt = "#,##0.00"
        rowNumber++;
    });
    //set border
    setBorder(sheet, lastColumn, rowNumber);
    rowNumber += 1;
    return rowNumber;
}

const addRowAndStyle = (sheet, data, rowNumber, lastColumn) => {
    sheet.addRow(data);
    setBorderAndFillAndBold(sheet, lastColumn, rowNumber);
    return rowNumber + 1;
}
const setBold = (sheet, rowNumber) => {
    sheet.getRow(rowNumber).font = { bold: true };
}
const addRowWithBorder = (sheet, data, rowNumber, lastColumn) => {
    sheet.addRow(data);
    setBorder(sheet, lastColumn, rowNumber);
    setBold(sheet, rowNumber);
    return rowNumber + 1;
}

const setBorderAndFill = (generationInputSheet, lastColumn, rowNumber, color = "C4DDFF") => {
    for (let i = 1; i < lastColumn + 1; i++) {

        generationInputSheet.getCell(rowNumber, i).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
        generationInputSheet.getCell(rowNumber, i).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: color }
        }
    }
}
const setBorderAndFillAndBold = (sheet, lastColumn, rowNumber, color = "C4DDFF") => {
    for (let i = 1; i < lastColumn + 1; i++) {

        sheet.getCell(rowNumber, i).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
        sheet.getCell(rowNumber, i).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: color }
        };
    }
    sheet.getRow(rowNumber).font = { bold: true };
}
const setBorder = (generationInputSheet, lastColumn, rowNumber) => {
    for (let i = 1; i < lastColumn + 1; i++) {

        generationInputSheet.getCell(rowNumber, i).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
    }
}

const getPeriodicHeader = (powerPlantData) => {
    const generationData = powerPlantData["energyGenerated"];
    if (!generationData) return [];
    const firstRecord = generationData[0];
    const { plantName, plantType, dataSource, unit, ...rest } = firstRecord;
    const headers = Object.keys(rest);
    return headers.map(header => ({ key: header, heading: header, width: 14 }));
};

function setAndMergePeriodHeading(sheet, lastColumn) {
    const column = sheet.getColumn(lastColumn);
    const letter = column.letter;
    sheet.mergeCells(`D2:${letter}2`);
    sheet.getCell("D2").value = "PERIODS";
    sheet.getColumn(1).alignment = { vertical: "middle", horizontal: "left", wrapText: true };
    sheet.getColumn(2).alignment = { vertical: "middle", horizontal: "Left", wrapText: true };
}

function generatePowerPlantTable(generationInputSheet, powerPlantTypes, data, rowNumber, totalDesc, lastColumn, agg = "SUM") {
    powerPlantTypes.forEach(type => {
        const plantTypeData = data.filter(plant => plant.plantType === type);
        const firstRecord = plantTypeData[0];
        const { plantName, plantType, dataSource, unit, ...rest } = firstRecord;
        const periodHeadings = Object.keys(rest);
        const len = plantTypeData.length;
        generationInputSheet.getRow(rowNumber).values = [totalDesc, unit, dataSource];
        generationInputSheet.getRow(rowNumber).font = { bold: true };
        //set formula in excel
        periodHeadings.forEach((heading) => {
            const letter = generationInputSheet.getColumnKey(heading).letter;
            const formula = `=${agg}(${letter}${rowNumber + 1}:${letter}${rowNumber + len})`;
            const row = generationInputSheet.getRow(rowNumber);
            row.getCell(letter).value = { formula: formula };
            row.getCell(letter).numFmt = '#,##0.00';
        });

        //set border and fill
        setBorderAndFill(generationInputSheet, lastColumn, rowNumber);

        plantTypeData.forEach((data) => {
            //set border
            setBorder(generationInputSheet, lastColumn, rowNumber);
            generationInputSheet.addRow(data).numFmt = "#,##0.00"
            rowNumber++;
        });
        //set border
        setBorder(generationInputSheet, lastColumn, rowNumber);
        rowNumber += 2;
    });
    return rowNumber;
}

function generateDepartmentWageTable(sheet, periodHeadings, data, rowNumber, totalDesc, lastColumn, agg = "SUM") {
    if (!data) return;
    const { unit, dataSource } = data[0];
    const len = data.length;
    sheet.getRow(rowNumber).values = [totalDesc, unit, dataSource];
    sheet.getRow(rowNumber).font = { bold: true };
    //set formula in excel
    periodHeadings.forEach((heading, index) => {
        const letter = sheet.getColumnKey(heading).letter;
        const formula = `=${agg}(${letter}${rowNumber + 1}:${letter}${rowNumber + len})`;
        const row = sheet.getRow(rowNumber);
        row.getCell(letter).value = { formula: formula };
        row.getCell(letter).numFmt = '#,##0.00';
    });

    //set border and fill
    setBorderAndFill(sheet, lastColumn, rowNumber);

    data.forEach((d) => {
        //set border
        setBorder(sheet, lastColumn, rowNumber);
        sheet.addRow(d).numFmt = "#,##0.00"
        rowNumber++;
    });
    //set border
    setBorder(sheet, lastColumn, rowNumber);
    rowNumber += 1;
    return rowNumber;
}

const addTotalRow = (sheet, periodHeadings, desc, unit, dataSource, rowNumber, lastColumn, len1, len2) => {
    sheet.getRow(rowNumber).values = [desc, unit, dataSource];
    //set formula in excel
    periodHeadings.forEach((heading) => {
        const letter = sheet.getColumnKey(heading).letter;
        const formula = `=${letter}${rowNumber + 1} + ${letter}${rowNumber + len1 + 2} + ${letter}${rowNumber + len1 + len2 + 3}`;
        const row = sheet.getRow(rowNumber);
        row.getCell(letter).value = { formula: formula };
        row.getCell(letter).numFmt = '#,##0.00';
    });
    setBorderAndFillAndBold(sheet, lastColumn, rowNumber);

    return rowNumber + 1;
}

const generateFinancialTable = (tAndDInputSheet, data, tAndDRowNumber, totalDesc, tAndDLastColumn) => {
    if (!data) return;
    const { name, unit, dataSource, ...rest } = data[0];
    const len = data.length;
    if (len === 1) {
        tAndDInputSheet.addRow(data[0]).numFmt = "#,##0.00";
        setBorderAndFillAndBold(tAndDInputSheet, tAndDLastColumn, tAndDRowNumber);
    }
    else {
        tAndDInputSheet.getRow(tAndDRowNumber).values = [totalDesc, unit, dataSource];
        const periodHeadings = Object.keys(rest);
        //set formula in excel
        periodHeadings.forEach((heading, index) => {
            const letter = tAndDInputSheet.getColumnKey(heading).letter;
            const formula = `=SUM(${letter}${tAndDRowNumber + 1}:${letter}${tAndDRowNumber + len})`;
            const row = tAndDInputSheet.getRow(tAndDRowNumber);
            row.getCell(letter).value = { formula: formula };
            row.getCell(letter).numFmt = '#,##0.00';
        });
        setBorderAndFillAndBold(tAndDInputSheet, tAndDLastColumn, tAndDRowNumber);

        data.forEach((d) => {
            //set border
            setBorder(tAndDInputSheet, tAndDLastColumn, tAndDRowNumber);
            tAndDInputSheet.addRow(d).numFmt = "#,##0.00"
            tAndDRowNumber++;
        });
        //set border
        setBorder(tAndDInputSheet, tAndDLastColumn, tAndDRowNumber);

    }

    return tAndDRowNumber + 1;

};

function generateCustomerBillingTable(sheet, paymentTypes, data, rowNumber, lastColumn, agg = "SUM") {
    paymentTypes.forEach(type => {
        const paymentTypeData = data.filter(billing => billing.paymentType === type);
        const firstRecord = paymentTypeData[0];
        if (!firstRecord) return;
        const { name, paymentType, dataSource, unit, ...rest } = firstRecord;
        const periodHeadings = Object.keys(rest);
        const len = paymentTypeData.length;
        sheet.getRow(rowNumber).values = [paymentType, unit, dataSource];
        sheet.getRow(rowNumber).font = { bold: true };
        //set formula in excel
        periodHeadings.forEach((heading) => {
            const letter = sheet.getColumnKey(heading).letter;
            const formula = `=${agg}(${letter}${rowNumber + 1}:${letter}${rowNumber + len})`;
            const row = sheet.getRow(rowNumber);
            row.getCell(letter).value = { formula: formula };
            row.getCell(letter).numFmt = '#,##0.00';
        });

        //set border and fill
        setBorder(sheet, lastColumn, rowNumber);

        paymentTypeData.forEach((data) => {
            //set border
            setBorder(sheet, lastColumn, rowNumber);
            sheet.addRow(data).numFmt = "#,##0.00"
            rowNumber++;
        });
        //set border
        setBorder(sheet, lastColumn, rowNumber);
        rowNumber += 1;
    });
    return rowNumber;
}

function generatePeakLoadAndInternalConsumptionTable(generationInputSheet, powerPlantTypes, data, rowNumber, totalDesc, lastColumn) {
    powerPlantTypes.forEach(type => {
        const plantTypeData = data.filter(plant => plant.plantType === type);
        const firstRecord = plantTypeData[0];
        const { plantName, plantType, dataSource, unit, ...rest } = firstRecord;
        const periodHeadings = Object.keys(rest);
        // const len = plantTypeData.length;
        generationInputSheet.getRow(rowNumber).values = [totalDesc, unit, dataSource];
        generationInputSheet.getRow(rowNumber).font = { bold: true };
        //set formula in excel
        periodHeadings.forEach((heading, index) => {
            const letter = generationInputSheet.getColumnKey(heading).letter;
            const formula = `=${letter}${rowNumber + 1}`;
            const row = generationInputSheet.getRow(rowNumber);
            row.getCell(letter).value = { formula };
        });

        //set border and fill
        setBorderAndFill(generationInputSheet, lastColumn, rowNumber);

        plantTypeData.forEach((data) => {
            //set border
            setBorder(generationInputSheet, lastColumn, rowNumber);
            generationInputSheet.addRow(data);
            rowNumber++;
        });
        //set border
        setBorder(generationInputSheet, lastColumn, rowNumber);
        rowNumber += 2;
    });
    return rowNumber;
}

const getDaysInMonth = (period) => {
    if (!period) return 0;
    if (period.indexOf("-") === -1) return 0;
    const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const periodArr = period.split("-");
    const month = shortMonthNames.indexOf(periodArr[0]) + 1;
    const year = parseInt("20" + periodArr[1]);
    const date = new Date(year, month, 0);
    return date.getDate();
}

const plantTypes = ["Bushrod Thermal Plant", "Mt. Coffee Hydro Plant"];
const paymentTypes = ["Pre pay", "Post pay"];

export const generateExcelFile = async (powerPlantData, peakLoadData, plantsAvailability, transmissionData, customerBillingData, departmentWagesData, financialData, powerPlantTypes = plantTypes) => {

    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Aaron Mineen';
    const tAndDInputSheet = workbook.addWorksheet("T & D Input Sheet");
    const generationInputSheet = workbook.addWorksheet('Generation Input Sheet');

    const genSheetColumnHeaders = [
        { heading: 'Indicator', key: 'plantName', width: 50.33 },
        { heading: 'Unit of Measure', key: 'unit', width: 16.22 },
        { heading: 'Data Source', key: 'dataSource', width: 14.67 },
        ...getPeriodicHeader(powerPlantData)
    ];
    const tAndDSheetColumnHeaders = [
        { heading: 'Indicator', key: 'name', width: 33.67 },
        { heading: 'Unit of Measure', key: 'unit', width: 16.22 },
        { heading: 'Data Source', key: 'dataSource', width: 20.22 },
        ...getPeriodicHeader(powerPlantData)
    ];
    generationInputSheet.columns = genSheetColumnHeaders;
    tAndDInputSheet.columns = tAndDSheetColumnHeaders;

    //Add gen-table header    
    generationInputSheet.getRow(3).values = genSheetColumnHeaders.map(ch => ch.heading);
    generationInputSheet.getRow(3).font = { bold: true };

    //Add T & D table header
    tAndDInputSheet.getRow(3).values = tAndDSheetColumnHeaders.map(ch => ch.heading);
    tAndDInputSheet.getRow(3).font = { bold: true };
    const genSheetLastColumn = generationInputSheet.columnCount;
    const tAndDLastColumn = tAndDInputSheet.columnCount;
    setAndMergePeriodHeading(generationInputSheet, genSheetLastColumn);
    setAndMergePeriodHeading(tAndDInputSheet, tAndDLastColumn);

    //set border and fill on gen-table header
    setBorderAndFill(generationInputSheet, genSheetLastColumn, 3, "5B9BD5");

    //set border and fill on T & D table header
    setBorderAndFill(tAndDInputSheet, tAndDLastColumn, 3, "5B9BD5");

    let genSheetRowNumber = 4;

    //Add Generation Data Table
    const dataKeys = Object.keys(powerPlantData);
    dataKeys.forEach(key => {
        const data = powerPlantData[key];
        const totalDesc = totalDescription[key];
        genSheetRowNumber = generatePowerPlantTable(generationInputSheet, powerPlantTypes, data, genSheetRowNumber, totalDesc, genSheetLastColumn);
    });
    //Add Plant Availability
    const availabilityKeys = Object.keys(plantsAvailability);
    availabilityKeys.forEach(key => {
        const data = plantsAvailability[key];
        const totalDesc = totalDescription[key];
        genSheetRowNumber = generatePowerPlantTable(generationInputSheet, powerPlantTypes, data, genSheetRowNumber, totalDesc, genSheetLastColumn, "AVERAGE");
    });

    //Add Peak Load Data Table to Generation Input Sheet
    const peakLoadDataKeys = Object.keys(peakLoadData);
    peakLoadDataKeys.forEach(key => {
        const data = peakLoadData[key];
        const totalDesc = totalDescription[key];
        genSheetRowNumber = generatePeakLoadAndInternalConsumptionTable(generationInputSheet, powerPlantTypes, data, genSheetRowNumber, totalDesc, genSheetLastColumn);
    });

    let tAndDRowNumber = 4;

    const totalGeneration = processTotalGenerationData(powerPlantData.energyGenerated)

    //Add Total Generation Data to T & D Input Sheet
    tAndDRowNumber = generateTotalGenerationTable(tAndDInputSheet, totalGeneration, tAndDRowNumber, totalDescription.totalGeneration, tAndDLastColumn);

    //Add  Energy Dispatched and Energy Delivered to T & D Input Sheet
    const { energyDispatched, energyDelivered } = transmissionData;
    //Add Energy Dispatched and Style it
    tAndDRowNumber = addRowAndStyle(tAndDInputSheet, energyDispatched, tAndDRowNumber, tAndDLastColumn);
    //Add Energy Delivered and Style it
    tAndDRowNumber = addRowAndStyle(tAndDInputSheet, energyDelivered, tAndDRowNumber, tAndDLastColumn);

    const { energyBilled, customerCount } = customerBillingData;
    //Add Energy Billing Total to T & D Input Sheet
    const perPayLen = energyBilled.filter(eb => eb.paymentType === paymentTypes[0]).length;
    const postPayLen = energyBilled.filter(eb => eb.paymentType === paymentTypes[1]).length;
    const periodHeadings = getPeriodicHeader(powerPlantData).map(ph => ph.key);
    tAndDRowNumber = addTotalRow(tAndDInputSheet, periodHeadings, "Total Energy Billed", "MWh", "Comm Monthly Reports", tAndDRowNumber, tAndDLastColumn, perPayLen, postPayLen);
    //Add Customer Billing Data to T & D Input Sheet

    tAndDRowNumber = generateCustomerBillingTable(tAndDInputSheet, paymentTypes, energyBilled, tAndDRowNumber, tAndDLastColumn);
    const { unmeteredEnergyBilled } = customerBillingData;
    //Add Unmetered Energy Billed to T & D Input Sheet
    tAndDRowNumber = addRowWithBorder(tAndDInputSheet, unmeteredEnergyBilled, tAndDRowNumber, tAndDLastColumn);

    const { totalLengthOfCircuit, totalCircuit, peakDemand, averageDemand } = transmissionData;
    //Add Total Length of Circuit to T & D Input Sheet
    tAndDRowNumber = addRowAndStyle(tAndDInputSheet, totalLengthOfCircuit, tAndDRowNumber, tAndDLastColumn);
    //Add Total Circuit to T & D Input Sheet
    tAndDRowNumber = addRowAndStyle(tAndDInputSheet, totalCircuit, tAndDRowNumber, tAndDLastColumn);
    //Add Average Demand to T & D Input Sheet
    tAndDRowNumber = addRowAndStyle(tAndDInputSheet, averageDemand, tAndDRowNumber, tAndDLastColumn);
    //Add Peak Demand to T & D Input Sheet
    tAndDRowNumber = addRowAndStyle(tAndDInputSheet, peakDemand, tAndDRowNumber, tAndDLastColumn);
    // //Add Total Operation Hours to T & D Input Sheet
    // tAndDRowNumber = addRowAndStyle(tAndDInputSheet, totalOperationHours, tAndDRowNumber, tAndDLastColumn);
    //Add Customer Count Total to T & D Input Sheet
    tAndDRowNumber = addTotalRow(tAndDInputSheet, periodHeadings, "Total Number of Customers", "Number", "Comm Monthly Reports", tAndDRowNumber, tAndDLastColumn, perPayLen, postPayLen);

    //Add Customer Count Data to T & D Input Sheet
    tAndDRowNumber = generateCustomerBillingTable(tAndDInputSheet, paymentTypes, customerCount, tAndDRowNumber, tAndDLastColumn);

    const { unmeteredCustomerCount } = customerBillingData;
    //Add Unmetered Customer Count to T & D Input Sheet
    tAndDRowNumber = addRowWithBorder(tAndDInputSheet, unmeteredCustomerCount, tAndDRowNumber, tAndDLastColumn);

    //Add Generation Data Table
    const departmentWageKeys = Object.keys(departmentWagesData);
    departmentWageKeys.forEach(key => {
        const data = departmentWagesData[key];
        const totalDesc = totalDescription[key];
        tAndDRowNumber = generateDepartmentWageTable(tAndDInputSheet, periodHeadings, data, tAndDRowNumber, totalDesc, tAndDLastColumn);
    });

    const financialDataKey = Object.keys(financialData);
    financialDataKey.forEach(key => {
        const data = financialData[key];
        const totalDesc = key;
        tAndDRowNumber = generateFinancialTable(tAndDInputSheet, data, tAndDRowNumber, totalDesc, tAndDLastColumn);
    });
    const { systemCapacity, contractedEnergy, energyImported, frequency, totalOperationHours } = transmissionData;
    const { name, unit, dataSource, ...periods } = systemCapacity;
    tAndDInputSheet.getRow(tAndDRowNumber).values = [totalDescription.expectedEnergy, "", dataSource];
    const periodColumns = Object.keys(periods);
    periodColumns.forEach((heading) => {
        const letter = tAndDInputSheet.getColumnKey(heading).letter;
        const daysInMonth = getDaysInMonth(heading);
        const formula = `=${letter}${tAndDRowNumber + 1}*24*0.85*${daysInMonth}`;
        const row = tAndDInputSheet.getRow(tAndDRowNumber);
        row.getCell(letter).value = { formula };
        row.getCell(letter).numFmt = '#,##0.00';
    });
    setBorderAndFillAndBold(tAndDInputSheet, tAndDLastColumn, tAndDRowNumber);

    tAndDRowNumber++;

    tAndDRowNumber = addRowAndStyle(tAndDInputSheet, systemCapacity, tAndDRowNumber, tAndDLastColumn);
    tAndDRowNumber = addRowAndStyle(tAndDInputSheet, frequency, tAndDRowNumber, tAndDLastColumn);
    tAndDRowNumber = addRowAndStyle(tAndDInputSheet, totalOperationHours, tAndDRowNumber, tAndDLastColumn);
    tAndDRowNumber = addRowAndStyle(tAndDInputSheet, contractedEnergy, tAndDRowNumber, tAndDLastColumn);
    tAndDRowNumber = addRowAndStyle(tAndDInputSheet, energyImported, tAndDRowNumber, tAndDLastColumn);
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Regulatory Compliance Reporting Matrix.xlsx");

}

