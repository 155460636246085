import {Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import MuiButton from '../buttons/MuiButton';

const SimplePopupContent = (props) => {
    const { yesOnClick, noOnClick, message } = props;
    return (
        <div>
            <Typography variant="subtitle1" component='div' sx={{ marginBottom:2 }}>
                {message}
            </Typography>
            <Box sx={{margin:"0 auto", textAlign:"center"}}>
                <MuiButton color="secondary" onClick={yesOnClick} text="Yes" />
                <MuiButton color="primary" onClick={noOnClick} text="No" />
            </Box>
        </div>
    )
}

export default SimplePopupContent
