import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { formatFigure3 } from "../../services/util";

const MixedCharts = ({
  dataSeries,
  title,
  titleY1,
  titleY2,
  unit1,
  unit2,
  height = "360px",
}) => {
  if (!dataSeries) return null;
  const series = dataSeries;
  const options = {
    chart: {
      height,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: [0, 2],
      curve: "smooth",
    },
    title: {
      text: title,
    },
    yaxis: [
      {
        title: {
          text: titleY1,
        },
        labels:{
          style:{
            colors:"black"
          },
          formatter: function(val) {
            if(val===0) return val
            const value = val/1000;
            return value.toFixed(1) + "k";
          }
        }
      },
      {
        opposite: true,
        title: {
          text: titleY2,
        },
        labels:{
          style:{
            colors:"black"
          },
          formatter: function(val) {
            if(val===0) return val
            const value = val/1000;
            return value.toFixed(1) + "k";
          }
        }
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: [
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return formatFigure3(y) + ` ${unit1}`;
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return formatFigure3(y) + ` ${unit2}`;
            }
            return y;
          },
        },
      ],
    },
  };
  return (
    <Box>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={height}
      />
    </Box>
  );
};

export default MixedCharts;
