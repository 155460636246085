import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";

const SmoothLineChartWithAnnotation = ({title, seriesData = [], annotationText, unit, color, height}) => {
  // debugger;
  const series = [
    {
      name: title,
      data: seriesData,
    },
  ];
  const options = {
    chart: {
      type: "area",
      height: 280,
      sparkline: {
        enabled: true,
      },
    },
    
    annotations: {
      xaxis: [
        {
          x: annotationText,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            orientation: 'horizontal',
            text: annotationText,
            borderWidth:"1px"
          }
        }
      ]
    },
    stroke: {
      curve: "smooth",
      //curve: 'straight',
      width: "1.5",
    },
    colors: [color],
    fill: {
        gradient: {
            enabled: true,
            opacityFrom: 0.5,
            opacityTo: 0.1
        }
    },
    
    tooltip: {
      y: {
        formatter: function (y) {
          if (y !== undefined) return y.toLocaleString() + ` ${unit}`;
          return y;
        },
      },
    },
  };
  return (
    <Box sx={{p:0.5}}>
      <ReactApexChart
        height={height}
        options={options}
        series={series}
        type="area"
      />
    </Box>
  );
};

export default SmoothLineChartWithAnnotation;
