import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/system/Box';

const EditDeleteMenu = ({ onEditClick, onDeleteClick }) => {
  //TODO: fix onEditButtonClickClosed or onDeleteButtonClickClosed
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        aria-label='edit & delete button'
        aria-controls='edit&delete-buttons'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <MoreVertIcon fontSize='medium' />
      </IconButton>
      <Menu
        sx={{ pl: 1, pr: 1 }}
        id='edit&delete-buttons'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={onEditClick} sx={{ py: 0.5, px: 2 }}>
          <EditIcon fontSize='small' sx={{ m: 1 }} />
          Edit
        </MenuItem>
        <MenuItem sx={{ color: 'red', py: 0.5, px: 2 }} onClick={onDeleteClick}>
          <CloseIcon fontSize='small' sx={{ m: 1 }} />
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default EditDeleteMenu;
