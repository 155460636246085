import React from "react";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { financialCardBaseStyles } from "../styles";
import { formatFigure2 } from "../../services/util";

const FinancialCard = ({ value, title }) => {
    
  return (
    <Box
      sx={{ ...financialCardBaseStyles, p:2, gridTemplateRows: "repeat(2, auto auto)",  placeItems:"start"}}
      display="grid"
    >
      <Box>
        <Stack direction="row">
          <Box sx={{p:0.7, borderRadius:"50%", bgcolor:value<0? "#F5C0C0" : "#CDDEFF", display:"grid", placeItems:"center", color:value<0? "#CE1212" : "#00A19D"}}>
            <MonetizationOnIcon />
          </Box>
          <Box sx={{display:"grid", placeItems:"center", ml:1, color:"text.secondary"}}>{title}</Box>
        </Stack>        
      </Box>
      <Box sx={{fontSize:"24px", fontWeight:"bold", mt:1}}>
          {`$ ${formatFigure2(value)}`}
      </Box>
    </Box>
  );
};

export default FinancialCard;
