import { Box, Stack } from "@mui/material";
import React from "react";
import BoltIcon from '@mui/icons-material/Bolt';
import { transmissionCardBaseStyles2 } from "../styles";
import { formatFigure3 } from "../../services/util";
import { alpha } from "@mui/system";
import SmoothLineChart from "../controls/SmoothLineChart";

const defaultIcon = <BoltIcon fontSize="medium"/>

const TransmissionKPICardWithChart = ({ title, value, seriesData, unit, color, icon = defaultIcon }) => {
  return (
    <Box
      sx={{
        ...transmissionCardBaseStyles2,
        p: 2,
        gridTemplateRows: "repeat(3, 20% 20% auto)",
        placeItems: "start",
      }}
      display="grid"
    >
      <Stack sx={{width:"100%"}} direction={"row"} justifyContent="space-between" alignContent={"center"}>
        <Box sx={{display:"grid", placeItems:"center", ml:1, color:"text.secondary"}}>{title}</Box>
        <Box
          sx={{
            p: 2,
            borderRadius: 1.5,
            bgcolor: alpha(color, 0.2),
            display: "grid",
            placeItems: "center",
            color: alpha(color, 1)
          }}
        >
         {icon}
        </Box>
      </Stack>
      <Box sx={{ fontSize: "20px", fontWeight: "bold", mt: 1 }}>
        {`${formatFigure3(value, 0)} ${unit}`}
      </Box>
      <Box sx = {{p:1, width:"100%"}}>
        <SmoothLineChart
          color={color}
          height="130px"
          title={title}
          unit={unit}
          frontUnit={false}
          opacityFrom = {0.6}
          seriesData={seriesData}
        />
      </Box>
    </Box>
  );
};

export default TransmissionKPICardWithChart;
