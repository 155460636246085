import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";

const BillingRadialChart = ({ dataSeries, height = 330}) => {
  if(!dataSeries) return null;
  const series = dataSeries.data.map(d=>d.y);
  const options = {
    chart: {
      type: "radialBar",
      height,
      offsetX: 0,
    },
    plotOptions: {
      radialBar: {
        inverseOrder: false,
        hollow: {
          margin: 5,
          size: "42%",
          background: "transparent"
        },
        track: {
          show: true,
          background: "#B6E6E6",
          strokeWidth: "15%",
          opacity: 1,
          margin: 3 // margin is in pixels
        }
      }
    },
    
    labels: dataSeries.data.map(d=>d.x),
    title: {
        text:`${dataSeries.name}`,
        align: 'center',
        style: {
            fontSize: '14px',
            color:"#323232"
        }
    },
    legend: {
      show: true,
      position: "bottom",
      formatter: function (val, opts) {
        return val + " -  " + opts.w.globals.series[opts.seriesIndex] + "%";
      }
    },
   
  };
  return (
    <Box >
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={height}
      />
    </Box>
  );
};

export default BillingRadialChart;
