import { Box } from "@mui/system";
import React from "react";
import { cardBaseStyles } from "../styles";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SmoothLineChart from "../controls/SmoothLineChart";
import { Stack } from "@mui/material";


const getTrend = (data) => {
  if (!data) return;
  const length = data.length;
  if (length < 2) return;
  const current = data[length - 1];
  const previous = data[length - 2];
  if(current.y === 0)  return (
    <Box sx={{ color: "red" }}>
      {"100%"} <TrendingDownIcon />
    </Box>
  );
  const diff = current.y - previous.y;
  const trend = diff / current.y;
  const percent = (trend * 100).toFixed(1) + "%";
  if (trend < 0)
    return (
      <Box sx={{ color: "red" }}>
        {percent} <TrendingDownIcon />
      </Box>
    );
  else
    return (
      <Box sx={{ color: "green" }}>
        {percent} <TrendingUpIcon />
      </Box>
    );
};
const DashboardCard = ({ chartTitle, unit,valueTitle = chartTitle, value, seriesData, color = "#2FA4FF", height=120 }) => {
  return (
    <Box
      sx={{
        ...cardBaseStyles,
        display: "grid",
        gridTemplateRows: "repeat(3, 20% auto 20%)",
      }}
    >
      <Box>{getTrend(seriesData)}</Box>
      <SmoothLineChart title={chartTitle} seriesData={seriesData} unit={unit} color = {color} height = {height} opacityFrom ={0.4} />
      <Stack>
        <Box sx={{ fontWeight: "bold" }}>
          {`${value} ${unit}`}{" "}
        </Box>
        <Box sx={{ color: "text.secondary" }}>{valueTitle}</Box>
      </Stack>
    </Box>
  );
};

export default DashboardCard;
