import { Divider, Stack } from "@mui/material";
import { Box, alpha } from "@mui/system";
import React from "react";
import { formatFigure3 } from "../../services/util";
import ColumnChart from "../controls/ColumnChart";
import { billingCardBaseStyles } from "../styles";

const CustomerBillingsChart = ({
  title,
  totalValue,
  dataSeries,
  unit = "",
  height,
  selectedYear,
  stacked,
}) => {
  return (
    <Box sx={{ ...billingCardBaseStyles }}>
      <Stack
        direction={"column"}
        spacing={1}
        divider={<Divider orientation="horizontal" flexItem />}
      >
        <Box>
          <Box
            sx={{
              color: "text.secondary",
              display: "flex",
              alignItems: "center",
              width: 1,
            }}
          >
            {title}
            <Box
              sx={{ fontSize: "20px", fontWeight: "bold", ml: 2 }}
            >{`${formatFigure3(totalValue, 0)} ${unit}`}</Box>
          </Box>
          <Box sx={{width:{xs:0.2, sm:0.15}, p:0.5, borderRadius:"10px", display:"grid", placeItems:"center", bgcolor:alpha("#53B8BB", 0.2)}} >{selectedYear}</Box>
        </Box>
        <Box>
          <ColumnChart
            title={title}
            dataSeries={dataSeries}
            unit={unit}
            height={height}
            stacked={stacked}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default CustomerBillingsChart;
