import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { appUserRoles } from '../../services/util';
import GenerationEditForm from './GenerationEditForm';
import GenerationKpiList from './GenerationKpiList';
import PrivateRoute from '../routes/PrivateRoute';
import GenerationKpiContextProvider from '../../contexts/generationKpiContext';
import SubPlantDetails from './SubPlantDetails';
import GenerationDetails from "./GenerationDetails"

const GenerationKPI = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <GenerationKpiContextProvider>
      <PrivateRoute
          roles={appUserRoles}
          path={path}
          exact
          component={GenerationDetails}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/table`}
          exact
          component={GenerationKpiList}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/table/names/:name/:period`}
          exact
          component={SubPlantDetails}
        />
        
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/table/form/:id`}
          exact
          component={GenerationEditForm}
        />
        <PrivateRoute
          roles={appUserRoles}
          path={`${path}/table/form`}
          exact
          component={GenerationEditForm}
        />
      </GenerationKpiContextProvider>
    </Switch>
  );
};

export default GenerationKPI;
