import DialogTitle from "@mui/material/DialogTitle";
import Dialog  from "@mui/material/Dialog";
import MuiActionButton from "../buttons/MuiActionButton";
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent";


const Popup = (props) => {
    const {title, children, openPopup, setOpenPopup} = props;

    return (
        <Dialog open={openPopup} sx={{padding:2,position:'absolute', top: 5, maxHeight:"320px"}} maxWidth="md" >
            <DialogTitle>
                <div style={{display:'flex'}}>
                    <Typography variant = "h6" component="div" sx = {{flexGrow:1, color:'text.primary'}}>
                        {title}
                    </Typography>
                    <MuiActionButton color="secondary" onClick={()=>setOpenPopup(false)}>
                        <CloseIcon/>
                    </MuiActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default Popup