import React, { useState } from 'react';
import Box from '@mui/system/Box';
import PageHeader from './components/pageHeader';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Alert, Collapse, IconButton, TextField } from '@mui/material';
import { useAuthContext } from './contexts/authContext';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router';

const initialValues = {
  email: '',
  password: '',
};
const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string('Enter your password').required('Password is required'),
});

const Login = () => {
  const [error, setError] = useState('');
  const [hasError, setHasError] = useState(false);
  const { loginUser } = useAuthContext();
  const history = useHistory();

  return (
    <Box sx={{ width: 1, display: 'grid', placeItems: 'center' }}>
      <PageHeader
        title='Login Page'
        subTitle='Login Page for LEC KPI Dashboard'
        icon={<LockOpenIcon fontSize='large' />}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            await loginUser(values);
            history.push('/');
          } catch (error) {
            setError('Incorrect username or password');
            setHasError(true);
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          isValid,
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'grid',
                placeItems: 'center',
                bgcolor: 'background.paper',
                p: 2,
                width: { xs: '320px', sm: '500px' },
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: 'grid', placeItems: 'center' }}>
                <Box sx={{ fontSize: 18, fontWeight: 'medium' }}>
                  Welcome Back
                </Box>
                <Box
                  component='h5'
                  sx={{ mt: 0, fontSize: 11, color: 'text.secondary' }}
                >
                  Please enter your credentials to access your account
                </Box>
              </Box>
              <TextField
                sx={{ ml: 1, my: 2, minWidth: { xs: '280px', sm: '420px' } }}
                onChange={handleChange}
                label='Email'
                placeholder='Email'
                value={values.email}
                type='text'
                name='email'
                variant='outlined'
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email ? errors.email : ''}
              />

              <TextField
                sx={{ ml: 1, my: 1, minWidth: { xs: '280px', sm: '420px' } }}
                name='password'
                label='Password'
                placeholder='Password'
                value={values.password}
                type='password'
                variant='outlined'
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={
                  touched.password && errors.password ? errors.password : ''
                }
              />

              <Box display='inline'>
                <LoadingButton
                  sx={{ ml: 1, my: 1, textTransform: 'none' }}
                  disabled={isSubmitting || !isValid}
                  fullWidth
                  loading={isSubmitting}
                  variant='contained'
                  size='large'
                  type='submit'
                >
                  Login
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <Box>
        <Collapse in={hasError}>
          <Alert
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setHasError(false);
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            {error}
          </Alert>
        </Collapse>
      </Box>
    </Box>
  );
};

export default Login;
