import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditDeleteMenu from "../controls/EditDeleteMenu";

function GenerationCollapsibleRow({
  row,
  editData,
  prompToDeleteData,
  currentUser,
  onSubPlantClick,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(!open);
  const handleSubPlantClick = (plantName, period) => {
    onSubPlantClick(plantName, period);
  };
  return (
    <React.Fragment>
      <TableRow
        sx={
          row.plants.length !== 0
            ? {
                "& > *": { borderBottom: "unset" },
              }
            : {}
        }
        onClick={handleClick}
      >
        <TableCell>
          {row.plants.length !== 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleClick}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>

        <TableCell align="left">{row.plantType}</TableCell>
        <TableCell align="left">{row.period}</TableCell>
        <TableCell align="right">{row.peakLoad}</TableCell>
        <TableCell align="right">{row.internalConsumption}</TableCell>        
        {currentUser && currentUser.role === "Admin" && (
          <TableCell align="right">
            <EditDeleteMenu
              onEditClick={(e) => {
                e.stopPropagation();
                editData(row._id);
              }}
              onDeleteClick={(e) => {
                e.stopPropagation();
                prompToDeleteData(row._id);
              }}
            />
          </TableCell>
        )}
      </TableRow>
      {row.plants.length !== 0 && (
        <TableRow sx={{ backgroundColor: "#efefef" }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Typography variant="h6" gutterBottom component="div">
                  Sub Plants
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Plant Name</TableCell>
                      <TableCell align="right">Energy Generated</TableCell>
                      <TableCell align="right">
                        Service Hours
                      </TableCell>
                      <TableCell align="right">Scheduled Outage Hours</TableCell>
                      <TableCell align="right">Maintenance Hours</TableCell>
                      <TableCell align="right">Forced Outages</TableCell>
                      <TableCell align="right">Unplanned Outage</TableCell>
                      <TableCell align="right">Period Hours</TableCell>
                      <TableCell align="right">Installed Capacity</TableCell>
                      <TableCell align="right">Fuel Consumption</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.plants.map((plant, index) => (
                      <TableRow
                        key={`${plant._id}~${index}`}
                        onClick={() =>
                          handleSubPlantClick(plant.plantName, row.period)
                        }
                      >
                        <TableCell align="left">{plant.plantName}</TableCell>
                        <TableCell align="right">
                          {plant.energyGenerated}
                        </TableCell>
                        <TableCell align="right">
                          {plant.serviceHours}
                        </TableCell>
                        <TableCell align="right">
                          {plant.scheduledOutageHours}
                        </TableCell>
                        <TableCell align="right">
                          {plant.maintenanceHours}
                        </TableCell>
                        <TableCell align="right">
                          {plant.forcedOutage}
                        </TableCell>
                        <TableCell align="right">
                          {plant.unplannedOutage}
                        </TableCell>
                        <TableCell align="right">{plant.periodHours}</TableCell>
                        <TableCell align="right">
                          {plant.installedCapacity}
                        </TableCell>
                        <TableCell align="right">
                          {plant.fuelConsumption}
                        </TableCell>
                        {currentUser && currentUser.role === "Admin" && (
                          <TableCell align="right">
                            <EditDeleteMenu
                              onEditClick={(e) => {
                                e.stopPropagation();
                                editData(row._id);
                              }}
                              onDeleteClick={(e) => {
                                e.stopPropagation();
                                prompToDeleteData(row._id);
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default GenerationCollapsibleRow;
