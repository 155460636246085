import { IconButton, Popover, Typography } from "@mui/material";
import { alpha, Box } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import React from "react";

const CardTitle = ({ title, definition }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const showInfo = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "info-popover" : undefined;
  return (
    <Box sx={{ alignContent: "center" }}>
      {title}
      <IconButton
        aria-describedby={id}
        onClick={showInfo}
        sx={{ color: "primary.main" }}
      >
        <InfoOutlinedIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          component={Typography}
          sx={{
            p: 2,
            fontSize: 11,
            fontWeight: "medium",
            maxWidth: "230px",
            color: "success.dark",
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
          }}
        >
          {definition}
        </Box>
      </Popover>
    </Box>
  );
};

export default CardTitle;
