export const accountNames = {
    wagesAndSalaries: "Total wages & salaries",
    expenses:"Total Expenses",
    fuelCost:"Total fuel cost",
    sales:"Total sales",
    grossProfitMargin: "Gross profit margin",
    netProfitAfterTax:"Net profit after tax",
    netProfitBeforeTax:"Net profit before tax",
    accountReceivable: "Accounts receivable",
    inventory:"Total Inventory Stock",
    ownersEquity:"Total owners equity",
    fixedAssets:"Total Fixed assets",
    currentAssets : "Total Current assets",
    debt:"Total debt"
}