import React from 'react';
import { useFormikContext } from 'formik';
import InputWithAdornment from '../controls/formControls/InputWithAdornment';

const TotalDependentInput = (props) => {
  const {
    values: { accounts },
    touched,
    setFieldValue,
  } = useFormikContext();
  const [disable, setDisable] = React.useState(false);

  React.useEffect(() => {
    setDisable(accounts.length !== 0);

    setFieldValue(props.name, sumSubAccounts(accounts, true));
  }, [accounts, setFieldValue, touched, props.name]);

  const sumSubAccounts = (accounts) => {
    if (accounts.length === 0) return 0;

    return accounts.reduce((acc, curr) => {
      if (typeof curr.value !== 'number') return acc;
      return acc + curr.value;
    }, 0);
  };

  return (
    <>
      <InputWithAdornment
        {...props}
        inputProps={{
          disabled: disable,
        }}
      />
    </>
  );
};

export default TotalDependentInput;
