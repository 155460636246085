import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";

const FinancialLineChart = ({title, subtitle, seriesData = [], height=360}) => {
  const series = seriesData
    const options = {
        chart: {
            type: 'area',
            foreColor: "#ccc",
            zoom: {
                enabled: false
            }
        },
        // colors: ["#D54C4C", "#05D7A0", "#F5DC50", "#82D2F5"],
        fill: {
            gradient: {
                enabled: true,
                opacityFrom: 0.55,
                opacityTo: 0
            }
        },

        markers: {
            size: 1,
            colors: ["#000524"],
            strokeColor: "#00BAEC",
            strokeWidth: 1
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
          theme: "light",
          y: {
            formatter: function (y) {
              if (y !== undefined) return `$` + y.toLocaleString();
              return y;
            },
          },
        },
        title: {
            text:title,
            align: 'center',
            style: {
                fontSize: '12px',
                color:"#323232"
            }
        },
        subtitle: {
            text: `${subtitle} ($)`,
            align: 'left',
            style: {
                fontSize: '12px',
                color:"#323232"
            }
        },
        stroke: {
            curve: 'smooth',
            width: 1.5
        },
        yaxis: {
            show: true,
            labels:{
              style:{
                colors:"black"
              },
              formatter: function(val) {
                if(val===0) return val
                const value = val/1000000;
                return value.toFixed(2) + "m";
              }
            }
        },
        xaxis:{
            labels:{
                show:true,
                style:{
                  colors:"black"
                }

            },
            axisTicks: {
                show: true,                
            },
            tickAmount:12
        },
      legend:{
        labels:{
          colors:"black"
        }
      }
    }


    return (
        <Box>
            <ReactApexChart height={height} options={options} series={series} type="area" />
        </Box>
    )
};

export default FinancialLineChart;
